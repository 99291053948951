import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatInput } from '@angular/material/input';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Observable, Subscription } from 'rxjs';
import { startWith, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-formly-autocomplete-type',
  template: `
    <input
      matInput
      [matAutocomplete]="auto"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (change)="handleEmptyInput($event)"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      [panelWidth]="panelWidth"
    >
      <mat-option
        *ngFor="let item of filteredOptions"
        [value]="item.value"
        (onSelectionChange)="selected($event, item)"
      >
        {{ item.label }}
      </mat-option>
    </mat-autocomplete>
  `,
})
export class AppFormlyAutocompleteComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  // Optional: only if you want to rely on `MatInput` implementation
  @ViewChild(MatInput, { static: true }) formFieldControl: MatInput;

  filter: Observable<any>;
  filteredOptions: any[] = [];

  filteredOptionsSubscription: Subscription;

  panelWidth: string | number = 'auto';

  ngOnInit() {
    if (this.to.disabled) {
      this.formControl.disable();
    }

    if (this.to.panelWidth) {
      this.panelWidth = this.to.panelWidth;
    }

    this.filteredOptionsSubscription = this.formControl.valueChanges
      .pipe(
        startWith(''),
        switchMap((term) => this.to.filter(term ? term : null))
      )
      .subscribe((s) => {
        this.filteredOptions = s as any[];
      });
  }

  ngOnDestroy() {
    if (this.filteredOptionsSubscription) {
      this.filteredOptionsSubscription.unsubscribe();
    }
  }

  displayFn(inputVal: string): string | undefined {
    // tslint:disable-next-line
    const itemId = Number.parseInt(inputVal);

    if (itemId && itemId > 0 && this.filteredOptions) {
      const item = this.filteredOptions.find((x) => x.value === itemId);

      if (item) {
        return item.label;
      } else {
        return this.getInitialValue(inputVal);
      }
    } else {
      this.setListValue(null);
      return inputVal;
    }
  }

  selected(event: MatOptionSelectionChange, item: any) {
    if (event.source.selected) {
      this.setListValue(item);
    }

    if (this.to.selected) {
      this.to.selected(item);
    }
  }

  private getInitialValue(enteredValue) {
    if (this.to.listValue) {
      const listValue = this.to.listValue;
      if (listValue.includes('.')) {
        const values = listValue.split('.');
        return this.model[values[0]]
          ? this.model[values[0]][values[1]]
          : enteredValue;
      }
      return this.model[listValue] ? this.model[listValue] : enteredValue;
    } else {
      return enteredValue;
    }
  }

  handleEmptyInput(event: any) {
    if (event.target.value === '') {
      this.setListValue(null);
    }
  }

  private setListValue(item: any) {
    if (this.to.listValue) {
      const listValue = this.to.listValue;
      if (listValue.includes('.')) {
        const values = listValue.split('.');
        if (item === null) {
          this.model[values[0]] = Object.assign({});
        } else {
          this.model[values[0]] = Object.assign(
            {},
            { id: item.value, [values[1]]: item.label }
          );
        }
      } else {
        if (item === null) {
          this.model[listValue] = null;
        } else {
          this.model[listValue] = item.label;
        }
      }
    }
  }
}
