export * from './clients.service';
import { ClientsService } from './clients.service';
export * from './collaborator.service';
import { CollaboratorService } from './collaborator.service';
export * from './comment.service';
import { CommentService } from './comment.service';
export * from './commercialImputation.service';
import { CommercialImputationService } from './commercialImputation.service';
export * from './estimation.service';
import { EstimationService } from './estimation.service';
export * from './invoice.service';
import { InvoiceService } from './invoice.service';
export * from './mission.service';
import { MissionService } from './mission.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './proposal.service';
import { ProposalService } from './proposal.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './task.service';
import { TaskService } from './task.service';
export * from './timeImputation.service';
import { TimeImputationService } from './timeImputation.service';
export const APIS = [ClientsService, CollaboratorService, CommentService, CommercialImputationService, EstimationService, InvoiceService, MissionService, ProjectService, ProposalService, RoleService, TaskService, TimeImputationService];
