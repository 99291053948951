import { CollaboratorActions, CollaboratorsActionTypes } from './collaborators.actions';
import { CollaboratorsState, initialCollaboratorsState } from './collaborators.state';
export function collaboratorsReducer(
  state = initialCollaboratorsState,
  action: CollaboratorActions
): CollaboratorsState {
  switch (action.type) {

    case CollaboratorsActionTypes.GET_ACTIVE_COLLABORATORS_SUCCESS: {
      return {
        ...state,
        activeCollaborators: action.payload
      };
    }

    case CollaboratorsActionTypes.GET_COLLABORATORS_WORKLOAD_SUCCESS: {
      return {
        ...state,
        collaboratorsWorkload: action.payload
      }
    }

    default: {
      return state;
    }
  }
}
