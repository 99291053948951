import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { getTcAuthUser, TC_AUTH_LOGIN_URL } from '@tc/auth';
import { PermissionService } from '../permission.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>,
    private permissionService: PermissionService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.store.pipe(
      select(getTcAuthUser),
      map(user => {

        if (!user) {
          this.router.navigate(['/login']);
          return false;
        }

        const roles = route.data.roles as Array<string>;
        if (roles && (user as any).roles) {
          const intersection = roles.filter(x => (user as any).roles.includes(x));
          if (intersection.length === 0) {
            this.router.navigate(['/']);
            return false;
          }
        }
        return true;
      }));
  }
}
