import { DashboardEntitiesEnum } from "./../../../dashboard/dashboard-entities-enum";
import {
  TcAction,
  TcGenericEntity,
  TcListFilterModel,
  TcListSortModel,
} from "@tc/core";
import { EntitiesEnum } from "../../enums/entities-enum";
import { createAction, props } from "@ngrx/store";
import { ListEffects } from "./../effects/list.effects";

export enum ListActionTypes {
  ADD_LIST_ITEM = "[List] Add list item",
  EDIT_LIST_ITEM = "[List] Edit list item",
  CREATE_LIST_ITEM = "[List] Create list item from another",
  DUPLICATE_LIST_ITEM = "[List] Duplicate list item"
}

export class AddListItem implements TcAction {
  readonly type = ListActionTypes.ADD_LIST_ITEM;
  constructor(public payload: { entityName: EntitiesEnum; args?: any }) { }
}

export class EditListItem implements TcAction {
  readonly type = ListActionTypes.EDIT_LIST_ITEM;
  constructor(
    public payload: {
      entityName: EntitiesEnum;
      data: TcGenericEntity;
      args?: any;
    }
  ) { }
}

export class CreateListItem implements TcAction {
  readonly type = ListActionTypes.CREATE_LIST_ITEM;
  constructor(
    public payload: {
      entityName: EntitiesEnum;
      data: TcGenericEntity;
      args?: any;
    }
  ) { }
}

export const filterListItems = createAction(
  "[List] Filter list items",
  props<{
    entityName: EntitiesEnum | DashboardEntitiesEnum;
    filter: TcListFilterModel;
    order?: TcListSortModel;
    initial?: boolean;
  }>()
);

export class DuplicateListItem implements TcAction {
  readonly type = ListActionTypes.DUPLICATE_LIST_ITEM;
  constructor(
    public payload: {
      entityName: EntitiesEnum;
      data: TcGenericEntity;
      args?: any;
    }
  ) { }
}

export type ListActions = AddListItem | EditListItem | CreateListItem | DuplicateListItem;
