import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CollaboratorsState } from './collaborators.state';

const getCollaboratorsState = createFeatureSelector<CollaboratorsState>(
  'collaborators'
);

export const getActiveCollaboratorsByTrigram = (term: string) => createSelector(
  getCollaboratorsState,
  (state: CollaboratorsState) => state.activeCollaborators.filter(c => term && c.trigram && c.trigram.toLowerCase().includes(term.toLowerCase()))
);

export const getCollaboratorsWorkload = () => createSelector(
  getCollaboratorsState,
  (state: CollaboratorsState) => state.collaboratorsWorkload
);

