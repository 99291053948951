import { Invoice } from '../api/krakenn-api';

export class InvoiceType {
  static className = 'Invoice';
  static gqlRootQueryName = 'invoice';
  static gqlColumns: string[] = [
      '_id',
      'clientId',
      'number',
      'date',
      'amountHT',
      'amountTVA',
      'amountTTC',
      'status',
      'payedDate',
      'url',
  ];

  static gqlNodeMapper = (node: any): Invoice => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Invoice;
    }

}
