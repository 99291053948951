import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CollaboratorsListComponent } from "./components/smart/collaborators-list/collaborators-list.component";
import { CollaboratorDetailComponent } from "./components/smart/collaborator-detail/collaborator-detail.component";
import { TcCoreModule } from "@tc/core";
import { CoreModule } from "../core/core.module";
import { CollaboratorsEffects } from "./store/collaborators.effects";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from '@ngrx/store';
import { collaboratorsReducer } from './store/collaborators.reducer';
import { CollaboratorsWorkloadComponent } from './components/smart/collaborators-workload/collaborators-workload.component';
import { WorkloadPerCollabComponent } from './components/dumb/workload-per-collab/workload-per-collab.component';

const smartComponents = [
  CollaboratorsListComponent,
  CollaboratorDetailComponent,
  CollaboratorsWorkloadComponent
];

@NgModule({
  declarations: [...smartComponents, WorkloadPerCollabComponent ],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule,
    EffectsModule.forFeature([CollaboratorsEffects]),
    StoreModule.forFeature('collaborators', collaboratorsReducer),
  ],
  entryComponents: [CollaboratorDetailComponent],
  exports: [...smartComponents],
})
export class CollaboratorsModule { }
