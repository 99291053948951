import { Component, OnInit } from '@angular/core';
import { TcPage, IPageState } from '@tc/core';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import { ToggleFilterModel } from 'src/app/modules/core/models/toggle-filter.model';
import { MissionDbStatus } from 'src/app/modules/core/enums/mission-db-status.enum';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-missions-page',
  templateUrl: './missions-page.component.html',
  styleUrls: ['./missions-page.component.scss']
})
export class MissionsPageComponent extends TcPage implements OnInit {

  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'missions-page.title'
    };

    this.pageInfo = pageInfo;
    super.ngOnInit();
  }
}
