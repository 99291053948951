import { TargetDateService } from 'src/app/services/target-date.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  TcGenericListComponent,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcListFilterType,
  TcSmartComponent,
  TcListDisplayColumnType,
} from '@tc/core';
import { Task } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import {
  AddListItem,
  EditListItem,
} from 'src/app/modules/core/store/actions/list.actions';
import { DashboardEntitiesEnum } from '../../../dashboard-entities-enum';
import {
  InsertTimeImputation,
  OpenTerminateTaskDialog,
} from '../../../store/dashboard.actions';
import { MyTask } from '../../../models/my-task.model';
import { Utils } from 'src/app/modules/core/utils';
import { DisplayCommentsList } from 'src/app/modules/comments/store/comments.actions';
import { CommentsTable } from 'src/app/modules/comments/enums/comments-table.enum';
import { JiraUrlService } from 'src/app/services/jira-url.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard-my-tasks-list',
  templateUrl: './dashboard-my-tasks-list.component.html',
  styleUrls: ['./dashboard-my-tasks-list.component.scss'],
})
export class DashboardMyTasksListComponent
  extends TcSmartComponent
  implements OnInit
{
  constructor(
    private store: Store<any>,
    private jiraUrl: JiraUrlService,
    private datePipe: DatePipe,
    private readonly targetDateService: TargetDateService
  ) {
    super();
  }

  @ViewChild('colJiraIdTemplate', { static: true })
  colJiraIdTemplate: TemplateRef<any>;
  @ViewChild('colProjectNameTemplate', { static: true })
  colProjectNameTemplate: TemplateRef<any>;
  @ViewChild('colMissionNameTemplate', { static: true })
  colMissionNameTemplate: TemplateRef<any>;
  @ViewChild('colSumTI', { static: true }) colSumTI: TemplateRef<any>;
  @ViewChild('colTP', { static: true }) colTP: TemplateRef<any>;
  @ViewChild('colTargetDate', { static: true }) colTargetDate: TemplateRef<any>;
  @ViewChild('advancementPercent', { static: true })
  advancementPercent: TemplateRef<any>;
  @ViewChild('colLastRaf', { static: true }) colLastRaf: TemplateRef<any>;

  @ViewChild('rowActionCommentTemplate', { static: true })
  rowActionCommentTemplate: TemplateRef<any>;

  myTasksList: TcGenericListComponent<Task>;
  @ViewChild('myTasksList', { static: true }) set setMyTasksList(
    values: TcGenericListComponent<MyTask>
  ) {
    this.myTasksList = values;
    this.myTasksList.entityName = DashboardEntitiesEnum.DashboardMyTasks;
  }

  ngOnInit() {
    this.myTasksList.rowActionButtonsPosition =
      TcListRowActionButtonsPosition.AfterData;
    this.myTasksList.showTotalInActionsHeader = false;

    this.myTasksList.sortType = TcListSortType.Server;
    this.myTasksList.sortDirection = 'desc';
    this.myTasksList.sortActive = 'priority';
    this.myTasksList.filterType = TcListFilterType.Disabled;
    this.myTasksList.hasFixedHeader = true;

    this.myTasksList.hasAddButton = false;
    this.myTasksList.addItemWhenKeyPresed = false;

    this.myTasksList.hasActionsLabel = false;
    this.myTasksList.isPaged = false;

    this.myTasksList.onScrollDown = () => {
      this.myTasksList.service.getAll();
    };

    this.myTasksList.columns = [
      {
        propertyName: 'id',
        visible: true,
      },
      {
        propertyName: 'jiraId',
        visible: true,
        htmlTemplate: this.colJiraIdTemplate,
      },
      {
        propertyName: 'project.name',
        visible: true,
        htmlTemplate: this.colProjectNameTemplate,
      },
      {
        propertyName: 'mission.name',
        visible: true,
        htmlTemplate: this.colMissionNameTemplate,
      },
      {
        propertyName: 'name',
        visible: true,
      },
      {
        propertyName: 'targetPoints',
        visible: true,
        htmlTemplate: this.colTP,
      },
      {
        propertyName: 'sumTimeImputation',
        visible: true,
        htmlTemplate: this.colSumTI,
      },
      {
        propertyName: 'advancementPercent',
        visible: true,
        htmlTemplate: this.advancementPercent,
      },
      {
        propertyName: 'lastRaf',
        visible: true,
        htmlTemplate: this.colLastRaf,
      },
      {
        propertyName: 'targetDate',
        visible: true,
        // displayColumnType: TcListDisplayColumnType.Date,
        htmlTemplate: this.colTargetDate,
      },
      {
        propertyName: 'globalPriority',
        visible: true,
      },
    ];

    this.myTasksList.rowActions = [
      {
        actionName: 'insert-time',
        visible: true,
        icon: 'add_circle',
      },
      {
        actionName: 'comment',
        visible: true,
        icon: 'comment',
        htmlTemplate: this.rowActionCommentTemplate,
      },
      {
        actionName: 'terminate-task',
        visible: true,
        icon: 'done',
      },
    ];

    this.myTasksList.onRowAction = (row: MyTask, actionName: string) => {
      if (actionName === 'insert-time') {
        // dispatch new action for inserting imputation: this will display popup for imputation
        this.store.dispatch(new InsertTimeImputation(row));
      }
      if (actionName === 'comment') {
        this.store.dispatch(
          new DisplayCommentsList({ table: CommentsTable.Task, id: row.id })
        );
      }
      if (actionName === 'terminate-task') {
        this.store.dispatch(new OpenTerminateTaskDialog(row));
      }
    };

    this.myTasksList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Tasks }));
    };

    this.myTasksList.onRowClick = (row: Task) => {
      this.store.dispatch(
        new EditListItem({
          entityName: EntitiesEnum.Tasks,
          data: row,
          args: { readOnly: true },
        })
      );
    };
  }

  getTpInHours = (row: MyTask) => {
    return Utils.convertDaysToHours(row.targetPoints ?? 0);
  };

  getTiInHours = (row: MyTask) => {
    return Utils.convertDaysToHours(row.sumTimeImputation);
  };

  lastRafInHours = (row: MyTask) => {
    return Utils.convertDaysToHours(row.lastRaf);
  };

  getJiraUrl(row) {
    return this.jiraUrl.getJiraUrl(row.jiraId);
  }

  getAdvancementPercent = (row: MyTask) => {
    const sumTI = Utils.convertDaysToHours(row.sumTimeImputation);
    const tp = Utils.convertDaysToHours(row.targetPoints ?? 0);

    if (sumTI === 0 || tp === 0) {
      return 0;
    }

    const percent = sumTI / tp;

    return (percent * 100).toFixed(2);
  };

  isTargetDateRed = (row: MyTask) => {
    return this.targetDateService.isInTheWeek(
      this.datePipe.transform(row.targetDate, 'yyyy-MM-dd')
    );
  };

  getTargetDate = (row: MyTask) => {
    return this.datePipe.transform(row.targetDate, 'dd/MM/yyyy');
  };

  showComments(row: Task) {
    this.store.dispatch(
      new DisplayCommentsList({
        table: CommentsTable.Task,
        id: row.id,
        args: { title: 'comments-list.tasks.title' },
      })
    );
  }
}
