import { TimeImputation } from '../api/krakenn-api';

export class TimeImputationType {
  static className = 'TimeImputation';
  static gqlRootQueryName = 'timeimputation';
  static gqlColumns: string[] = [
      '_id',
      'collaboratorId',
      'taskId',
      'date',
      'workDone',
      'daysConsumed',
      'daysEstimatedToFinish',
      'commercialimputationId',
      'cost',
  ];

  static gqlNodeMapper = (node: any): TimeImputation => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as TimeImputation;
    }

}
