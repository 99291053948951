import { Cover } from '../api/krakenn-api';

export class CoverType {
  static className = 'Cover';
  static gqlRootQueryName = 'cover';
  static gqlColumns: string[] = [
      'payementId',
      'invoiceId',
  ];

  static gqlNodeMapper = (node: any): Cover => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Cover;
    }

}
