import { ReportingService } from './../../services/reporting.service';
import { Component, OnInit } from '@angular/core';
import { TcPage, IPageState, TcConfigService } from '@tc/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.scss']
})
export class ReportingPageComponent extends TcPage implements OnInit {

  constructor(
    store: Store<any>,
    private reportingService: ReportingService,
    private configService: TcConfigService) {
    super(store);
  }

  ngOnInit() {
    // Init the reporting service with the base path of the API
    this.reportingService.init((this.configService.config as any).API_BASE_PATH);

    const pageInfo: IPageState = {
      title: 'reporting-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
