import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  TcSmartComponent,
  TcGenericFilterComponent,
  TcSimpleFilterComponent,
  TcListFilterModel,
  TcListFilterType,
  TcListSortModel,
  TcFilterTypes,
} from '@tc/core';
import {
  Holiday,
  Opportunity,
  Estimation,
  Proposal,
} from 'src/generated/api/krakenn-api';
import { Store, select } from '@ngrx/store';
import { DashboardEntitiesEnum } from '../../../dashboard-entities-enum';
import { filterListItems } from 'src/app/modules/core/store/actions/list.actions';
import { Subscription } from 'rxjs';
import { getTcAuthUser, TcUser } from '@tc/auth';
import { UserRole } from 'src/app/modules/core/user-role.enum';
import { TargetDateService } from 'src/app/services/target-date.service';
import {
  ListRefreshService,
  ListRefreshType,
} from 'src/app/services/list-refresh.service';
import { skipWhile } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  isAdmin = false;

  authUserSubscription: Subscription;
  authUser: TcUser;

  filterValue = null;

  private refreshSubscription: Subscription;

  dashboardMyTasksFilter: TcSimpleFilterComponent;
  @ViewChild('dashboardMyTasksFilter', { static: true })
  set setDashboardMyTasksFilter(values: TcSimpleFilterComponent) {
    this.dashboardMyTasksFilter = values;
    // this.dashboardMyTasksFilter.filterLabel = '';
  }

  dashboardUnassignedTasksFilter: TcSimpleFilterComponent;
  @ViewChild('dashboardUnassignedTasksFilter', { static: true })
  set setDashboardUnassignedTasksFilter(values: TcSimpleFilterComponent) {
    this.dashboardUnassignedTasksFilter = values;
    // this.dashboardMyTasksFilter.filterLabel = '';
  }

  dashboardHolidaysFilter: TcGenericFilterComponent<Holiday>;
  @ViewChild('dashboardHolidaysFilter', { static: true })
  set setDashboardHolidaysFilter(values: TcGenericFilterComponent<Holiday>) {
    this.dashboardHolidaysFilter = values;
    this.dashboardHolidaysFilter.entityName =
      DashboardEntitiesEnum.DashboardHolidays;
    this.dashboardHolidaysFilter.filterLabel = '';
  }

  dashboardHotOpportunitiesFilter: TcGenericFilterComponent<Opportunity>;
  @ViewChild('dashboardHotOpportunitiesFilter', { static: true })
  set setDashboardHotOpportunitiesFilter(
    values: TcGenericFilterComponent<Opportunity>
  ) {
    this.dashboardHotOpportunitiesFilter = values;
    this.dashboardHotOpportunitiesFilter.entityName =
      DashboardEntitiesEnum.DashboardHotOpportunities;
    this.dashboardHotOpportunitiesFilter.filterLabel = '';
  }

  dashboardEstimationsFilter: TcGenericFilterComponent<Estimation>;
  @ViewChild('dashboardEstimationsFilter', { static: true })
  set setDashboardEstimationsFilter(
    values: TcGenericFilterComponent<Estimation>
  ) {
    this.dashboardEstimationsFilter = values;
    this.dashboardEstimationsFilter.entityName =
      DashboardEntitiesEnum.DashboardEstimations;
    this.dashboardEstimationsFilter.filterLabel = '';
  }

  dashboardProposalsToFollowFilter: TcGenericFilterComponent<Proposal>;
  @ViewChild('dashboardProposalsToFollowFilter', { static: true })
  set setDashboardProposalsToFollowFilter(
    values: TcGenericFilterComponent<Proposal>
  ) {
    this.dashboardProposalsToFollowFilter = values;
    this.dashboardProposalsToFollowFilter.entityName =
      DashboardEntitiesEnum.DashboardProposals;
    this.dashboardProposalsToFollowFilter.filterLabel = '';
  }

  constructor(
    private store: Store<any>,
    private readonly targetDateService: TargetDateService,
    private readonly listRefreshService: ListRefreshService
  ) {
    super();
  }

  ngOnInit() {
    this.filterValue = null;
    this.authUserSubscription = this.store
      .pipe(select(getTcAuthUser))
      .subscribe((authUser) => {
        if (authUser) {
          this.authUser = authUser;

          if (
            (authUser as any).roles &&
            (authUser as any).roles.find((r) => r === UserRole.Admin)
          ) {
            this.isAdmin = true;
          }

          const order = {
            key: 'globalPriority',
            order: 'DESC',
          } as TcListSortModel;
          this.loadMyTasks(null, order, true);
          this.loadUnassignedTasks(null, order, true);
        }
      });

    this.refreshSubscription = this.listRefreshService.subject
      .pipe(
        skipWhile((type) => type !== ListRefreshType.DashboardUnassignedTasks)
      )
      .subscribe(() => {
        const order = {
          key: 'globalPriority',
          order: 'DESC',
        } as TcListSortModel;
        this.loadUnassignedTasks(this.filterValue, order, true);
      });
  }

  ngOnDestroy() {
    if (this.authUserSubscription) {
      this.authUserSubscription.unsubscribe();
    }

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    this.filterValue = null;
  }

  loadMyTasks = (
    filterValue?: string,
    order?: TcListSortModel,
    initial?: boolean
  ) => {
    if (this.authUser) {
      const filter = {
        collaboratorId: {
          filterType: 'Equal',
          value: this.authUser.id + '',
        },
        mission: {
          status: {
            filterType: 'Equal',
            value: '1',
          },
        },
        terminated: {
          filterType: 'Equal',
          value: '0',
        },
      } as TcListFilterModel;

      if (filterValue) {
        filter.anyFieldContains = filterValue;
      }

      this.store.dispatch(
        filterListItems({
          entityName: DashboardEntitiesEnum.DashboardMyTasks,
          filter,
          order: order ? order : null,
          initial,
        })
      );
    }
  };

  searchMyTasks = (filterValue: string) => {
    this.loadMyTasks(filterValue);
  };

  loadUnassignedTasks = (
    filterValue?: string,
    order?: TcListSortModel,
    initial?: boolean
  ) => {
    const targetDateInterval =
      this.targetDateService.getIntervalForUnassignedTasks();

    if (this.authUser) {
      const filter = {
        mission: {
          status: {
            filterType: 'Equal',
            value: '1',
          },
        },
        terminated: {
          filterType: 'Equal',
          value: '0',
        },
        targetDate: {
          filterType: 'DateRange',
          value: targetDateInterval,
        },
      } as TcListFilterModel;

      if (filterValue) {
        filter.anyFieldContains = filterValue;
      }

      this.store.dispatch(
        filterListItems({
          entityName: DashboardEntitiesEnum.DashboardUnassignedTasks,
          filter,
          order: order ? order : null,
          initial,
        })
      );
    }
  };

  searchUnassignedTasks = (filterValue: string) => {
    this.filterValue = filterValue;
    this.loadUnassignedTasks(filterValue);
  };
}
