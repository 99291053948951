import { Mission } from '../api/krakenn-api';

export class MissionType {
  static className = 'Mission';
  static gqlRootQueryName = 'mission';
  static gqlColumns: string[] = [
      '_id',
      'projectId',
      'jiraSprint',
      'name',
      'targetDate',
      'quantity',
      'managementFactor',
      'imputationType',
      'unit',
      'priceHTPerUnit',
      'status',
      'priority',
      'targetInitialWL',
      'targetInitialDate',
      'targetReviewedWL',
      'targetReviewedDate',
      'changesCounter',
      'responsibleCollaboratorId',
  ];

  static gqlNodeMapper = (node: any): Mission => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Mission;
    }

}
