import { Injectable, Inject, Optional } from "@angular/core";
import {
    Configuration,
    BASE_PATH,
    Holiday
} from "src/generated/api/krakenn-api";
import { EntityCollectionServiceFactory } from "ngrx-data";
import { TcNotificationService, TcGenericEntity } from "@tc/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root",
})
export class HolidaysService {
    public configuration = new Configuration();
    protected basePath =
        "https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0";
    protected defaultHeaders = new HttpHeaders();

    constructor(
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration,
        private http: HttpClient,
        private entityCollectionServiceFactory: EntityCollectionServiceFactory,
        private notificationService: TcNotificationService,
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath =
                configuration.basePath || basePath || this.basePath;
        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    sendEmailForNewHolidayRequest(data: any): Observable<any> {
        const url = `${this.configuration.basePath}/holiday/send-email-new-holiday-request`;
        const headers = this.defaultHeaders;
        return this.http.post<Holiday>(url, data,
            {
                withCredentials: true,
                headers,
                observe: 'body',
                reportProgress: false
            });
    }
}
