import { Workbox, messageSW } from 'workbox-window';

const TIME_IN_MS = 5 * 1000; // 10 sec

export class SwService {
  static registrationStarted = false;

  static init = async (restartRequired = false) => {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    const showSnack = (a, b = null) => {
      if (a.message) {
        // TODO add GUI
        console.log(a.message);
      }
    };

    let refreshing = false;

    if (SwService.registrationStarted) {
      return;
    }
    if (!navigator.serviceWorker) {
      return;
    }

    SwService.registrationStarted = true;

    const wb = new Workbox('/sw.js');

    const registration = await wb.register();

    if (registration && registration.waiting) {
      onSWWaiting();
    }
    wb.addEventListener('activated', onSWActivated);
    wb.addEventListener('externalactivated', onSWUpdated);
    wb.addEventListener('externalwaiting', onSWWaiting);
    wb.addEventListener('waiting', onSWWaiting);

    setTimeout(checkForUpdates, TIME_IN_MS);

    function onSWActivated(ev: any /* WorkboxLifecycleEvent*/) {
      console.log('onSWActivated');

      if (ev.isUpdate) {
        return onSWUpdated();
      }

      showSnack({ message: 'Service Worker Installed' });

      if (restartRequired) {
        console.log('Claim required');
        // window.location.reload();
        messageSW(ev.sw, {
          type: 'CLIENTS_CLAIM',
        });
      }
    }

    function onSWUpdated() {
      if (refreshing) {
        return;
      }

      // New Service Worker has been activated.
      // You will need to refresh the page.

      refreshing = true;
      // if (navigateUrl) {
      //     return (window.location.href = navigateUrl);
      // }

      console.log('onSWUpdated reload');
      return window.location.reload();
    }

    function onSWWaiting() {
      // There is a new version of Service Worker.
      // And now there are two of them.
      // Show the user that he can upgrade


      // TODO GUI

      showSnack({ message: 'A new version is now available' });
      // changed
      upgradeServiceWorker();
    }

    async function upgradeServiceWorker() {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({
          type: 'SKIP_WAITING',
        });
      }
    }

    async function checkForUpdates() {
      if (!wb) {
        return;
      }
      try {
        await wb.update();
      } finally {
        setTimeout(checkForUpdates, TIME_IN_MS);
      }
    }
  };
}
