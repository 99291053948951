import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TcSmartComponent, TcGenericListComponent, TcListRowActionButtonsPosition, TcListSortType, TcListFilterType } from '@tc/core';
import { Store } from '@ngrx/store';
import { Estimation } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import { AddListItem, EditListItem } from 'src/app/modules/core/store/actions/list.actions';
import { DashboardEntitiesEnum } from '../../../dashboard-entities-enum';

@Component({
  selector: 'app-dashboard-estimations-list',
  templateUrl: './dashboard-estimations-list.component.html',
  styleUrls: ['./dashboard-estimations-list.component.scss']
})
export class DashboardEstimationsListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  constructor(private store: Store<any>) {
    super();
  }

  estimationsList: TcGenericListComponent<Estimation>;
  @ViewChild('estimationsList', { static: true }) set setEstimationsList(values: TcGenericListComponent<Estimation>) {
    this.estimationsList = values;
    this.estimationsList.entityName = DashboardEntitiesEnum.DashboardEstimations;
  }

  ngAfterViewInit() {
    this.estimationsList.service.clearCache();
    this.estimationsList.service.getWithQuery({ initial: 'true' });
  }

  ngOnInit() {
    this.estimationsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.estimationsList.showTotalInActionsHeader = true;

    this.estimationsList.sortType = TcListSortType.Server;
    this.estimationsList.filterType = TcListFilterType.Disabled;
    this.estimationsList.hasFixedHeader = true;

    this.estimationsList.hasAddButton = true;
    this.estimationsList.addItemWhenKeyPresed = true;

    this.estimationsList.hasActionsLabel = false;
    this.estimationsList.isPaged = false;

    this.estimationsList.onScrollDown = () => {
      this.estimationsList.service.getAll();
    };

    this.estimationsList.columns = [
      {
        propertyName: 'name',
        visible: true
      }
    ];

    this.estimationsList.onRowAction = (row: Estimation, actionName: string) => {
      if (actionName === 'edit') {
        this.editEstimation(row);
      }
    };

    // this.estimationsList.onRowClick = (row: Estimation) => {
    //   this.editEstimation(row);
    // };

    this.estimationsList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Estimations }));
    };
  }

  private editEstimation(row: Estimation) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Estimations, data: row }));
  }

}
