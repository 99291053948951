import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, mergeMap } from 'rxjs/operators';
import {
    SelectCommercialImputation, InvoiceActionTypes,
    GenerateInvoice, GetLastInvoice, GetLastInvoiceSuccess,
    GenerateTemporaryInvoice, GenerateTemporaryInvoiceSuccess, GenerateFinalInvoice, GenerateFinalInvoiceSuccess
} from '../actions/invoice.actions';
import { InvoiceService } from 'src/app/services/invoice/invoice.service';
import { Store, select } from '@ngrx/store';
import { getSelectedLinesForInvoice, getInvoiceResultModel } from '../invoice.selectors';
import { Observable } from 'rxjs';
import { TcAction } from '@tc/core';
import { Invoice } from 'src/generated/api/krakenn-api/model/models';
import { InvoiceResultModel } from '../../models/invoice-result.model';

@Injectable()
export class InvoiceEffects {


    constructor(
        private store: Store<any>,
        private actions$: Actions,
        private invoiceService: InvoiceService
    ) { }

    @Effect({ dispatch: false })
    selectCommercialImputations = this.actions$.pipe(
        ofType<SelectCommercialImputation>(InvoiceActionTypes.SELECT_COMMERCIAL_IMPUTATION),
        withLatestFrom(this.store.pipe(select(getSelectedLinesForInvoice))),
        map(([action, lines]) => {
            this.invoiceService.selectCommercialImputation(lines, action.payload);
        })
    );

    @Effect({ dispatch: false })
    generateInvoice = this.actions$.pipe(
        ofType<GenerateInvoice>(InvoiceActionTypes.GENERATE_INVOICE),
        map((action: GenerateInvoice) => {
            this.invoiceService.generateInvoice();
        })
    );

    @Effect()
    getLastInvoice$: Observable<TcAction> = this.actions$.pipe(
        ofType<GetLastInvoice>(
            InvoiceActionTypes.GET_LAST_INVOICE
        ),
        mergeMap((action: GetLastInvoice) =>
            this.invoiceService.lastInvoice().pipe(
                map(
                    (invoice: Invoice) =>
                        new GetLastInvoiceSuccess(invoice)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    getLastInvoiceSuccess = this.actions$.pipe(
        ofType<GetLastInvoiceSuccess>(InvoiceActionTypes.GET_LAST_INVOICE_SUCCESS),
        withLatestFrom(this.store.pipe(select(getSelectedLinesForInvoice))),
        map(([action, lines]) => {
            this.invoiceService.getLastInvoiceSuccess(lines, action.payload);
        })
    );

    @Effect()
    generateTemporaryInvoice$: Observable<TcAction> = this.actions$.pipe(
        ofType<GenerateTemporaryInvoice>(
            InvoiceActionTypes.GENERATE_TEMPORARY_INVOICE
        ),
        mergeMap((action: GenerateTemporaryInvoice) => {

            return this.invoiceService.generateTemporayInvoice(action.payload).pipe(
                map(
                    (result: InvoiceResultModel) =>
                        new GenerateTemporaryInvoiceSuccess(result)
                )
            );
        }
        )
    );

    @Effect({ dispatch: false })
    generateTemporaryInvoiceSuccess = this.actions$.pipe(
        ofType<GenerateTemporaryInvoiceSuccess>(InvoiceActionTypes.GENERATE_TEMPORARY_INVOICE_SUCCESS),
        map((action: GenerateTemporaryInvoiceSuccess) => {
            this.invoiceService.generateTemporaryInvoiceSuccess(action.payload.pdfId, action.payload.clientInvoiceEmailList, action.payload.clientInvoiceEmailText);
        })
    );


    @Effect()
    generateFinalInvoice$: Observable<TcAction> = this.actions$.pipe(
        ofType<GenerateFinalInvoice>(
            InvoiceActionTypes.GENERATE_FINAL_INVOICE
        ),
        withLatestFrom(this.store.pipe(select(getInvoiceResultModel))),
        mergeMap(([action, invoiceResultModel]) => {
            const model = {
                ...invoiceResultModel,
                clientInvoiceEmailList: action.payload.clientInvoiceEmailList,
                clientInvoiceEmailText: action.payload.clientInvoiceEmailText
            }
            return this.invoiceService.generateFinalInvoice(model).pipe(
                map(
                    (result: any) =>
                        new GenerateFinalInvoiceSuccess(result)
                )
            );
        }
        )
    );

    @Effect({ dispatch: false })
    generateTemporaryFinalSuccess = this.actions$.pipe(
        ofType<GenerateFinalInvoiceSuccess>(InvoiceActionTypes.GENERATE_FINAL_INVOICE_SUCCESS),
        map((action: GenerateFinalInvoiceSuccess) => {
            this.invoiceService.generateFinalInvoiceSuccess(action.payload.invoiceNumber, action.payload.clientName, action.payload.emailSent);
        })
    );


}
