import { InvoiceActions, InvoiceActionTypes } from './actions/invoice.actions';
import { initialInvoiceState, InvoiceState } from './invoice.state';

export function invoiceReducer(
  state = initialInvoiceState,
  action: InvoiceActions
): InvoiceState {
  switch (action.type) {

    case InvoiceActionTypes.RESET_SELECTED_COMMERCIAL_IMPUTATIONS: {
      return {
        ...state,
        lines: [],
        lastInvoice: null,
        resultModel: null,
        result: null
      };
    }

    case InvoiceActionTypes.SELECT_COMMERCIAL_IMPUTATION: {

      const lines = [...state.lines];

      const index = lines.findIndex(ci => ci.id === action.payload.id);
      if (index >= 0) {
        lines[index] = action.payload;
      } else {
        lines.push(action.payload);
      }
      return {
        ...state,
        lines
      };
    }

    case InvoiceActionTypes.REMOVE_INVALID_COMMERCIAL_IMPUTATION:
    case InvoiceActionTypes.DESELECT_COMMERCIAL_IMPUTATION: {

      const lines = state.lines;
      const selectedLines = lines.filter(value => value.id !== action.payload.id);

      return {
        ...state,
        lines: selectedLines
      };
    }

    case InvoiceActionTypes.GET_LAST_INVOICE_SUCCESS: {
      return {
        ...state,
        lastInvoice: action.payload,
        resultModel: null,
        result: null
      };
    }

    case InvoiceActionTypes.GENERATE_TEMPORARY_INVOICE_SUCCESS: {
      return {
        ...state,
        resultModel: action.payload
      };
    }


    case InvoiceActionTypes.GENERATE_FINAL_INVOICE_SUCCESS: {
      return {
        ...state,
        lines: [],
        lastInvoice: null,
        resultModel: null,
        result: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
