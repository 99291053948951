import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { ContactsListComponent } from './components/smart/contacts-list/contacts-list.component';
import { EditContactPopupComponent } from './components/smart/edit-contact-popup/edit-contact-popup.component';

const smartComponents = [ContactsListComponent, EditContactPopupComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule
  ],
  exports: [
    ...smartComponents
  ]
})
export class ContactsModule { }
