import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposalDetailComponent } from './components/smart/proposal-detail/proposal-detail.component';
import { ProposalsListComponent } from './components/smart/proposals-list/proposals-list.component';
import { TcCoreModule } from '@tc/core';
import { CoreModule } from '../core/core.module';

const smartComponents = [
  ProposalDetailComponent,
  ProposalsListComponent
];

@NgModule({
  declarations: [
    ...smartComponents
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule
  ],
  entryComponents: [
    ProposalDetailComponent
  ],
  exports: [...smartComponents]
})
export class ProposalsModule { }
