import { Injectable } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { Effect, ofType, Actions } from "@ngrx/effects";
import { TranslateService } from '@ngx-translate/core';
import { TcAction } from '@tc/auth';
import { TcNotificationService } from '@tc/core';
import { TcTranslateConfig } from '@tc/core/src/tc-core/translate/tc-translate.config';
import { Observable } from 'rxjs';
import { map, mergeMap } from "rxjs/operators";
import { CommentsService } from 'src/app/services/comments.service';
import { Comment, CommentService } from 'src/generated/api/krakenn-api';
import { CommentsPopupComponent } from '../smart/components/comments-popup/comments-popup.component';
import { AddComment, AddCommentSuccess, CommentsActionTypes, DeleteComment, DeleteCommentSuccess, DisplayCommentsList, EditComment, EditCommentSuccess, LoadCommentsList, LoadCommentsListSuccess } from './comments.actions';

@Injectable()
export class CommentsEffects {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private commentsService: CommentsService,
    private notificationService: TcNotificationService,
    private translateService: TranslateService
  ) { }

  @Effect({ dispatch: false })
  displayCommentsList = this.actions$.pipe(
    ofType<DisplayCommentsList>(CommentsActionTypes.DISPLAY_COMMENTS_LIST),
    map((action: DisplayCommentsList) => {
      this.dialog.open(CommentsPopupComponent, {
        data: action.payload,
        width: '60vw'
      });
    })
  );


  @Effect()
  loadCommentsList$: Observable<TcAction> = this.actions$.pipe(
    ofType<LoadCommentsList>(
      CommentsActionTypes.LOAD_COMMENTS_LIST
    ),
    mergeMap((action: LoadCommentsList) =>

      this.commentsService.getComments(action.payload.data.id, action.payload.data.table).pipe(
        map(
          (comments: Comment[]) =>
            new LoadCommentsListSuccess(comments)
        )
      )
    )
  );


  @Effect()
  addComment$: Observable<TcAction> = this.actions$.pipe(
    ofType<AddComment>(
      CommentsActionTypes.ADD_COMMENT
    ),
    mergeMap((action: AddComment) =>

      this.commentsService.save(action.payload).pipe(
        map(
          (comment: Comment) =>
            new AddCommentSuccess(comment)
        )
      )
    )
  );

  @Effect({ dispatch: false })
  addCommentSucces = this.actions$.pipe(
    ofType<AddCommentSuccess>(CommentsActionTypes.ADD_COMMENT_SUCCESS),
    map((action: AddCommentSuccess) => {
      this.notificationService.success(this.translateService.instant('comments.notifications.add'));
    })
  );

  @Effect({ dispatch: false })
  editCommentSucces = this.actions$.pipe(
    ofType<EditCommentSuccess>(CommentsActionTypes.EDIT_COMMENT_SUCCESS),
    map((action: EditCommentSuccess) => {
      this.notificationService.success(this.translateService.instant('comments.notifications.edit'));
    })
  );

  @Effect({ dispatch: false })
  deleteCommentSucces = this.actions$.pipe(
    ofType<DeleteCommentSuccess>(CommentsActionTypes.DELETE_COMMENT_SUCCESS),
    map((action: DeleteCommentSuccess) => {
      this.notificationService.success(this.translateService.instant('comments.notifications.delete'));
    })
  );

  @Effect()
  editComment$: Observable<TcAction> = this.actions$.pipe(
    ofType<EditComment>(
      CommentsActionTypes.EDIT_COMMENT
    ),
    mergeMap((action: EditComment) =>

      this.commentsService.edit(action.payload).pipe(
        map(
          (comment: Comment) =>
            new EditCommentSuccess(comment)
        )
      )
    )
  );

  @Effect()
  deleteComment$: Observable<TcAction> = this.actions$.pipe(
    ofType<DeleteComment>(
      CommentsActionTypes.DELETE_COMMENT
    ),
    mergeMap((action: DeleteComment) =>

      this.commentsService.delete(action.payload).pipe(
        map(
          (id: number) =>
            new DeleteCommentSuccess(id)
        )
      )
    )
  );
}
