import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceDetailComponent } from './components/smart/invoice-detail/invoice-detail.component';
import { InvoicesListComponent } from './components/smart/invoices-list/invoices-list.component';
import { CoreModule } from '../core/core.module';
import { TcCoreModule } from '@tc/core';

const smartComponents = [
  InvoiceDetailComponent,
  InvoicesListComponent
];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    CoreModule,
    TcCoreModule
  ],
  entryComponents: [
    InvoiceDetailComponent
  ],
  exports: [...smartComponents]
})
export class InvoicesModule { }
