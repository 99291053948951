import { Comment } from '../api/krakenn-api';

export class CommentType {
  static className = 'Comment';
  static gqlRootQueryName = 'comment';
  static gqlColumns: string[] = [
      '_id',
      'taskId',
      'content',
      'collaboratorId',
      'creationDate',
      'lastUpdate',
  ];

  static gqlNodeMapper = (node: any): Comment => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Comment;
    }

}
