import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { Mission } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import { AddMultipleTasks } from '../../../store/tasks.actions';

@Component({
  selector: 'app-add-tasks-mass-popup',
  templateUrl: './add-tasks-mass-popup.component.html',
  styleUrls: ['./add-tasks-mass-popup.component.scss']
})
export class AddTasksMassPopupComponent extends TcFormComponent<any> implements OnInit {

  constructor(private qlAutocompleteService: QlAutcompleteService,
    private dialogRef: MatDialogRef<AddTasksMassPopupComponent>,
    translate: TcTranslateService,
    private store: Store<any>,
    elem: ElementRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    super(translate, elem);

    this.fields = [
      {
        key: 'missionId',
        type: 'autocomplete-list',
        templateOptions: {
          required: true,
          filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Mission>(EntitiesEnum.Missions, term, 'id', 'name,project{name client{name}}',
            (item: any) => {
              const clientName = item.project.client.name.substring(0, 10);
              const projectName = item.project.name.substring(0, 10);
              return `${clientName} / ${projectName} / ${item.name}`;
            },
            false,
            [{ property: 'status', filterType: 'In', value: '1' }]
          ),
          listValue: 'mission.name'
        },
        validators: {
          missionId: {
            expression: (c) => !c.value || /^\d+$/.test(c.value),
            message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
          },
        },
        expressionProperties: {
          'templateOptions.disabled': 'model.readOnly'
        },
      },
      {
        key: 'tasks',
        type: 'textarea',
        templateOptions: {
          required: true,
          autosize: true,
          autosizeMinRows: 10,
          autosizeMaxRows: 11
        }
      },
    ];
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onClose() {
    this.dialogRef.close();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.store.dispatch(new AddMultipleTasks(this.model));
    this.dialogRef.close();

  }
}
