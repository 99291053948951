import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TcSmartComponent, TcGenericListComponent, TcListRowActionButtonsPosition, TcListSortType, TcListFilterType } from '@tc/core';
import { Store } from '@ngrx/store';
import { Proposal } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import { AddListItem, EditListItem } from 'src/app/modules/core/store/actions/list.actions';
import { DashboardEntitiesEnum } from '../../../dashboard-entities-enum';

@Component({
  selector: 'app-dashboard-proposals-to-follow',
  templateUrl: './dashboard-proposals-to-follow.component.html',
  styleUrls: ['./dashboard-proposals-to-follow.component.scss']
})
export class DashboardProposalsToFollowComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  constructor(private store: Store<any>) {
    super();
  }

  proposalsList: TcGenericListComponent<Proposal>;
  @ViewChild('proposalsList', { static: true }) set setProposalsList(values: TcGenericListComponent<Proposal>) {
    this.proposalsList = values;
    this.proposalsList.entityName = DashboardEntitiesEnum.DashboardProposals;
  }

  ngAfterViewInit() {
    this.proposalsList.service.clearCache();
    this.proposalsList.service.getWithQuery({ initial: 'true' });
  }

  ngOnInit() {
    this.proposalsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.proposalsList.showTotalInActionsHeader = true;

    this.proposalsList.sortType = TcListSortType.Server;
    this.proposalsList.filterType = TcListFilterType.Disabled;
    this.proposalsList.hasFixedHeader = true;

    this.proposalsList.hasAddButton = true;
    this.proposalsList.addItemWhenKeyPresed = true;

    this.proposalsList.hasActionsLabel = false;
    this.proposalsList.isPaged = false;

    this.proposalsList.onScrollDown = () => {
      this.proposalsList.service.getAll();
    };

    this.proposalsList.columns = [
      {
        propertyName: 'name',
        visible: true
      }
    ];

    this.proposalsList.onRowAction = (row: Proposal, actionName: string) => {
      if (actionName === 'edit') {
        this.editProposal(row);
      }
    };

    // this.proposalsList.onRowClick = (row: Proposal) => {
    //   this.editProposal(row);
    // };

    this.proposalsList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Proposals }));
    };
  }

  private editProposal(row: Proposal) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Proposals, data: row }));
  }

}
