import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsListComponent } from './smart/components/comments-list/comments-list.component';
import { CommentItemComponent } from './smart/components/comment-item/comment-item.component';
import { TcCoreModule } from '@tc/core';
import { CoreModule } from '../core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { CommentsEffects } from './store/comments.effects';
import { StoreModule } from '@ngrx/store';
import { commentsReducer } from './store/comments.reducer';
import { CommentsPopupComponent } from './smart/components/comments-popup/comments-popup.component';
import { EditCommentPopupComponent } from './smart/components/edit-comment-popup/edit-comment-popup.component';


const smartComponents = [CommentItemComponent, CommentsListComponent, CommentsPopupComponent,
  EditCommentPopupComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule,
    StoreModule.forFeature('comments', commentsReducer),
    EffectsModule.forFeature([CommentsEffects]),
  ],
  entryComponents: [CommentsPopupComponent, EditCommentPopupComponent],
  exports: [...smartComponents],
})
export class CommentsModule { }
