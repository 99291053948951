import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import {
  TcGenericListComponent, TcListRowActionButtonsPosition, TcSmartComponent, TcListSortType, TcListFilterType,
  TcListDisplayColumnType, TcCurrencyFormat, TcListDefaultAction, TcFilterTypes, FilterTypesEnum, TcTranslateService
} from '@tc/core';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { Store } from '@ngrx/store';
import { EditListItem, AddListItem, filterListItems, CreateListItem, DuplicateListItem } from 'src/app/modules/core/store/actions/list.actions';
import { Opportunity } from 'src/generated/api/krakenn-api';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { OpportunityDbStatus } from 'src/app/modules/core/enums/opportunity-db-status.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-opportunities-list',
  templateUrl: './opportunities-list.component.html',
  styleUrls: ['./opportunities-list.component.scss']
})
export class OpportunitiesListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  listId = 'opportunities-list';

  activeOpportunities = [
    OpportunityDbStatus.Received,
    OpportunityDbStatus.Contacted,
    OpportunityDbStatus.FirstSalesMeetingFixed,
    OpportunityDbStatus.EstimationToBeDone,
    OpportunityDbStatus.EstimationDone,
    OpportunityDbStatus.ProposalToBeDone,
    OpportunityDbStatus.ProposalSentToFollow
  ];

  inactiveOpportunities = [
    OpportunityDbStatus.NOGO,
    OpportunityDbStatus.Won,
    OpportunityDbStatus.Lost,
    OpportunityDbStatus.Abandoned,
    OpportunityDbStatus.InStandBy
  ];


  constructor(private store: Store<any>,
    private tcCurrencyFormat: TcCurrencyFormat,
    private translate: TcTranslateService) {
    super();
  }

  @ViewChild('colCDCExistsTemplate', { static: true }) colCDCExistsTemplate: TemplateRef<any>;
  @ViewChild('colStatusTemplate', { static: true }) colStatusTemplate: TemplateRef<any>;
  @ViewChild('colBudgetTemplate', { static: true }) colBudgetTemplate: TemplateRef<any>;

  opportunitiesList: TcGenericListComponent<Opportunity>;
  @ViewChild('opportunitiesList', { static: true }) set setOpportunitiesList(values: TcGenericListComponent<Opportunity>) {

    this.opportunitiesList = values;
    this.opportunitiesList.entityName = EntitiesEnum.Opportunities;
  }

  ngAfterViewInit() {
  }

  ngOnInit() {

    this.opportunitiesList.hasTotalLine = true;
    this.opportunitiesList.totalDisplayColumn = 'contactEmail';
    this.opportunitiesList.totalLineColumns = ['budget'];

    this.opportunitiesList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.opportunitiesList.showTotalInActionsHeader = true;

    this.opportunitiesList.sortType = TcListSortType.Server;
    this.opportunitiesList.filterType = TcListFilterType.Server;
    this.opportunitiesList.hasFixedHeader = true;

    this.opportunitiesList.hasAddButton = true;
    this.opportunitiesList.addItemWhenKeyPresed = true;

    this.opportunitiesList.hasActionsLabel = false;
    this.opportunitiesList.isPaged = false;

    this.opportunitiesList.onScrollDown = () => {
      this.opportunitiesList.service.getAll();
    };

    this.opportunitiesList.filters = [
      {
        key: TcFilterTypes.anyFieldContains,
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.anyFieldContains,
        label: `${this.listId}.filter-label`,
        options: {
          minLength: 1,
          debounceTime: 500,
        }
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.inactive`,
          onLabel: `${this.listId}.filter.toggles.active`,
          default: true,
          onValue: this.activeOpportunities
        }
      },
      {
        key: 'signatureTargetDate',
        type: TcFilterTypes.daterange,
        label: `${this.listId}.filter.signatureTargetDate`,
        hidden: false,
        options: {
          hasReset: true
        }
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.multiselect,
        options: {
          items: new SelectValues().opportunityStatus(this.translate),
          label: `${this.listId}.filter.multiselect.status`,
          removable: true,
        }
      }
    ];

    this.opportunitiesList.columns = [
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'description',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Html
      },
      {
        propertyName: 'contactName',
        visible: true
      },
      {
        propertyName: 'contactPhone',
        visible: true
      },
      {
        propertyName: 'contactEmail',
        visible: true
      },
      {
        propertyName: 'budget',
        visible: true,
        htmlTemplate: this.colBudgetTemplate
      },
      {
        propertyName: 'cDCExists',
        visible: true,
        htmlTemplate: this.colCDCExistsTemplate
      },
      {
        propertyName: 'status',
        visible: true,
        htmlTemplate: this.colStatusTemplate
      },
      {
        propertyName: 'signatureProbability',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Percent,
      },
      {
        propertyName: 'signatureTargetDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date,
      },
    ];

    this.opportunitiesList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Delete,
        icon: 'delete',
        visible: true
      },
      {
        actionName: 'addEstimation',
        icon: 'add_shopping_cart',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Duplicate,
        icon: 'content_copy',
        visible: true
      }
    ];

    this.opportunitiesList.onRowAction = (row: Opportunity, actionName: string) => {
      if (actionName === 'edit') {
        this.editOpportunity(row);
      } else if (actionName === 'addEstimation') {
        this.addEstimation(row);
      }
    };

    this.opportunitiesList.onRowClick = (row: Opportunity) => {
      this.editOpportunity(row);
    };

    this.opportunitiesList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Opportunities }));
    };

    this.opportunitiesList.onDuplicateRow = (row: Opportunity) => {
      this.store.dispatch(new DuplicateListItem({ entityName: EntitiesEnum.Opportunities, data: row }));
    }
  }

  private editOpportunity(row: Opportunity) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Opportunities, data: row, args: { toCreate: { createEntity: EntitiesEnum.Proposals } } }));
  }

  private addEstimation(row: Opportunity) {
    this.store.dispatch(new CreateListItem({ entityName: EntitiesEnum.Opportunities, data: row, args: { toCreate: { createEntity: EntitiesEnum.Estimations } } }));
  }

  getStatusLabel(opportunity: Opportunity) {
    const result = new SelectValues().opportunityStatus(this.translate).find(p => p.value === opportunity.status);
    return result ? result.label : '';
  }

  getBudget = (row: Opportunity): string => {
    if (row.budget) {
      let bugdet = this.tcCurrencyFormat.transform(row.budget);
      if (bugdet) {
        bugdet = bugdet.replace(',00', '');
      }
      return bugdet;
    }
    return '';

  }

}
