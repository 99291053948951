import { Alert } from '../api/krakenn-api';

export class AlertType {
  static className = 'Alert';
  static gqlRootQueryName = 'alert';
  static gqlColumns: string[] = [
      '_id',
      'taskId',
      'type',
      'situationDescription',
      'consequencesDescription',
      'solutionToMinimizeImpact',
      'clientInformed',
  ];

  static gqlNodeMapper = (node: any): Alert => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Alert;
    }

}
