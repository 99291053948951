import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { EstimationsListComponent } from './components/smart/estimations-list/estimations-list.component';
import { EstimationDetailComponent } from './components/smart/estimation-detail/estimation-detail.component';
import { CoreModule } from '../core/core.module';
import { EstimationLinesListComponent } from './components/smart/estimation-lines-list/estimation-lines-list.component';
import { EstimationLineDetailComponent } from './components/smart/estimation-line-detail/estimation-line-detail.component';


const smartComponents = [
  EstimationsListComponent,
  EstimationDetailComponent,
  EstimationLinesListComponent,
  EstimationLineDetailComponent
];

@NgModule({
  declarations: [
    ...smartComponents,
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule
  ],
  entryComponents: [
    EstimationDetailComponent,
    EstimationLineDetailComponent
  ],
  exports: [...smartComponents]
})
export class EstimationsModule { }
