import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { TcSmartComponent, TcGenericListComponent, TcListRowActionButtonsPosition, TcListSortType, TcListFilterType } from '@tc/core';
import { Store } from '@ngrx/store';
import { Opportunity } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import { AddListItem, EditListItem } from 'src/app/modules/core/store/actions/list.actions';
import { DashboardEntitiesEnum } from '../../../dashboard-entities-enum';

@Component({
  selector: 'app-dashboard-hot-opportunities-list',
  templateUrl: './dashboard-hot-opportunities-list.component.html',
  styleUrls: ['./dashboard-hot-opportunities-list.component.scss']
})
export class DashboardHotOpportunitiesListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  constructor(private store: Store<any>) {
    super();
  }

  hotOpportunities: TcGenericListComponent<Opportunity>;
  @ViewChild('hotOpportunities', { static: true }) set setHotOpportunities(values: TcGenericListComponent<Opportunity>) {
    this.hotOpportunities = values;
    this.hotOpportunities.entityName = DashboardEntitiesEnum.DashboardHotOpportunities;
  }

  ngAfterViewInit() {
    this.hotOpportunities.service.clearCache();
    this.hotOpportunities.service.getWithQuery({ initial: 'true' });
  }

  ngOnInit() {
    this.hotOpportunities.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.hotOpportunities.showTotalInActionsHeader = true;

    this.hotOpportunities.sortType = TcListSortType.Server;
    this.hotOpportunities.filterType = TcListFilterType.Disabled;
    this.hotOpportunities.hasFixedHeader = true;

    this.hotOpportunities.hasAddButton = true;
    this.hotOpportunities.addItemWhenKeyPresed = true;

    this.hotOpportunities.hasActionsLabel = false;
    this.hotOpportunities.isPaged = false;

    this.hotOpportunities.onScrollDown = () => {
      this.hotOpportunities.service.getAll();
    };

    this.hotOpportunities.columns = [
      {
        propertyName: 'name',
        visible: true
      }
    ];

    this.hotOpportunities.onRowAction = (row: Opportunity, actionName: string) => {
      if (actionName === 'edit') {
        this.editOpportunity(row);
      }
    };

    // this.hotOpportunities.onRowClick = (row: Opportunity) => {
    //   this.editOpportunity(row);
    // };

    this.hotOpportunities.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Opportunities }));
    };
  }

  private editOpportunity(row: Opportunity) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Opportunities, data: row }));
  }

}
