import { Component, OnInit } from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent implements OnInit {

  constructor(private apollo: Apollo) { }

  data: string;

  ngOnInit() {
    this.data = 'test';

    const query = gql`
    query Q1($first: Int, $after: String, $orderBy: OrderBy, $filter: InvoiceFilterType){
      invoice (first: $first, after:$after, orderBy: $orderBy, filter: $filter){
        pageInfo{
          hasNextPage,
          hasPreviousPage
          startCursor,
          endCursor
        },
        edges {
          cursor,
          node {
            id,
            _id,
            number,
            client {
              id,
              _id
            }
          }
        }
      }
    }
    `;
    this.apollo.watchQuery({
      query,
      errorPolicy: 'all',
      variables: {
        first: 2
      }
    })
    .valueChanges.subscribe(d => {
      this.data = JSON.stringify(d, null, 4);
    });

  }

}
