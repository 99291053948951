import { Opportunity } from '../api/krakenn-api';

export class OpportunityType {
  static className = 'Opportunity';
  static gqlRootQueryName = 'opportunity';
  static gqlColumns: string[] = [
      '_id',
      'name',
      'description',
      'contactName',
      'contactPhone',
      'contactEmail',
      'budget',
      'cDCExists',
      'status',
      'signatureProbability',
      'signatureTargetDate',
  ];

  static gqlNodeMapper = (node: any): Opportunity => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Opportunity;
    }

}
