import { Injectable, Optional, Inject } from '@angular/core';
import { TcService, TcNotificationService } from '@tc/core';
import { CommercialImputation, Invoice, BASE_PATH, Configuration } from 'src/generated/api/krakenn-api';
import { Store } from '@ngrx/store';
import { RemoveInvalidCommercialImputation, GetLastInvoice, GenerateTemporaryInvoice, GenerateFinalInvoice } from 'src/app/modules/commercial-imputations/store/actions/invoice.actions';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceDateDialogComponent } from 'src/app/modules/commercial-imputations/components/dumb/invoice-date-dialog/invoice-date-dialog.component';
import { DatePipe } from '@angular/common';
import { InvoicePreviewComponent } from 'src/app/modules/commercial-imputations/components/dumb/invoice-preview/invoice-preview.component';
import { InvoicePayloadModel } from 'src/app/modules/commercial-imputations/models/invoice-payload.model';
import { InvoiceResultModel } from 'src/app/modules/commercial-imputations/models/invoice-result.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends TcService {


  protected basePath = 'https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();


  constructor(
    private store: Store<any>,
    private httpClient: HttpClient,
    private notificationService: TcNotificationService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super();

    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath = configuration.basePath || basePath || this.basePath;

    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  selectCommercialImputation(lines: CommercialImputation[], selected: CommercialImputation) {

    const commercialImputationsForSelectedClient = lines.filter(ci => (ci as any).mission.project.client._id !== (selected as any).mission.project.client._id);

    if (commercialImputationsForSelectedClient.length > 0) {
      this.notificationService.error('Please select commercial imputation for the same client!', 'Invalid selection');
      this.store.dispatch(new RemoveInvalidCommercialImputation(selected));
    } else {

      if (selected.invoiceId) {
        this.notificationService.error('This line has already been invoiced!', 'Invalid selection');
        this.store.dispatch(new RemoveInvalidCommercialImputation(selected));
      }

      if (selected.status !== 0 && selected.status !== 2) {
        this.notificationService.error('Line status should be Manual or Validated!', 'Invalid selection');
        this.store.dispatch(new RemoveInvalidCommercialImputation(selected));
      }

      if (lines.length > 18) {
        this.notificationService.error('You can select maximum 18 lines!', 'Invalid selection');
        this.store.dispatch(new RemoveInvalidCommercialImputation(selected));
      }

    }
  }

  generateInvoice() {
    this.store.dispatch(new GetLastInvoice());
  }

  getLastInvoiceSuccess(lines: CommercialImputation[], lastInvoice: Invoice) {

    const dateParse = new Date(lastInvoice.date);

    const minDate = new Date(dateParse.getFullYear(), dateParse.getMonth(), dateParse.getDate(), 0, 0, 0, 0)

    const dialog = this.dialog.open(InvoiceDateDialogComponent, {
      data: {
        minDate,
        defaultDate: this.getInvoiceDefaultDate(new Date(lastInvoice.date))
      }
    });

    const clientId = (lines[0] as any).mission.project.client._id;

    lines = lines.map(ci => {
      const clone = Object.assign({}, ci);
      clone.date = this.datePipe.transform(ci.date, 'dd/MM/yyyy');
      (clone as any).originalDate = ci.date;
      return clone;
    });;

    dialog.afterClosed().subscribe(date => {
      if (date) {
        const dateString = this.datePipe.transform(date, 'yyyy-MM-dd');
        this.store.dispatch(
          new GenerateTemporaryInvoice(
            {
              invoiceDateString: dateString,
              invoiceDate: date,
              invoiceNumber: this.getInvoiceNumber(date, new Date(lastInvoice.date), lastInvoice.number),
              clientId,
              lines: lines.sort((a, b) => (a as any).originalDate - (b as any).originalDate)
            }));
      }
    });
  }

  generateTemporaryInvoiceSuccess(pdfId: string, clientInvoiceEmailList: string, clientInvoiceEmailText: string) {
    if (pdfId) {
      const dialog = this.dialog.open(InvoicePreviewComponent, {
        width: '70vw',
        height: '90vh',
        maxHeight: '90vh',
        data: {
          pdfId,
          clientInvoiceEmailList,
          clientInvoiceEmailText
        },
        // autoFocus: false
      });

      dialog.afterClosed().subscribe(result => {
        if (result) {
          this.store.dispatch(new GenerateFinalInvoice({
            clientInvoiceEmailList: result.clientInvoiceEmailList,
            clientInvoiceEmailText: result.clientInvoiceEmailText
          }));
        }
      });
    }
  }


  getInvoiceDefaultDate(lastInvoiceDate: Date) {

    const currentDate = new Date();

    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

    let result = currentDate;

    if (currentDate.getDate() <= 6) {
      result = lastDayOfMonth;
    }

    if (result < lastInvoiceDate) {
      result = lastInvoiceDate;
    }

    return result;
  }

  generateFinalInvoiceSuccess(invoiceNumber: number, clientName: string, emailSent: boolean) {
    if (emailSent) {
      this.notificationService.success('Invoice with number ' + invoiceNumber + ' was generated successfully for ' + clientName + ' !');
    } else {
      const error = 'Invoice with number ' + invoiceNumber + ' was generated successfully for ' + clientName + ', but an error occured when sending the email to the client.';
      this.notificationService.error(error);
      console.error(error);
    }
  }

  getInvoiceNumber(newInvoiceDate: Date, oldInvoiceDate: Date, lastInvoiceNumber: number) {
    if (newInvoiceDate.getFullYear() !== oldInvoiceDate.getFullYear()) {
      const invoiceNewYear = newInvoiceDate.getFullYear().toString().slice(-2);
      lastInvoiceNumber = lastInvoiceNumber + 1;

      return '' + invoiceNewYear + '-' + lastInvoiceNumber;

    } else {
      const invoiceYear = oldInvoiceDate.getFullYear().toString().slice(-2);

      lastInvoiceNumber = lastInvoiceNumber + 1;
      return '' + invoiceYear + '-' + lastInvoiceNumber;
    }
  }



  lastInvoice(observe?: 'body', reportProgress?: boolean): Observable<Invoice> {

    const headers = this.defaultHeaders;

    return this.httpClient.get<Invoice>(`${this.configuration.basePath}/invoice/lastInvoice`,
      {
        withCredentials: true,
        headers,
        observe,
        reportProgress
      }
    );
  }


  generateTemporayInvoice(invoicePayloadModel: InvoicePayloadModel, observe?: 'body', reportProgress?: boolean): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'application/xml'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.configuration.basePath}/invoice/generateTemporaryInvoice`,
      invoicePayloadModel,
      {
        withCredentials: true,
        headers,
        observe,
        reportProgress
      }
    );
  }


  generateFinalInvoice(invoiceResultModel: InvoiceResultModel, observe?: 'body', reportProgress?: boolean): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'application/xml'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }


    return this.httpClient.post<any>(`${this.configuration.basePath}/invoice/generateFinalInvoice`,
      invoiceResultModel,
      {
        withCredentials: true,
        headers,
        observe,
        reportProgress
      }
    );
  }

}
