export enum EntitiesEnum {
  Activitys = 'Activitys',
  Alerts = 'Alerts',
  Assigns = 'Assigns',
  Clients = 'Clients',
  Collaborators = 'Collaborators',
  Comments = 'Comments',
  CommercialImputations = 'CommercialImputations',
  Contacts = 'Contacts',
  Covers = 'Covers',
  DbVersions = 'DbVersions',
  Estimations = 'Estimations',
  EstimationLines = 'EstimationLines',
  Holidays = 'Holidays',
  Invoices = 'Invoices',
  Leads = 'Leads',
  Missions = 'Missions',
  Opportunities = 'Opportunities',
  Params = 'Params',
  Payments = 'Payments',
  Projects = 'Projects',
  Proposals = 'Proposals',
  Rightss = 'Rightss',
  Roles = 'Roles',
  Tasks = 'Tasks',
  TimeImputations = 'TimeImputations',
}
