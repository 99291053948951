import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { AddEntityComponent } from './components/dumb/add-entity/add-entity.component';
import { CreateEditPopupComponent } from './components/smart/create-edit-popup/create-edit-popup.component';
import { ListEffects } from './store/effects/list.effects';
import { EffectsModule } from '@ngrx/effects';
import { AppFormlyAutocompleteComponent } from './autocomplete.component';
import { FiltersComponent } from './components/smart/filters/filters.component';
import { BigTextsComponent } from './components/dumb/big-texts/big-texts.component';
import { AgGridModule } from 'ag-grid-angular';

const components = [
  AddEntityComponent,
  AppFormlyAutocompleteComponent,
  FiltersComponent,
  CreateEditPopupComponent,
  BigTextsComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([ListEffects]),
    AgGridModule.withComponents([])
  ],
  exports: [
    ...components
  ],
  providers: [
  ],
  entryComponents: [
    CreateEditPopupComponent
  ]
})
export class CoreModule { }
