import { Component, ElementRef, Optional, Inject } from '@angular/core';
import { TcTranslateService, TcFormComponent } from '@tc/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-invoice-date-dialog',
  templateUrl: './invoice-date-dialog.component.html',
  styleUrls: ['./invoice-date-dialog.component.scss']
})
export class InvoiceDateDialogComponent extends TcFormComponent<any> {

  constructor(
    translate: TcTranslateService,
    elem: ElementRef,
    private dialogRef: MatDialogRef<InvoiceDateDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {

    super(translate, elem);
    this.fields = [
      {
        key: 'date',
        type: 'datepicker',
        templateOptions: {
          required: true,
          datepickerOptions: {
            filter: (d): boolean => {
              return d >= dialogData.minDate;
            }
          }
        }
      }
    ];

    if (dialogData) {
      if (dialogData.defaultDate) {
        const date = new Date(dialogData.defaultDate);
        this.model.date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
      }
    }

  }

  submit(model: any) {
    if (this.form.valid) {
      this.dialogRef.close(new Date(model.date));
    }
  }

  onClose() {
    this.dialogRef.close();
  }

}
