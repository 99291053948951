import { TcAction } from "@tc/core";
import { Task } from "src/generated/api/krakenn-api";

export enum TasksActionTypes {
  TERMINATE_TASK = "[Tasks] Terminate task",
  EDIT_TASK = "[Tasks] Edit task",
  OPEN_ADD_TASKS_MASS_DIALOG = "[Tasks] Open add tasks mass dialog",
  ADD_MULTIPLE_TASKS = "[Tasks] Add multiple tasks",
  ADD_MULTIPLE_TASKS_SUCCESS = "[Tasks] Add multiple tasks success"
}

export class TerminateTask implements TcAction {
  readonly type = TasksActionTypes.TERMINATE_TASK;
  constructor(
    public payload: {
      data: any;
    }
  ) { }
}

export class EditTask implements TcAction {
  readonly type = TasksActionTypes.EDIT_TASK;
  constructor(
    public payload: number
  ) { }
}

export class OpenAddTasksMassDialog implements TcAction {
  readonly type = TasksActionTypes.OPEN_ADD_TASKS_MASS_DIALOG;
  constructor() {}
}

export class AddMultipleTasks implements TcAction {
  readonly type = TasksActionTypes.ADD_MULTIPLE_TASKS;
  constructor (
    public payload: {
      data: any
    }
  ) {}
}

export class AddMultipleTaskSuccess implements TcAction {
  readonly type = TasksActionTypes.ADD_MULTIPLE_TASKS_SUCCESS;
  constructor(
    public payload: {
      data: any
    }
  ) {}
}

export type TasksActions =
  | TerminateTask
  | EditTask
  | OpenAddTasksMassDialog
  | AddMultipleTasks
  | AddMultipleTaskSuccess;
