import { TimeImputation } from './../../../../generated/api/krakenn-api/model/timeImputation';
import { TcAction } from '@tc/core';
import { MyTask } from '../models/my-task.model';

export enum DashboardActionTypes {
  INSERT_TIME_IMPUTATION = '[Dashboard - MyTasks] Insert Time Imputation',
  INSERT_TIME_IMPUTATION_SUCCESS = '[Dashboard - MyTasks] Insert Time Imputation Success',
  OPEN_TERMINATE_TASK_DIALOG = '[Dashboard - MyTasks] Open Terminate Task Dialog'
}

export class InsertTimeImputation implements TcAction {
  readonly type = DashboardActionTypes.INSERT_TIME_IMPUTATION;
  constructor(public payload: MyTask) { }
}

export class OpenTerminateTaskDialog implements TcAction {
  readonly type = DashboardActionTypes.OPEN_TERMINATE_TASK_DIALOG;
  constructor(public payload: MyTask) { }
}

export class InsertTimeImputationSuccess implements TcAction {
  readonly type = DashboardActionTypes.INSERT_TIME_IMPUTATION_SUCCESS;
  constructor(public payload: MyTask) { }
}

export type DashboardActions =
  | InsertTimeImputation
  | InsertTimeImputationSuccess;
