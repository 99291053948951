import { TcAction } from "@tc/core";
import { Holiday } from "src/generated/api/krakenn-api";

export enum HolidaysActionTypes {
  SEND_EMAIL_NEW_HOLIDAY_REQUEST = "[Holidays] Send Email New Holiday Request",
}

export class SendEmailNewHolidayRequest implements TcAction {
  readonly type = HolidaysActionTypes.SEND_EMAIL_NEW_HOLIDAY_REQUEST;
  constructor(
    public payload: {
      data: Holiday;
    }
  ) {}
}

export type HolidaysActions = SendEmailNewHolidayRequest;
