import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Login, Logout, getTcAuthUser } from '@tc/auth';
import { TcConfigService } from '@tc/core';
import { Subscription } from 'rxjs';
import { BypassLogin } from '../../../store/login.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  skipLoginEmail = 'krakenntest.dev@gmail.com';
  showBypassLoginButton = false;

  constructor(private store: Store<any>, private router: Router, private configService: TcConfigService) { }

  currentUserSubscription: Subscription;

  ngOnInit() {
    this.showBypassLoginButton = (this.configService.config as any).SHOW_BYPASS_LOGIN_BUTTON;
    this.currentUserSubscription = this.store.pipe(select(getTcAuthUser)).subscribe(user => {
      if (user != null) {
        this.router.navigate(['/']);
      }
    });

  }

  ngOnDestroy() {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  googleLogin() {
    this.store.dispatch(new Login({ providerId: 'Google', args: { prompt: 'select_account' } }));
  }

  bypassLogin() {
    this.store.dispatch(new BypassLogin({ email: this.skipLoginEmail }));
  }

}
