import { Injectable } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { Effect, ofType, Actions } from "@ngrx/effects";
import { TranslateService } from '@ngx-translate/core';
import { TcAction } from '@tc/auth';
import { TcNotificationService } from '@tc/core';
import { TcTranslateConfig } from '@tc/core/src/tc-core/translate/tc-translate.config';
import { Observable } from 'rxjs';
import { map, mergeMap } from "rxjs/operators";
import { ActivitysService } from 'src/app/services/activitys.service';
import { Activity } from 'src/generated/api/krakenn-api';
import { ActivitysPopupComponent } from '../components/smart/activitys-popup/activitys-popup.component';
import { AddActivity, AddActivitySuccess, ActivitysActionTypes, DeleteActivity, DeleteActivitySuccess, DisplayActivitysList,
  EditActivity, EditActivitySuccess, LoadActivitysList, LoadActivitysListSuccess, MarkActivityAsDone, MarkActivityAsDoneSuccess } from './activitys.actions';

@Injectable()
export class ActivitysEffects {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private activitysService: ActivitysService,
    private notificationService: TcNotificationService,
    private translateService: TranslateService
  ) { }

  @Effect({ dispatch: false })
  displayActivitysList = this.actions$.pipe(
    ofType<DisplayActivitysList>(ActivitysActionTypes.DISPLAY_ACTIVITYS_LIST),
    map((action: DisplayActivitysList) => {
      this.dialog.open(ActivitysPopupComponent, {
        data: action.payload,
        width: '60vw'
      });
    })
  );


  @Effect()
  loadActivitysList$: Observable<TcAction> = this.actions$.pipe(
    ofType<LoadActivitysList>(
      ActivitysActionTypes.LOAD_ACTIVITYS_LIST
    ),
    mergeMap((action: LoadActivitysList) =>

      this.activitysService.getActivitys(action.payload.data.id, action.payload.data.table).pipe(
        map(
          (activitys: Activity[]) =>
            new LoadActivitysListSuccess(activitys)
        )
      )
    )
  );


  @Effect()
  addActivity$: Observable<TcAction> = this.actions$.pipe(
    ofType<AddActivity>(
      ActivitysActionTypes.ADD_ACTIVITY
    ),
    mergeMap((action: AddActivity) =>

      this.activitysService.save(action.payload).pipe(
        map(
          (activity: Activity) =>
            new AddActivitySuccess(activity)
        )
      )
    )
  );

  @Effect({ dispatch: false })
  addActivitySucces = this.actions$.pipe(
    ofType<AddActivitySuccess>(ActivitysActionTypes.ADD_ACTIVITY_SUCCESS),
    map((action: AddActivitySuccess) => {
      this.notificationService.success(this.translateService.instant('activitys.notifications.add'));
    })
  );

  @Effect({ dispatch: false })
  editActivitySucces = this.actions$.pipe(
    ofType<EditActivitySuccess>(ActivitysActionTypes.EDIT_ACTIVITY_SUCCESS),
    map((action: EditActivitySuccess) => {
      this.notificationService.success(this.translateService.instant('activitys.notifications.edit'));
    })
  );

  @Effect({ dispatch: false })
  deleteActivitySucces = this.actions$.pipe(
    ofType<DeleteActivitySuccess>(ActivitysActionTypes.DELETE_ACTIVITY_SUCCESS),
    map((action: DeleteActivitySuccess) => {
      this.notificationService.success(this.translateService.instant('activitys.notifications.delete'));
    })
  );

  @Effect()
  editActivity$: Observable<TcAction> = this.actions$.pipe(
    ofType<EditActivity>(
      ActivitysActionTypes.EDIT_ACTIVITY
    ),
    mergeMap((action: EditActivity) =>

      this.activitysService.edit(action.payload).pipe(
        map(
          (activity: Activity) =>
            new EditActivitySuccess(activity)
        )
      )
    )
  );

  @Effect()
  markActivityAsDone$: Observable<TcAction> = this.actions$.pipe(
    ofType<MarkActivityAsDone>(
      ActivitysActionTypes.MARK_ACTIVITY_AS_DONE
    ),
    mergeMap((action: MarkActivityAsDone) =>

      this.activitysService.markActivityAsDone(action.payload).pipe(
        map(
          (activity: Activity) =>
            new EditActivitySuccess(activity)
        )
      )
    )
  );

  @Effect({ dispatch: false })
  markActivityAsDoneSucces = this.actions$.pipe(
    ofType<MarkActivityAsDoneSuccess>(ActivitysActionTypes.MARK_ACTIVITY_AS_DONE_SUCCESS),
    map((action: MarkActivityAsDoneSuccess) => {
      this.notificationService.success(this.translateService.instant('activitys.notifications.mark-activity-as-done'));
    })
  );

  @Effect()
  deleteActivity$: Observable<TcAction> = this.actions$.pipe(
    ofType<DeleteActivity>(
      ActivitysActionTypes.DELETE_ACTIVITY
    ),
    mergeMap((action: DeleteActivity) =>

      this.activitysService.delete(action.payload).pipe(
        map(
          (id: number) =>
            new DeleteActivitySuccess(id)
        )
      )
    )
  );
}
