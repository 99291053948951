import { createSelector } from '@ngrx/store';
import { EntitySelectorsFactory } from 'ngrx-data';
import { DashboardEntitiesEnum } from '../../dashboard/dashboard-entities-enum';

export const myTasksSelectors = new EntitySelectorsFactory().create<any>(DashboardEntitiesEnum.DashboardMyTasks);

export const myTasks = createSelector(
    myTasksSelectors.selectEntities,
    (tasks) => tasks
);
