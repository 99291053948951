import { Project } from './../../../../../../generated/api/krakenn-api/model/project';
import { EntitiesEnum } from './../../../../../../generated/EntitiesEnum';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import {
  TcGenericListComponent, TcListRowActionButtonsPosition, TcListSortType, TcListFilterType, TcSmartComponent,
  TcListDisplayColumnType, TcListDefaultAction, TcFilterTypes, FilterTypesEnum
} from '@tc/core';
import { Client, Collaborator, Mission, Task } from 'src/generated/api/krakenn-api';
import { Store } from '@ngrx/store';
import { EditListItem, AddListItem, DuplicateListItem } from 'src/app/modules/core/store/actions/list.actions';
import { DisplayCommentsList } from 'src/app/modules/comments/store/comments.actions';
import { TaskDbTerminated } from 'src/app/modules/core/enums/task-db-terminated.enum';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import * as moment from 'moment';
import { CommentsTable } from 'src/app/modules/comments/enums/comments-table.enum';
import { Subscription } from 'rxjs';
import { ListRefreshService, ListRefreshType } from 'src/app/services/list-refresh.service';
import { skipWhile } from 'rxjs/operators';
import { MissionDbStatus } from 'src/app/modules/core/enums/mission-db-status.enum';
import { TargetDateService } from 'src/app/services/target-date.service';


@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})
export class TasksListComponent extends TcSmartComponent implements OnInit, AfterViewInit, OnDestroy {

  listId = 'tasks-list';

  private refreshSubscription: Subscription;

  constructor(
    private store: Store<any>,
    private qlAutocompleteService: QlAutcompleteService,
    private readonly listRefreshService: ListRefreshService,
    private readonly targetDateService: TargetDateService) {
    super();
  }

  @ViewChild('colClientNameTemplate', { static: true }) colClientNameTemplate: TemplateRef<any>;
  @ViewChild('colProjectNameTemplate', { static: true }) colProjectNameTemplate: TemplateRef<any>;
  @ViewChild('colTerminatedTemplate', { static: true }) colTerminatedTemplate: TemplateRef<any>;
  @ViewChild('rowActionDeleteTemplate', { static: true }) rowActionDeleteTemplate: TemplateRef<any>;
  @ViewChild('rowActionCommentTemplate', { static: true }) rowActionCommentTemplate: TemplateRef<any>;

  tasksList: TcGenericListComponent<Task>;
  @ViewChild('tasksList', { static: true }) set setTasksList(values: TcGenericListComponent<Task>) {
    this.tasksList = values;
    this.tasksList.entityName = EntitiesEnum.Tasks;
  }

  ngAfterViewInit() {
  }

  async ngOnInit() {

    const format = 'YYYY-MM-DD';

    const dateString = moment(new Date()).format(format);
    const day = moment(dateString, format).day();

    this.refreshSubscription = this.listRefreshService.subject
      .pipe(skipWhile((type) => type !== ListRefreshType.Tasks))
      .subscribe(() => this.refresh(true));

    this.tasksList.hasTotalLine = true;
    this.tasksList.totalDisplayColumn = 'description';
    this.tasksList.totalLineColumns = ['targetPoints', 'targetPointsPerCollaborator', 'sumTimeImputation', 'consumedPerCollaborator'];

    this.tasksList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;

    this.tasksList.showTotalInActionsHeader = true;

    this.tasksList.hasActionsLabel = false;
    this.tasksList.isPaged = false;

    this.tasksList.sortType = TcListSortType.Server;
    this.tasksList.filterType = TcListFilterType.Server;

    this.tasksList.hasAddButton = true;
    this.tasksList.addItemWhenKeyPresed = true;

    this.tasksList.hasFixedHeader = true;

    this.tasksList.onScrollDown = () => {
      this.tasksList.service.getAll();
    };

    this.tasksList.deleteYesFocused = true;

    this.tasksList.filters = [
      {
        key: TcFilterTypes.anyFieldContains,
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.anyFieldContains,
        label: `${this.listId}.filter-label`,
        options: {
          minLength: 1,
          debounceTime: 500,
        }
      },
      {
        key: 'terminated',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.notTerminated`,
          onLabel: ``,
          default: true,
          onValue: [TaskDbTerminated.NotTerminated]
        }
      },
      {
        key: 'terminated',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.terminated`,
          onLabel: ``,
          default: false,
          onValue: [TaskDbTerminated.Terminated]
        }
      },
      {
        key: 'targetDate',
        type: TcFilterTypes.daterange,
        label: `${this.listId}.filter.targetDate`,
        hidden: false,
        options: {
          format: 'DD/MM/YYYY',
          default: this.targetDateService.getDefaultDate(),
          hasReset: true
        }
      },
      {
        key: 'collaboratorsTrigram',
        filterType: 'Includes' as FilterTypesEnum,
        type: TcFilterTypes.multiselect,
        options: {
          filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Collaborator>(EntitiesEnum.Collaborators, term, 'trigram', 'trigram',
            null,
            false,
            [{ property: 'expirationDate', filterType: 'IsNull', value: '1' }]
          ),
          label: `${this.listId}.filter.multiselect.collaboratorsTrigram`,
          removable: true,
        }
      },
      {
        key: 'client.name',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.multiselect,
        options: {
          filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Client>(EntitiesEnum.Clients, term, 'name', 'name'),
          label: `${this.listId}.filter.multiselect.client`,
          removable: true,
        }
      },
      {
        key: 'project.name',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.multiselect,
        options: {
          filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Project>(EntitiesEnum.Projects, term, 'name', 'name'),
          label: `${this.listId}.filter.multiselect.project`,
          removable: true,
        }
      },
      {
        key: 'mission.name',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.multiselect,
        options: {
          filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Mission>(EntitiesEnum.Missions, term, 'name', 'name,project{name client{name}}',
            (item: any) => {
              const clientName = item.project.client.name.substring(0, 10);
              const projectName = item.project.name.substring(0, 10);
              return `${clientName} / ${projectName} / ${item.name}`;
            },
            false,
            [{ property: 'status', filterType: 'In', value: '1' }]
          ),
          label: `${this.listId}.filter.multiselect.mission`,
          removable: true,
        }
      },
      {
        key: 'mission.status',
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.text,
        label: '',
        hidden: true,
        options: {
          default: MissionDbStatus.InProgress.toString(),
        }
      }
    ];

    this.tasksList.columns = [
      {
        propertyName: 'id',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Number
      },
      {
        propertyName: 'client.name',
        visible: true,
        htmlTemplate: this.colClientNameTemplate
      },
      {
        propertyName: 'project.name',
        visible: true,
        htmlTemplate: this.colProjectNameTemplate
      },
      {
        propertyName: 'mission.name',
        visible: true,
      },
      {
        propertyName: 'jiraId',
        visible: true,
      },
      {
        propertyName: 'collaboratorsTrigram',
        visible: true,
      },
      {
        propertyName: 'name',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Html
      },
      {
        propertyName: 'description',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Html
      },
      {
        propertyName: 'targetPoints',
        visible: true,
      },
      {
        propertyName: 'sumTimeImputation',
        visible: true
      },
      {
        propertyName: 'targetPointsPerCollaborator',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Number
      },
      {
        propertyName: 'consumedPerCollaborator',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Number
      },
      {
        propertyName: 'targetDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'globalPriority',
        visible: true,
      },
      {
        propertyName: 'terminated',
        visible: true,
        htmlTemplate: this.colTerminatedTemplate
      }
    ];

    this.tasksList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'comment',
        icon: 'comment',
        visible: true,
        htmlTemplate: this.rowActionCommentTemplate
      },
      {
        actionName: TcListDefaultAction.Duplicate,
        icon: 'content_copy',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Delete,
        icon: 'delete',
        visible: true,
        htmlTemplate: this.rowActionDeleteTemplate
      }
    ];

    this.tasksList.onRowAction = (row: Task, actionName: string) => {
      if (actionName === 'edit') {
        this.editTask(row);
      }
    };


    this.tasksList.onRowClick = (row: Task) => {
      this.editTask(row);
    };

    this.tasksList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Tasks }));
    };

    this.tasksList.onDuplicateRow = (row: Task) => {
      this.store.dispatch(new DuplicateListItem({ entityName: EntitiesEnum.Tasks, data: row }));
    }

  }

  private editTask(row: Task) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Tasks, data: row }));
  }

  deleteTask(row: Task) {
    this.tasksList.tcList.onDeleteAction(row);
  }

  showComments(row: Task) {
    this.store.dispatch(new DisplayCommentsList({ table: CommentsTable.Task, id: row.id, args: { title: 'comments-list.tasks.title' } }));
  }

  refresh(initial = false) {
    this.tasksList.refresh(initial);
  }

  ngOnDestroy() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

}
