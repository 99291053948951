import { Subscription } from 'rxjs';
import { Component, TemplateRef, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TcSmartComponent,
  TcTranslateService,
  TcValidationsService,
  TcGridCellComponent,
  TcConfirmDialogComponent,
  TcGridTemplateRendererComponent,
  TcGridActionButtonsPositions,TcNotificationService } from '@tc/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { ContactsService } from './../../../../../services/contacts.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent extends TcSmartComponent implements OnDestroy,OnInit {
  public massUpdateCapabilityActivated = true;
  public hasRowActions = true;
  public rowActionsButtonPosition = TcGridActionButtonsPositions.BeforeData;
  public rowActionButtons = [
    {
      icon: 'delete',
      hidden: (node) => node?.noOfActivities === 0 && node?.noOfLeads === 0 ? false : true,
      action: async (node) => {
        console.log('Delete node', node);
        this.deleteRow(node);
      },
    }
  ];

  public massUpdateTemplateOptions = [];

  @ViewChild('rowActionDeleteTemplate', { static: true }) rowActionDeleteTemplate: TemplateRef<any>;

  public autoFocus: { should: boolean, rowIndex: number, colKey: string } = { should: false, rowIndex: null, colKey: null };

  public rows: any[] = [];
  public gridOptions: GridOptions = {
    rowModelType: '',
    domLayout: 'autoHeight',
    rowSelection: 'multiple'
  };

  public enableMassUpdate = true;
  public performingMassUpdate = false;

  public columnsDefinitions: ColDef[];

  private gridApi: GridApi;
  private gridColumnApi;

  rowsSubscription: Subscription;

  constructor(
    private translate: TcTranslateService,
    private dialog: MatDialog,
    private contactService: ContactsService,
    private notificationService: TcNotificationService,
    private readonly tcValidationsService: TcValidationsService,) {
    super();
  }

  ngOnInit(): void {
    this.columnsDefinitions = [
      {
        field: 'id',
        editable: false,
        hide: true,
        cellEditor: TcGridCellComponent.MatInputEditor,
        headerValueGetter: () => this.translate.instant('id'),
        sortable: true
      },
      {
        field: 'firstName',
        editable: true,
        cellEditor: TcGridCellComponent.MatInputEditor,
        sortable: true,
        filter: true,
        headerValueGetter: () => this.translate.instant('firstName'),
        cellEditorParams: {
          validators: [
            this.tcValidationsService.required(this.translate.instant('firstName.required')),
          ],
        },
      },
      {
        field: 'lastName',
        editable: true,
        cellEditor: TcGridCellComponent.MatInputEditor,
        sortable: true,
        filter: true,
        headerValueGetter: () => this.translate.instant('lastName'),
      },
      {
        field: 'role',
        editable: true,
        cellEditor: TcGridCellComponent.MatInputEditor,
        sortable: true,
        filter: true,
        headerValueGetter: () => this.translate.instant('role'),
      },
      {
        field: 'phone',
        editable: true,
        cellEditor: TcGridCellComponent.MatInputEditor,
        sortable: true,
        filter: true,
        headerValueGetter: () => this.translate.instant('phoneNumber'),
      },
      {
        field: 'email',
        editable: true,
        cellEditor: TcGridCellComponent.MatInputEditor,
        sortable: true,
        filter: true,
        headerValueGetter: () => this.translate.instant('email'),
        cellEditorParams: {
          validators: [
            this.tcValidationsService.isEmail(this.translate.instant('email.invalidEmail')),
          ],
        }
      },
    ];
    this.loadAllRows();
  }

  public onReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;

    this.loadAllRows();
  }

  public onMassUpdate(event) {
    event.afterClosed.subscribe((result) => {
      const noRowsUpdated = this.gridApi.getSelectedNodes().length;

      this.gridApi.getSelectedNodes().forEach((rowNode) => {
        rowNode.setDataValue(result.field, result.newValue);
        this.updateRowNode(rowNode);
      });

      this.notificationService.success(noRowsUpdated + this.translate.instant('task-grid.dialog.success'));
    });
  }

   public updateRowNode(node) {
    const contact = node?.data;

    this.contactService.edit(contact).subscribe(
      (result) => {
        console.log('Result ' + result);
        this.loadAllRows();
      }
    );
  }

  public updateCell(node) {

    if (node.value === node.oldValue) {
      return;
    }

    if (node.column.getColId() !== this.gridApi.getFocusedCell().column.getColId()) {
      this.autoFocus = { should: true, rowIndex: node.rowIndex, colKey: this.gridApi.getFocusedCell().column.getColId() }
    }

    const contact = node?.data;

    if (!contact?.id) {
      delete (contact as any).id;
      this.contactService.save(contact).subscribe(
        (result) => {
          this.loadAllRows();
        }
      );
    } else {
      this.contactService.edit(contact).subscribe(
        (result) => {
          this.loadAllRows();
        }
      );
    }

  }

  editContact(node: any) {
    throw new Error('Method not implemented.');
  }

  public addRow() {
    this.rows.unshift({
      id: null,
      firstName: null,
      lastName: null,
      role: null,
      phone: null,
      email: null,
    });
    this.gridApi?.setRowData(this.rows);
    this.gridApi?.startEditingCell({ rowIndex: 0, colKey: 'firstName' });
  }

  public deleteRow(node) {
    const dialog = this.dialog.open(TcConfirmDialogComponent, {
      data: {
        noText: 'no',
        title: 'contacts.dialog.title',
        yesText: 'yes',
        message: 'contacts.dialog.message',
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.contactService.delete(node.id).subscribe((id) => {
          this.loadAllRows();
        })
        this.gridApi?.setRowData(this.rows);
      }
    });
  }

  autoSizeAll(skipHeader) {
    const allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  private async loadAllRows() {
    this.rowsSubscription = this.contactService.getAllContacts().subscribe((contacts) => {
      this.rows = contacts;
      this.gridApi?.setRowData(this.rows);
      if (this.autoFocus?.should) {
        this.gridApi.startEditingCell({ rowIndex: this.autoFocus.rowIndex, colKey: this.autoFocus.colKey })
        this.autoFocus = { should: false, rowIndex: null, colKey: null };
      }
      this.autoSizeAll(false);
    });
  }

  ngOnDestroy() {
    this.rowsSubscription?.unsubscribe();
  }

}
