import { Injectable } from "@angular/core";
import { Effect, ofType, Actions } from "@ngrx/effects";
import { SendEmailNewHolidayRequest, HolidaysActionTypes } from "./holidays.actions";
import { HolidaysService } from "src/app/services/holidays.service";
import { map } from "rxjs/operators";
import { TcTranslateService, TcNotificationService } from '@tc/core';

@Injectable()
export class HolidaysEffects {
    constructor(
        private actions$: Actions,
        private holidaysService: HolidaysService,
        private translateService: TcTranslateService,
        private notificationService: TcNotificationService
    ) { }

    @Effect({ dispatch: false })
    sendEmailNewHolidayRequest = this.actions$.pipe(
        ofType<SendEmailNewHolidayRequest>(
            HolidaysActionTypes.SEND_EMAIL_NEW_HOLIDAY_REQUEST
        ),
        map((action: SendEmailNewHolidayRequest) => {
            this.holidaysService.sendEmailForNewHolidayRequest(action.payload.data).subscribe(s => {
                if(s === 'ok'){
                    this.notificationService.success(this.translateService.instant('holidays.notifications.success'));
                }
             })
        })
    );
}
