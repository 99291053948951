import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadDetailComponent } from './components/smart/lead-detail/lead-detail.component';
import { LeadsListComponent } from './components/smart/leads-list/leads-list.component';
import { TcCoreModule } from '@tc/core';
import { CoreModule } from '../core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { ActivityItemComponent } from './components/smart/activity-item/activity-item.component';
import { ActivitysListComponent } from './components/smart/activitys-list/activitys-list.component';
import { ActivitysEffects } from './store/activitys.effects';
import { StoreModule } from '@ngrx/store';
import { activitysReducer } from './store/activitys.reducer';
import { ActivitysPopupComponent } from './components/smart/activitys-popup/activitys-popup.component';
import { EditActivityPopupComponent } from './components/smart/edit-activity-popup/edit-activity-popup.component';
import { ContactEditorFieldComponent } from './components/smart/contact-editor-field/contact-editor-field.component';

const smartComponents = [
  LeadDetailComponent,
  LeadsListComponent,
  ActivityItemComponent,
  ActivitysListComponent,
  ActivitysPopupComponent,
  EditActivityPopupComponent
];

@NgModule({
  declarations: [
    ...smartComponents,
    ContactEditorFieldComponent
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule,
    StoreModule.forFeature('activitys', activitysReducer),
    EffectsModule.forFeature([ActivitysEffects]),
  ],
  entryComponents: [
    LeadDetailComponent
  ],
  exports: [
    ...smartComponents
  ]
})
export class LeadsModule { }
