import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsListComponent } from './components/smart/clients-list/clients-list.component';
import { TcCoreModule } from '@tc/core';
import { ClientDetailComponent } from './components/smart/client-detail/client-detail.component';
import { CoreModule } from '../core/core.module';


const smartComponents = [
  ClientsListComponent,
  ClientDetailComponent
];

@NgModule({
  declarations: [
    ...smartComponents
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule
  ],
  entryComponents: [
    ClientDetailComponent
  ],
  exports: [...smartComponents]
})
export class ClientsModule { }
