import { Inject, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TcDialogComponent } from '@tc/core';
import { TerminateTask } from '../../../store/tasks.actions';

@Component({
  selector: 'app-terminate-task-popup',
  templateUrl: './terminate-task-popup.component.html',
  styleUrls: ['./terminate-task-popup.component.scss']
})
export class TerminateTaskPopupComponent extends TcDialogComponent implements OnInit {

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<TerminateTaskPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
  }

  ngOnInit() {
  }

  terminate() {
    if(this.data){
      this.store.dispatch(new TerminateTask(this.data));
    }
  }
  cancel() {
    this.dialogRef.close();
  }

  onClose() {
    this.dialogRef.close();
  }

}
