import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { OpportunityService } from "src/app/services/opportunity.service";
import {
  AddOpportunityProposal,
  CreateOpportunityEstimation,
  OpportunitiesActionTypes,
} from "./opportunities.actions";
import { map } from "rxjs/operators";

@Injectable()
export class OpportunitiesEffects {
  constructor(
    private actions$: Actions,
    private opportunityService: OpportunityService
  ) {}

  @Effect({ dispatch: false })
  addOpportunityProposal = this.actions$.pipe(
    ofType<AddOpportunityProposal>(
      OpportunitiesActionTypes.ADD_OPPORTUNITY_PROPOSAL
    ),
    map((action: AddOpportunityProposal) => {
      this.opportunityService.addOpportunityProposal(
        action.payload.entityName,
        action.payload.data,
        action.payload.args
      );
    })
  );

  @Effect({dispatch: false})
  createOpportunityEstimation = this.actions$.pipe(
    ofType<CreateOpportunityEstimation>(
      OpportunitiesActionTypes.CREATE_OPPORTUNITY_ESTIMATION
    ),
    map((action: CreateOpportunityEstimation) => {
      this.opportunityService.createOpportunityEstimation(
        action.payload.entityName,
        action.payload.data,
        action.payload.args
      );
    })
  );
}
