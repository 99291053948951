import { TcConfigService, TcService } from '@tc/core';
import { Injectable } from '@angular/core';
const { version } = require('../../../package.json');

@Injectable({
  providedIn: 'root'
})
export class VersionService extends TcService {

  constructor(private configService: TcConfigService) {
    super();
  }

  public getVersion(): string {
    return version + '-' + (this.configService.config as any).BUILD_DATE;
  }

}
