import { Component, ElementRef, OnInit } from "@angular/core";
import { TcTranslateService, TcGenericFormComponent } from "@tc/core";
import { EntityCollectionServiceFactory } from "ngrx-data";
import { Collaborator } from "src/generated/api/krakenn-api";
import { EntitiesEnum } from "src/app/modules/core/enums/entities-enum";
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: "app-collaborator-detail",
  templateUrl: "./collaborator-detail.component.html",
  styleUrls: ["./collaborator-detail.component.scss"],
})
export class CollaboratorDetailComponent extends TcGenericFormComponent<
Collaborator
> implements OnInit {
  constructor(
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {
    super(
      EntitiesEnum.Collaborators,
      entityCollectionServiceFactory,
      translate,
      elem,
      dateAdapter,
    );

    this.fields = [
      {
        fieldGroupClassName: "form-display-grid",
        fieldGroup: [
          {
            key: "jiraLogin",
            type: "input",
            templateOptions: {
              type: "text",
              required: false,
              maxLength: 50,
            },
          },
          {
            key: "firstName",
            type: "input",
            templateOptions: {
              type: "text",
              required: true,
              maxLength: 50,
            },
          },
          {
            key: "lastName",
            type: "input",
            templateOptions: {
              type: "text",
              required: true,
              maxLength: 50,
            },
          },
          {
            key: "companyName",
            type: "input",
            templateOptions: {
              type: "text",
              required: false,
              maxLength: 50,
            },
          },
          {
            key: "costPerDay",
            type: "input",
            templateOptions: {
              type: "number",
              required: true,
            },
          },
          {
            key: "email",
            type: "input",
            templateOptions: {
              type: "text",
              required: true,
              maxLength: 255,
            },
          },
          {
            key: "phoneNumber",
            type: "input",
            templateOptions: {
              type: "text",
              required: false,
              maxLength: 255,
            },
          },
          {
            key: "expirationDate",
            type: "datepicker",
            templateOptions: {
              required: false,
              type: "text",
              datepickerPopup: "dd-MMMM-yyyy",
              datepickerOptions: {
                format: "dd-MM-yyyy hh:mm:ss",
              },
            },
          },
          {
            key: 'scheduledReports',
            type: 'textarea',
            templateOptions: {
              autosize: true,
              autosizeMinRows: 8,
              autosizeMaxRows: 8,
            }
          },
          {
            key: "trigram",
            type: "input",
            templateOptions: {
              required: true,
              type: "text",
              maxLength: 3,
            },
          }
        ],
      },
    ];
  }

  ngOnInit() {
    if (!this.model.id) {
      this.model.expirationDate = null;
    }
    super.ngOnInit();
  }
}
