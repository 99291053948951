import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JQueryService {

  setAddTasksMassButtonTooltip = () => {
    $(() => {
      $('.add-tasks-mass-button').tooltip({
        position: { my: 'left bottom', at: 'left-550 bottom-30', collision: 'flip flip' },
        // tslint:disable-next-line
        content: function () {
          return $(this).prop('title');
        },
        // tslint:disable-next-line
        open: function (event, ui) {
          ui.tooltip.css('max-width', '700px');
          ui.tooltip.css('min-width', '700px');
        }
      });
      $('.add-tasks-mass-button').on('mouseover', () => {
        $('.add-tasks-mass-button').tooltip('enable');
      });
      $('.add-tasks-mass-button').on('click', () => {
        $('.add-tasks-mass-button').tooltip('disable');
      });
    });
  }

}
