import { CommentsActions, CommentsActionTypes } from './comments.actions';
import { CommentsState, initialCommentsState } from './comments.state';

export function commentsReducer(
    state = initialCommentsState,
    action: CommentsActions
): CommentsState {
    switch (action.type) {

        case CommentsActionTypes.LOAD_COMMENTS_LIST_SUCCESS: {

            return {
                ...state,
                commentsList: action.payload
            };
        }

        case CommentsActionTypes.DISPLAY_COMMENTS_LIST: {
            return {
                ...state,
                commentsList: []
            };
        }

        case CommentsActionTypes.ADD_COMMENT_SUCCESS: {

            const comments = state.commentsList;
            const commentsClone = Object.assign([], comments);
            commentsClone.push(action.payload);

            return {
                ...state,
                commentsList: commentsClone
            };
        }

        case CommentsActionTypes.EDIT_COMMENT_SUCCESS: {

            const comments = [...state.commentsList];

            const commentIndex = comments.findIndex(x => x.id === action.payload.id);

            if(commentIndex >= 0){
                comments[commentIndex] = action.payload;
            }

            return {
                ...state,
                commentsList: comments
            };
        }

        case CommentsActionTypes.DELETE_COMMENT_SUCCESS: {

            const comments = [...state.commentsList];

            const commentsClone = Object.assign([], comments);

            const commentIndex = comments.findIndex(x => x.id === action.payload);
            if(commentIndex >= 0){
                comments.splice(commentIndex, 1);
            }

            return {
                ...state,
                commentsList: comments
            };
        }

        default: {
            return state;
        }
    }
}
