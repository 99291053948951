import { Project } from '../api/krakenn-api';

export class ProjectType {
  static className = 'Project';
  static gqlRootQueryName = 'project';
  static gqlColumns: string[] = [
      '_id',
      'clientId',
      'proposalId',
      'name',
      'soldAmount',
      'soldDays',
      'jiraProject',
  ];

  static gqlNodeMapper = (node: any): Project => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Project;
    }

}
