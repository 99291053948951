import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommentsState } from './comments.state';

const getCommentsState = createFeatureSelector<CommentsState>(
    'comments'
);

export const getCommentsForTask = createSelector(
    getCommentsState,
    (state: CommentsState) => state.commentsList
);

