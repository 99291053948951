import { Opportunity } from 'src/generated/api/krakenn-api/model/opportunity';

export class DashboardHotOpportunitiesType {
    static className = 'Opportunity';
    static gqlRootQueryName = 'opportunity';
    static gqlColumns: string[] = [
        '_id',
        'name'
    ];

    static gqlNodeMapper = (node: any): Opportunity => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Opportunity;
    }

}
