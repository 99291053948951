import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import { TcGenericListComponent, TcSmartComponent, TcListRowActionButtonsPosition, TcListSortType, TcListFilterType, TcListDisplayColumnType, TcFilterTypes, FilterTypesEnum } from '@tc/core';
import { Lead } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { Store, select } from '@ngrx/store';
import { EditListItem, AddListItem } from 'src/app/modules/core/store/actions/list.actions';
import { Observable, Subscription } from 'rxjs';
import { TcUser, getTcAuthUser } from '@tc/auth';
import { UserRole } from 'src/app/modules/core/user-role.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-leads-list',
  templateUrl: './leads-list.component.html',
  styleUrls: ['./leads-list.component.scss']
})
export class LeadsListComponent extends TcSmartComponent implements OnInit, OnDestroy, AfterViewInit {

  listId = 'leads-list';

  isAdmin = false;
  currentUser$: Observable<TcUser>;
  currentUser: TcUser;
  currentUserSubscription: Subscription;

  constructor(
    private store: Store<any>) {
    super();
  }

  @ViewChild('colStatusTemplate', { static: true }) colStatusTemplate: TemplateRef<any>;
  @ViewChild('colTypeTemplate', { static: true }) colTypeTemplate: TemplateRef<any>;
  @ViewChild('colContactNameTemplate', { static: true }) colContactNameTemplate: TemplateRef<any>;
  @ViewChild('colCollaboratorNameTemplate', { static: true }) colCollaboratorNameTemplate: TemplateRef<any>;
  @ViewChild('colCreatedDateTemplate', { static: true }) colCreatedDateTemplate: TemplateRef<any>;
  @ViewChild('colUpdatedDateTemplate', { static: true }) colUpdatedDateTemplate: TemplateRef<any>;

  @ViewChild('rowActionEditTemplate', { static: true }) rowActionEditTemplate: TemplateRef<any>;


  leadsList: TcGenericListComponent<Lead>;
  @ViewChild('leadsList', { static: true }) set setLeadsList(values: TcGenericListComponent<Lead>) {

    this.leadsList = values;
    this.leadsList.entityName = EntitiesEnum.Leads;
  }

  ngOnDestroy(): void {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.leadsList.service.getAll();
  }

  ngOnInit() {

    this.currentUserSubscription = this.store.pipe(select(getTcAuthUser)).subscribe(user => {
      if (user) {
        this.currentUser = user;
        if ((user as any).roles && (user as any).roles.find(r => r === UserRole.Admin)) {
          this.isAdmin = true;
        }
      }
    });

    this.leadsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.leadsList.showTotalInActionsHeader = true;

    this.leadsList.sortType = TcListSortType.Server;
    this.leadsList.filterType = TcListFilterType.Server;
    this.leadsList.hasFixedHeader = true;

    this.leadsList.hasAddButton = true;
    this.leadsList.addItemWhenKeyPresed = true;

    this.leadsList.hasActionsLabel = false;
    this.leadsList.isPaged = false;

    this.leadsList.onScrollDown = () => {
      console.log('scroll');
      this.leadsList.service.getAll();
    };

    this.leadsList.filters = [
      {
        key: TcFilterTypes.anyFieldContains,
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.anyFieldContains,
        label: `${this.listId}.filter.label`,
        options: {
          minLength: 1,
          debounceTime: 500,
        }
      }
    ];

    this.leadsList.columns = [
      {
        propertyName: 'name',
        visible: true,
        sortExpression : 'name'
      },
      {
        propertyName: 'contact.name',
        visible: true,
        sortExpression: 'contact.lastName'
      },
      {
        propertyName: 'collaborator.name',
        visible: true,
        sortExpression: 'collaborat.lastName'
      },
      {
        propertyName: 'opportunity.name',
        visible: true,
        sortExpression: 'opportunit.name'
      },
      {
        propertyName: 'activityDomain',
        visible: true,
      },
      {
        propertyName: 'group',
        visible: true
      },
      {
        propertyName: 'incorporationNumber',
        visible: true
      },
      {
        propertyName: 'salesRevenu',
        visible: true
      },
      {
        propertyName: 'salesRevenuYear',
        visible: true
      },
      {
        propertyName: 'collaboratorsNumber',
        visible: true
      },
      {
        propertyName: 'address1',
        visible: true
      },
      {
        propertyName: 'address2',
        visible: true
      },
      {
        propertyName: 'postalCode',
        visible: true
      },
      {
        propertyName:'city',
        visible: true
      },
      {
        propertyName: 'country',
        visible: true
      },
      {
        propertyName: 'comments',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Html
      },
      {
        propertyName: 'priority',
        visible: true
      },
      {
        propertyName: 'createdOn',
        visible: true,
        htmlTemplate: this.colCreatedDateTemplate
      },
      {
        propertyName: 'updatedOn',
        visible: true,
        htmlTemplate: this.colUpdatedDateTemplate
      }
    ];

    this.leadsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true,
        htmlTemplate: this.rowActionEditTemplate
      }
    ];

    this.leadsList.onRowAction = (row: Lead, actionName: string) => {
      if (actionName === 'edit') {
        this.editLead(row);
      }
    };


    this.leadsList.onRowClick = (row: Lead) => {
      this.editLead(row);
    };

    this.leadsList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Leads }));
    };
  }

  editLead(row: Lead) {
         this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Leads, data: row }));
    }
}
