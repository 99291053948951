import { TcAction } from '@tc/core';
import { CommercialImputation, Invoice } from 'src/generated/api/krakenn-api';
import { InvoicePayloadModel } from '../../models/invoice-payload.model';
import { InvoiceResultModel } from '../../models/invoice-result.model';

export enum InvoiceActionTypes {

    RESET_SELECTED_COMMERCIAL_IMPUTATIONS = '[Invoice] Reset selected commercial imputations',

    SELECT_COMMERCIAL_IMPUTATION = '[Invoice] Select Commercial Imputation',
    DESELECT_COMMERCIAL_IMPUTATION = '[Invoice] Deselect Commercial Imputation',
    REMOVE_INVALID_COMMERCIAL_IMPUTATION = '[Invoice] Remove Invalid Commercial Imputation',

    GENERATE_INVOICE = '[Invoice] Generate invoice',

    GET_LAST_INVOICE = '[Invoice] Get last invoice',
    GET_LAST_INVOICE_SUCCESS = '[Invoice] Get last invoice success',

    GENERATE_TEMPORARY_INVOICE = '[Invoice] Generate temporary invoice',
    GENERATE_TEMPORARY_INVOICE_SUCCESS = '[Invoice] Generate temporary invoice success',

    GENERATE_FINAL_INVOICE = '[Invoice] Generate final invoice',
    GENERATE_FINAL_INVOICE_SUCCESS = '[Invoice] Generate final invoice success',
}

export class ResetSelectedCommercialImputations implements TcAction {
    readonly type = InvoiceActionTypes.RESET_SELECTED_COMMERCIAL_IMPUTATIONS;
    constructor() { }
}

export class SelectCommercialImputation implements TcAction {
    readonly type = InvoiceActionTypes.SELECT_COMMERCIAL_IMPUTATION;
    constructor(public payload: CommercialImputation) { }
}

export class DeselectCommercialImputation implements TcAction {
    readonly type = InvoiceActionTypes.DESELECT_COMMERCIAL_IMPUTATION;
    constructor(public payload: CommercialImputation) { }
}

export class RemoveInvalidCommercialImputation implements TcAction {
    readonly type = InvoiceActionTypes.REMOVE_INVALID_COMMERCIAL_IMPUTATION;
    constructor(public payload: CommercialImputation) { }
}

export class GenerateInvoice implements TcAction {
    readonly type = InvoiceActionTypes.GENERATE_INVOICE;
    constructor(public payload: CommercialImputation[]) { }
}

export class GetLastInvoice implements TcAction {
    readonly type = InvoiceActionTypes.GET_LAST_INVOICE;
    constructor() { }
}

export class GetLastInvoiceSuccess implements TcAction {
    readonly type = InvoiceActionTypes.GET_LAST_INVOICE_SUCCESS;
    constructor(public payload: Invoice) { }
}

export class GenerateTemporaryInvoice implements TcAction {
    readonly type = InvoiceActionTypes.GENERATE_TEMPORARY_INVOICE;
    constructor(public payload: InvoicePayloadModel) { }
}

export class GenerateTemporaryInvoiceSuccess implements TcAction {
    readonly type = InvoiceActionTypes.GENERATE_TEMPORARY_INVOICE_SUCCESS;
    constructor(public payload: InvoiceResultModel) { }
}

export class GenerateFinalInvoice implements TcAction {
    readonly type = InvoiceActionTypes.GENERATE_FINAL_INVOICE;
    constructor(public payload: {clientInvoiceEmailList: string, clientInvoiceEmailText: string}) { }
}

export class GenerateFinalInvoiceSuccess implements TcAction {
    readonly type = InvoiceActionTypes.GENERATE_FINAL_INVOICE_SUCCESS;
    constructor(public payload: any) { }
}

export type InvoiceActions =
    | ResetSelectedCommercialImputations
    | SelectCommercialImputation
    | DeselectCommercialImputation
    | RemoveInvalidCommercialImputation
    | GenerateInvoice
    | GetLastInvoice
    | GetLastInvoiceSuccess
    | GenerateTemporaryInvoice
    | GenerateTemporaryInvoiceSuccess
    | GenerateFinalInvoice
    | GenerateFinalInvoiceSuccess;
