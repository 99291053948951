import { Role } from '../api/krakenn-api';

export class RoleType {
  static className = 'Role';
  static gqlRootQueryName = 'role';
  static gqlColumns: string[] = [
      '_id',
      'code',
  ];

  static gqlNodeMapper = (node: any): Role => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Role;
    }

}
