import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InvoiceState } from './invoice.state';

const getInvoiceState = createFeatureSelector<InvoiceState>(
    'invoice'
);

export const getSelectedLinesForInvoice = createSelector(
    getInvoiceState,
    (state: InvoiceState) => state.lines
);

export const getInvoiceResultModel = createSelector(
    getInvoiceState,
    (state: InvoiceState) => state.resultModel
);

export const getInvoiceResult = createSelector(
    getInvoiceState,
    (state: InvoiceState) => state.result
);
