import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import {
  TcGenericListComponent, TcListRowActionButtonsPosition, TcListSortType,
  TcListFilterType, TcSmartComponent, TcListDisplayColumnType, FilterTypesEnum, TcFilterTypes, TcTranslateService
} from '@tc/core';
import { Invoice } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { Store } from '@ngrx/store';
import { EditListItem, AddListItem } from 'src/app/modules/core/store/actions/list.actions';
import { InvoiceDbStatus } from 'src/app/modules/core/enums/invoice.db-status.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss']
})
export class InvoicesListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  listId = 'invoices-list';
  constructor(
    private store: Store<any>,
    private translate: TcTranslateService) {
    super();
  }

  @ViewChild('colClientNameTemplate', { static: true }) colClientNameTemplate: TemplateRef<any>;
  @ViewChild('colDateTemplate', { static: true }) colDateTemplate: TemplateRef<any>;
  @ViewChild('colStatusTemplate', { static: true }) colStatusTemplate: TemplateRef<any>;
  @ViewChild('colPayedDateTemplate', { static: true }) colPayedDateTemplate: TemplateRef<any>;
  @ViewChild('invoiceNumberTemplate', { static: true }) invoiceNumberTemplate: TemplateRef<any>;
  @ViewChild('rowActionDownloadTemplate', { static: true }) rowActionDownloadTemplate: TemplateRef<any>;

  invoicesList: TcGenericListComponent<Invoice>;
  @ViewChild('invoicesList', { static: true }) set setInvoicesList(values: TcGenericListComponent<Invoice>) {
    this.invoicesList = values;
    this.invoicesList.entityName = EntitiesEnum.Invoices;
  }

  ngAfterViewInit() {
  }

  ngOnInit() {

    this.invoicesList.hasTotalLine = true;
    this.invoicesList.totalDisplayColumn = 'date';
    this.invoicesList.totalLineColumns = ['amountHT', 'amountTVA', 'amountTTC'];

    this.invoicesList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.invoicesList.showTotalInActionsHeader = true;

    this.invoicesList.sortType = TcListSortType.Server;
    this.invoicesList.filterType = TcListFilterType.Server;
    this.invoicesList.hasFixedHeader = true;

    this.invoicesList.hasAddButton = true;
    this.invoicesList.addItemWhenKeyPresed = true;

    this.invoicesList.hasActionsLabel = false;
    this.invoicesList.isPaged = false;

    this.invoicesList.onScrollDown = () => {
      console.log('scroll');
      this.invoicesList.service.getAll();
    };

    this.invoicesList.filters = [
      {
        key: TcFilterTypes.anyFieldContains,
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.anyFieldContains,
        label: `${this.listId}.filter-label`,
        options: {
          minLength: 1,
          debounceTime: 500,
        }
      },
      {
        key: 'date',
        type: TcFilterTypes.daterange,
        label: `${this.listId}.filter.date`,
        hidden: false,
        options: {
          format: 'DD/MM/YYYY',
          hasReset: true
        }
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.initial`,
          onLabel: ``,
          default: false,
          onValue: [InvoiceDbStatus.Initial]
        }
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.sent`,
          onLabel: ``,
          default: true,
          onValue: [InvoiceDbStatus.Sent]
        }
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.payed`,
          onLabel: ``,
          default: false,
          onValue: [InvoiceDbStatus.Payed]
        }
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.unpaidLost`,
          onLabel: ``,
          default: false,
          onValue: [InvoiceDbStatus.UnpaidLost]
        }
      }
    ];

    this.invoicesList.columns = [
      {
        propertyName: 'number',
        visible: true,
        htmlTemplate: this.invoiceNumberTemplate
      },
      {
        propertyName: 'client.name',
        visible: true,
        htmlTemplate: this.colClientNameTemplate
      },
      {
        propertyName: 'details',
        visible: true,
        // htmlTemplate: this.invoiceDetailsTemplate,
        displayColumnType: TcListDisplayColumnType.Html,
      },
      {
        propertyName: 'date',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date,
      },
      {
        propertyName: 'amountHT',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Currency,
      },
      {
        propertyName: 'amountTVA',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Currency
      },
      {
        propertyName: 'amountTTC',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Currency
      },
      {
        propertyName: 'status',
        visible: true,
        htmlTemplate: this.colStatusTemplate
      },
      {
        propertyName: 'payedDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      }
    ];

    this.invoicesList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true
      },
      {
        actionName: 'download',
        icon: 'get_app',
        visible: true,
        htmlTemplate: this.rowActionDownloadTemplate
      }
    ];

    this.invoicesList.onRowAction = (row: Invoice, actionName: string) => {
      if (actionName === 'edit') {
        this.editInvoice(row);
      }
    };

    this.invoicesList.onRowClick = (row: Invoice) => {
      this.editInvoice(row);
    };

    this.invoicesList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Invoices }));
    };

  }

  private editInvoice(row: any) {
    // Remove details property from custom invoice
    const { details, ...data } = row;
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Invoices, data }));
  }

  getStatusLabel(invoice: Invoice) {
    const result = new SelectValues().invoiceStatus(this.translate).find(p => p.value === invoice.status);
    return result ? result.label : '';
  }

  private downloadInvoice(row: Invoice) {
    window.open(row.url, '_self');
  }

  refresh() {
    this.invoicesList.refresh();
  }
}
