import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getTcAuthUser } from '@tc/auth';
import {
  TcGenericListComponent,
  TcListFilterType,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcSmartComponent,
} from '@tc/core';
import { Subscription } from 'rxjs';
import { CommentsTable } from 'src/app/modules/comments/enums/comments-table.enum';
import { DisplayCommentsList } from 'src/app/modules/comments/store/comments.actions';
import {
  AddListItem,
  EditListItem,
} from 'src/app/modules/core/store/actions/list.actions';
import { UserRole } from 'src/app/modules/core/user-role.enum';
import { Utils } from 'src/app/modules/core/utils';
import { JiraUrlService } from 'src/app/services/jira-url.service';
import { ListRefreshType } from 'src/app/services/list-refresh.service';
import { TargetDateService } from 'src/app/services/target-date.service';
import { Task } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import { DashboardEntitiesEnum } from '../../../dashboard-entities-enum';

@Component({
  selector: 'app-dashboard-unassigned-tasks-list',
  templateUrl: './dashboard-unassigned-tasks-list.component.html',
  styleUrls: ['./dashboard-unassigned-tasks-list.component.scss'],
})
export class DashboardUnassignedTasksListComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  constructor(
    private store: Store<any>,
    private jiraUrl: JiraUrlService,
    private datePipe: DatePipe,
    private readonly targetDateService: TargetDateService
  ) {
    super();
  }

  @ViewChild('colJiraIdTemplate', { static: true })
  colJiraIdTemplate: TemplateRef<any>;
  @ViewChild('colProjectNameTemplate', { static: true })
  colProjectNameTemplate: TemplateRef<any>;
  @ViewChild('colMissionNameTemplate', { static: true })
  colMissionNameTemplate: TemplateRef<any>;
  @ViewChild('colTP', { static: true }) colTP: TemplateRef<any>;
  @ViewChild('colTargetDate', { static: true }) colTargetDate: TemplateRef<any>;
  @ViewChild('advancementPercent', { static: true })
  advancementPercent: TemplateRef<any>;
  @ViewChild('colLastRaf', { static: true }) colLastRaf: TemplateRef<any>;

  @ViewChild('rowActionCommentTemplate', { static: true })
  rowActionCommentTemplate: TemplateRef<any>;

  unassignedTasksList: TcGenericListComponent<Task>;
  @ViewChild('unassignedTasksList', { static: true })
  set setUnassignedTasksList(values: TcGenericListComponent<Task>) {
    this.unassignedTasksList = values;
    this.unassignedTasksList.entityName =
      DashboardEntitiesEnum.DashboardUnassignedTasks;
  }

  isAdmin = false;
  currentUserSubscription: Subscription;

  ngOnInit() {
    this.currentUserSubscription = this.store
      .pipe(select(getTcAuthUser))
      .subscribe((user) => {
        if (user) {
          if (
            (user as any).roles &&
            (user as any).roles.find((r) => r === UserRole.Admin)
          ) {
            this.isAdmin = true;
          }
        }
      });

    this.unassignedTasksList.rowActionButtonsPosition =
      TcListRowActionButtonsPosition.AfterData;
    this.unassignedTasksList.showTotalInActionsHeader = false;

    this.unassignedTasksList.sortType = TcListSortType.Server;
    this.unassignedTasksList.sortDirection = 'desc';
    this.unassignedTasksList.sortActive = 'priority';
    this.unassignedTasksList.filterType = TcListFilterType.Disabled;
    this.unassignedTasksList.hasFixedHeader = true;

    this.unassignedTasksList.hasAddButton = false;
    this.unassignedTasksList.addItemWhenKeyPresed = false;

    this.unassignedTasksList.hasActionsLabel = false;
    this.unassignedTasksList.isPaged = false;

    this.unassignedTasksList.onScrollDown = () => {
      this.unassignedTasksList.service.getAll();
    };

    this.unassignedTasksList.columns = [
      {
        propertyName: 'id',
        visible: true,
      },
      {
        propertyName: 'jiraId',
        visible: true,
        htmlTemplate: this.colJiraIdTemplate,
      },
      {
        propertyName: 'project.name',
        visible: true,
        htmlTemplate: this.colProjectNameTemplate,
      },
      {
        propertyName: 'mission.name',
        visible: true,
        htmlTemplate: this.colMissionNameTemplate,
      },
      {
        propertyName: 'name',
        visible: true,
      },
      {
        propertyName: 'targetPoints',
        visible: true,
        htmlTemplate: this.colTP,
      },
      {
        propertyName: 'targetDate',
        visible: true,
        // displayColumnType: TcListDisplayColumnType.Date,
        htmlTemplate: this.colTargetDate,
      },
      {
        propertyName: 'globalPriority',
        visible: true,
      },
    ];

    this.unassignedTasksList.rowActions = [
      {
        actionName: 'comment',
        visible: true,
        icon: 'comment',
        htmlTemplate: this.rowActionCommentTemplate,
      },
    ];

    this.unassignedTasksList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Tasks }));
    };

    this.unassignedTasksList.onRowClick = (row: Task) => {
      this.store.dispatch(
        new EditListItem({
          entityName: EntitiesEnum.Tasks,
          data: row,
          args: {
            readOnly: !this.isAdmin,
            refreshListAfterClose: this.isAdmin
              ? ListRefreshType.DashboardUnassignedTasks
              : null,
          },
        })
      );
    };
  }

  ngOnDestroy(): void {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  getTpInHours = (row: Task) => {
    return Utils.convertDaysToHours(row.targetPoints ?? 0);
  };

  getJiraUrl(row: Task) {
    return this.jiraUrl.getJiraUrl(row.jiraId);
  }

  isTargetDateRed = (row: Task) => {
    return this.targetDateService.isInTheWeek(
      this.datePipe.transform(row.targetDate, 'yyyy-MM-dd')
    );
  };

  getTargetDate = (row) => {
    return this.datePipe.transform(row.targetDate, 'dd/MM/yyyy');
  };

  showComments(row: Task) {
    this.store.dispatch(
      new DisplayCommentsList({
        table: CommentsTable.Task,
        id: row.id,
        args: { title: 'comments-list.tasks.title' },
      })
    );
  }
}
