import { Proposal } from '../api/krakenn-api';

export class ProposalType {
  static className = 'Proposal';
  static gqlRootQueryName = 'proposal';
  static gqlColumns: string[] = [
      '_id',
      'estimationId',
      'name',
      'sentDate',
      'status',
  ];

  static gqlNodeMapper = (node: any): Proposal => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Proposal;
    }

}
