import { Component, ElementRef, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { Store } from '@ngrx/store';
import { Contact, Lead } from 'src/generated/api/krakenn-api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import { AddActivity, EditActivity } from '../../../store/activitys.actions';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { QlAutcompleteService } from '../../../../../services/core/ql-autocomplete.service';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { EditContactPopupComponent } from '../../../../contacts/components/smart/edit-contact-popup/edit-contact-popup.component';

@Component({
  selector: 'app-edit-activity-popup',
  templateUrl: './edit-activity-popup.component.html',
  styleUrls: ['./edit-activity-popup.component.scss']
})
export class EditActivityPopupComponent extends TcFormComponent<any> implements OnInit {
  @ViewChild('contactButtonPanel', { static: true }) contactButtonPanel: TemplateRef<any>;

  title = '';
  disableLead = true;
  dateFormat = 'YYYY-MM-DD HH:mm:ss';

  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    private dialogRef: MatDialogRef<EditActivityPopupComponent>,
    private dialog: MatDialog,
    private notificationService: TcNotificationService,
    translate: TcTranslateService,
    private store: Store<any>,
    elem: ElementRef,
    private dateAdapter: DateAdapter<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(translate, elem);
    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'type',
            type: 'select',
            templateOptions: {
              required: true,
              options: new SelectValues().activityType(this.translate)
            }
          },
          {
            key: 'contactId',
            type: 'contact-editor-field',
            templateOptions: {
              required: false,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Contact>(EntitiesEnum.Contacts, term, 'id', 'firstName,lastName'),
              listValue: 'contact.name',
              addNewContact: ($event) => {this.addNewContact($event)},
              viewContact: ($event) => { this.viewContact($event)}
            },
            validators: {
              contactId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                  message: (error, field: FormlyFieldConfig) => `${field.formControl.value} ${this.translate.instant('is-not-valid')} ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: "targetDate",
            type: "datepicker",
            templateOptions: {
              type: "text",
              datepickerPopup: "dd-MMMM-yyyy",
              datepickerOptions: {
                format: "dd-MM-yyyy hh:mm:ss",
              },
            },
          },
          {
            key: "done",
            type: "checkbox",
            templateOptions: {
              type: "boolean",
            },
          },
          {
            key: 'leadId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              disabled: this.disableLead,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Lead>(EntitiesEnum.Leads, term, 'id', 'name'),
              listValue: 'lead.name'
            },
            validators: {
              leadId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} ${this.translate.instant('is-not-valid')} ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: "createdOn",
            type: "datepicker",
            templateOptions: {
              disabled: true,
              type: "text",
              readonly: true,
              datepickerPopup: "dd-MMMM-yyyy",
              datepickerOptions: {
                format: "dd-MM-yyyy hh:mm:ss",
              },
            },
          },
          {
            key: "updatedOn",
            type: "datepicker",
            templateOptions: {
              disabled: true,
              type: "text",
              readonly: true,
              datepickerPopup: "dd-MMMM-yyyy",
              datepickerOptions: {
                format: "dd-MM-yyyy hh:mm:ss",
              },
            },
          },
        ]
      },
      {
        fieldGroupClassName: "display-row",
        fieldGroup: [
          {
            key: "comments",
            type: "textarea-editor",
            className: "textarea-form-content textarea-form-label",
            templateOptions: {
              required: true
            },
          },
        ],
      }
    ];
  }

  onClose() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.model = Object.assign({}, this.data);

    if (!this.model.leadId) {
      this.disableLead = false;
    }

    if (!this.model.lead){
      const lead = {
          id: this.model.leadId,
          name: this.model.lead__name
        };
      this.model.lead = lead;
    }

    if(!this.model.contact){
      const contactName = this.model.contact__firstName ? this.model.contact__firstName + ' ' + this.model.contact__lastName : '';

      if (this.model.contact__firstName  !== null && this.model.contact__firstName  !== undefined) {
        const contact = {
          id: this.model.contactId,
          firstName: this.model.contact__firstName,
          lastName: this.model.contact__lastName,
          name: contactName
        };
        this.model.contact = contact;
      }
    }

    if (this.data.createdOn) {
      this.model.createdOn = moment(new Date(this.data.createdOn)).format('x');
    }
    if (this.data.targetDate) {
      this.model.targetDate = moment(new Date(this.data.targetDate)).format('x');
    } else {
      const curDate = new Date();
      const targetDate = new Date(curDate.getTime());
      targetDate.setDate(targetDate.getDate() + 7)

      this.model.targetDate = moment(targetDate).format('x');
    }
    if (this.data.updatedOn) {
      this.model.updatedOn = moment(new Date(this.data.updatedOn)).format('x');
    }

    if (!this.data?.id) {
      this.model.done = false;
    }

    super.ngOnInit();
  }

  submit() {
    if (this.form.valid) {
      if (!this.data?.id) {
        this.model.createdOn = this.dateAdapter.deserialize(new Date()).format(this.dateFormat);
        this.model.updatedOn = this.dateAdapter.deserialize(new Date()).format(this.dateFormat);
        this.model.targetDate = this.dateAdapter.deserialize(this.model.targetDate).format(this.dateFormat);
        const { leadId, contactId, collaboratorId, type, done, comments, targetDate, createdOn,updatedOn } = this.model;

        const savedActivity: any = {
            leadId,
            contactId,
            collaboratorId,
            type,
            done,
            comments,
            targetDate,
            createdOn,
            updatedOn
          };

        this.store.dispatch(new AddActivity(savedActivity));
      } else {
        this.model.createdOn = this.model.createdOn !== null && this.model.createdOn !== undefined ?
                                  this.dateAdapter.deserialize(this.model.createdOn).format(this.dateFormat):
                                  this.dateAdapter.deserialize(new Date()).format(this.dateFormat);
        this.model.updatedOn = this.dateAdapter.deserialize(new Date()).format(this.dateFormat);
        this.model.targetDate = this.model.targetDate !== null && this.model.targetDate !== undefined ?
                                this.dateAdapter.deserialize(this.model.targetDate).format(this.dateFormat) :
                                this.dateAdapter.deserialize(new Date()).format(this.dateFormat);
        const { id, leadId, contactId, type, done, comments, targetDate, createdOn,updatedOn } = this.model;

        const savedActivity: any = {
            id,
            leadId,
            contactId,
            type,
            done,
            comments,
            targetDate,
            createdOn,
            updatedOn
          };

        this.store.dispatch(new EditActivity(savedActivity));
      }
      this.dialogRef.close(true);
    }
  }

  addNewContact($event) {
    $event.preventDefault();
    const data = {};

    this.dialog.open(EditContactPopupComponent, {
      data,
      width: '54.5vw'
    }).afterClosed().subscribe((contact) => {
      if (contact){
        this.model.contact = contact;
        this.model.contact.name = contact.firstName + ' ' + contact.lastName;
        this.model.contactId = contact.id;
        this.form.controls.contactId.setValue(contact.id,contact);
      }
    });

  }

  viewContact($event){
    const contactId = this.model?.contactId;

    $event.preventDefault();

    if (contactId){
      const data = {
        id: contactId
      };

      this.dialog.open(EditContactPopupComponent, {
        data,
        width: '54.5vw'
      }).afterClosed().subscribe((contact) => {
        if (contact) {
          this.model.contact = contact;
          this.model.contact.name = contact.firstName + ' ' + contact.lastName;
        }
      },
      err => {
        console.log(err);
      });
    } else {
      this.notificationService.warning(this.translate.instant('select-contact-first'));
    }
  }
}
