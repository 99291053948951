import { Lead } from '../api/krakenn-api';

export class LeadType {
  static className = 'Lead';
  static gqlRootQueryName = 'lead';
  static gqlColumns: string[] = [
      '_id',
      'opportunityId',
      'contactId',
      'collaboratorId',
      'name',
      'activityDomain',
      'group',
      'incorporationNumber',
      'salesRevenu',
      'salesRevenuYear',
      'collaboratorsNumber',
      'address1',
      'address2',
      'postalCode',
      'city',
      'country',
      'comments',
      'priority',
      'createdOn',
      'updatedOn',
  ];

  static gqlNodeMapper = (node: any): Lead => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Lead;
    }

}
