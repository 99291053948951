import { Mission } from './../../../../generated/api/krakenn-api/model/mission';
import { Task } from './../../../../generated/api/krakenn-api/model/task';
export class MyTask implements Task {
  id: number;
  missionId: number;
  jiraId?: string;
  type?: string;
  name: string;
  targetPoints?: number;
  targetDate?: string;
  priority: number;
  collaboratorId: number;
  sumTimeImputation: number;
  lastRaf: number;
  mission: Mission;
}
