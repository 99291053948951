import { getCollaboratorsWorkload } from './../../../store/collaborators.selectors';
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';
import { CollaboratorWorkload } from './../../../collaborators.interfaces';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-collaborators-workload',
  templateUrl: './collaborators-workload.component.html',
  styleUrls: ['./collaborators-workload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CollaboratorsWorkloadComponent extends TcSmartComponent implements OnInit {
  @Output()
  refreshWorkload: EventEmitter<any> = new EventEmitter();

  MAXIMUM_COLLABORATORS_PER_COL = 4;

  public collaboratorsWorkload$: Observable<CollaboratorWorkload[]>;
  public workloadPerCollaborator: any[] = [];

  constructor(private store: Store<any>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.collaboratorsWorkload$ = this.store.pipe(select(getCollaboratorsWorkload()));

    this.collaboratorsWorkload$.subscribe((collaboratorsWorkload) => {
      this.workloadPerCollaborator = [];

      const noOfCollaborators = collaboratorsWorkload.length;
      const noOfCollabPerRow = this.MAXIMUM_COLLABORATORS_PER_COL;

      let col = 0;
      let row = [];
      let counter = 0;

      collaboratorsWorkload.forEach(collabWorkload => {
        if (collabWorkload.collaborator) {
          row.push(collabWorkload);
          counter++;
        }

        if (col < noOfCollabPerRow - 1 && counter !== noOfCollaborators) {
          col++;
        } else {
          this.workloadPerCollaborator.push(row);

          col = 0;
          row = [];
        }
      });
    });
  }

  refreshList() {
    this.refreshWorkload.emit();
  }
}
