import { Inject, Injectable, Optional } from "@angular/core";
import { DateAdapter } from '@angular/material/core';
import { TcNotificationService } from "@tc/core";
import { EntityCollectionServiceFactory } from "ngrx-data";
import {
  BASE_PATH,
  CommercialImputation,
  Configuration,
  Mission
} from "src/generated/api/krakenn-api";
import { CommercialImputationDbStatus } from "../modules/core/enums/commercial-imputation-db-status.enum";
import { EntitiesEnum } from "../modules/core/enums/entities-enum";
import { ListRefreshService, ListRefreshType } from './list-refresh.service';

@Injectable({
  providedIn: "root",
})
export class MissionsService {
  public configuration = new Configuration();
  protected basePath =
    "https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0";

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
    private notificationService: TcNotificationService,
    private readonly listRefreshService: ListRefreshService,
    private dateAdapter: DateAdapter<any>,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  addMissionCommercialImputation(data: Mission, args?: any) {
    let targetDate  = data.targetDate ? data.targetDate : null;

    if (!targetDate) {
      const date = new Date();
      targetDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime().toString();
    }

    const commercialImputation: CommercialImputation = {
      id: 0,
      date: this.dateAdapter.deserialize(targetDate).format('YYYY-MM-DD'),
      missionId: data.id,
      workDoneDescription: Object.assign({}, data.name),
      quantity: data.quantity ? data.quantity : 0,
      priceHT: data.priceHTPerUnit,
      status: CommercialImputationDbStatus.Manual,
    };

    const service = this.entityCollectionServiceFactory.create(
      EntitiesEnum.CommercialImputations
    );
    service.add(commercialImputation).subscribe(
      (result) => {
        this.notificationService.success(
          "Commercial imputation successfully saved!"
        );
        this.listRefreshService.emitRefresh(ListRefreshType.Missions);
      },
      (error) => {
        this.notificationService.error(error);
      }
    );
  }
}
