import { Component, ElementRef, OnInit } from '@angular/core';
import { TcGenericFormComponent, TcTranslateService } from '@tc/core';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { EstimationLine, Estimation } from 'src/generated/api/krakenn-api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-estimation-line-detail',
  templateUrl: './estimation-line-detail.component.html',
  styleUrls: ['./estimation-line-detail.component.scss']
})
export class EstimationLineDetailComponent extends TcGenericFormComponent<EstimationLine> implements OnInit {

  estimationId: number;

  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {

    super(EntitiesEnum.EstimationLines, entityCollectionServiceFactory, translate, elem, dateAdapter);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'estimationId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              // disabled: this.model.estimationId && this.model.estimationId > 0
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Estimation>(EntitiesEnum.Estimations, term, 'id', 'name'),
              listValue: 'estimation.name'
            },
            validators: {
              estimationId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            }
          },
          {
            key: 'category',
            type: 'input',
            templateOptions: {
              type: 'text'
            }
          },
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true
            }
          },
          {
            key: 'workloadProduction',
            type: 'input',
            templateOptions: {
              type: 'number'
            }
          },
          {
            key: 'workloadSales',
            type: 'input',
            templateOptions: {
              type: 'number'
            }
          },
          {
            key: 'riskLevel',
            type: 'select',
            templateOptions: {
              required: true,
              options: new SelectValues().estimationLineRiskLevel(this.translate)
            }
          },
          {
            key: 'status',
            type: 'select',
            templateOptions: {
              options: new SelectValues().estimationLineStatus(this.translate)
            }
          }
        ]
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    const estimation = (this as any).estimation;

    if (estimation) {
      this.estimationId = estimation;
      this.model.estimationId = this.estimationId;
    }
  }
}
