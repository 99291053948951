import { TcAction } from "@tc/auth";
import { TcGenericEntity } from "@tc/core";
import { EntitiesEnum } from "../../core/enums/entities-enum";

export enum BypassLoginActionTypes {
    BYPASS_LOGIN = '[Login] Bypass Login',
}

export class BypassLogin implements TcAction {
    readonly type = BypassLoginActionTypes.BYPASS_LOGIN;

    constructor(
        public payload: {
            email
        }
    ) { }
}