import { Estimation } from '../api/krakenn-api';

export class EstimationType {
  static className = 'Estimation';
  static gqlRootQueryName = 'estimation';
  static gqlColumns: string[] = [
      '_id',
      'clientId',
      'opportunityId',
      'name',
      'creationDate',
      'targetDate',
      'targetMargin',
  ];

  static gqlNodeMapper = (node: any): Estimation => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Estimation;
    }

}
