import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import {
  TcGenericListComponent,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcListFilterType,
  TcSmartComponent,
  TcListDisplayColumnType,
  FilterTypesEnum,
  TcFilterTypes,
} from '@tc/core';
import {
  Mission,
  TimeImputation,
  Collaborator,
} from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { Store } from '@ngrx/store';
import {
  AddListItem,
  EditListItem,
} from 'src/app/modules/core/store/actions/list.actions';
import * as moment from 'moment';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';

@Component({
  selector: 'app-time-imputations-list',
  templateUrl: './time-imputations-list.component.html',
  styleUrls: ['./time-imputations-list.component.scss'],
})
export class TimeImputationsListComponent
  extends TcSmartComponent
  implements OnInit, AfterViewInit
{
  listId = 'time-imputations-list';

  constructor(
    private store: Store<any>,
    private qlAutocompleteService: QlAutcompleteService
  ) {
    super();
  }

  @ViewChild('missionNameTemplate', { static: true })
  missionNameTemplate: TemplateRef<any>;
  @ViewChild('projectNameTemplate', { static: true })
  projectNameTemplate: TemplateRef<any>;
  @ViewChild('clientNameTemplate', { static: true })
  clientNameTemplate: TemplateRef<any>;
  @ViewChild('workDoneTemplate', { static: true })
  workDoneTemplate: TemplateRef<any>;

  timeImputationsList: TcGenericListComponent<TimeImputation>;
  @ViewChild('timeImputationsList', { static: true })
  set setTimeImputationsList(values: TcGenericListComponent<TimeImputation>) {
    this.timeImputationsList = values;
    this.timeImputationsList.entityName = EntitiesEnum.TimeImputations;
  }

  ngAfterViewInit() {}

  ngOnInit() {
    this.timeImputationsList.hasTotalLine = true;
    this.timeImputationsList.totalDisplayColumn = 'workDone';
    this.timeImputationsList.totalLineColumns = [
      'daysConsumed',
      'daysEstimatedToFinish',
    ];

    this.timeImputationsList.rowActionButtonsPosition =
      TcListRowActionButtonsPosition.BeforeData;

    this.timeImputationsList.showTotalInActionsHeader = true;

    this.timeImputationsList.hasActionsLabel = false;
    this.timeImputationsList.isPaged = false;

    this.timeImputationsList.sortType = TcListSortType.Server;
    this.timeImputationsList.filterType = TcListFilterType.Server;

    this.timeImputationsList.hasAddButton = true;
    this.timeImputationsList.addItemWhenKeyPresed = true;

    this.timeImputationsList.hasFixedHeader = true;

    this.timeImputationsList.onScrollDown = () => {
      this.timeImputationsList.service.getAll();
    };

    this.timeImputationsList.filters = [
      {
        key: TcFilterTypes.anyFieldContains,
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.anyFieldContains,
        label: `${this.listId}.filter-label`,
        options: {
          minLength: 1,
          debounceTime: 500,
        },
      },
      {
        key: 'collaboratorId',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.multiselect,
        options: {
          filter: (term) =>
            this.qlAutocompleteService.getAutocompleteValues<Collaborator>(
              EntitiesEnum.Collaborators,
              term,
              'id',
              'trigram',
              null,
              false,
              [{ property: 'expirationDate', filterType: 'IsNull', value: '1' }]
            ),
          label: `${this.listId}.filter.multiselect.collaboratorsTrigram`,
          removable: true,
        },
      },
      {
        key: 'date',
        type: TcFilterTypes.daterange,
        label: `${this.listId}.filter.date`,
        hidden: false,
        options: {
          format: 'DD/MM/YYYY',
          default: {
            start: moment(new Date(), 'DD/MM/YYYY')
              .subtract(1, 'months')
              .startOf('month'),
            end: moment(new Date(), 'DD/MM/YYYY'),
          },
          hasReset: true,
        },
      },
    ];

    this.timeImputationsList.columns = [
      {
        propertyName: 'collaborator.name',
        visible: true,
        sortExpression: 'collaborat.lastName',
      },
      {
        propertyName: 'client.name',
        visible: true,
        htmlTemplate: this.clientNameTemplate,
      },
      {
        propertyName: 'project.name',
        visible: true,
        htmlTemplate: this.projectNameTemplate,
      },
      {
        propertyName: 'mission.name',
        visible: true,
        htmlTemplate: this.missionNameTemplate,
      },
      {
        propertyName: 'task.name',
        visible: true,
      },
      {
        propertyName: 'date',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date,
      },
      {
        propertyName: 'workDone',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Html,
      },
      {
        propertyName: 'daysConsumed',
        visible: true,
      },
      {
        propertyName: 'daysEstimatedToFinish',
        visible: true,
      },
    ];

    this.timeImputationsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true,
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true,
      },
    ];

    this.timeImputationsList.onRowAction = (
      row: TimeImputation,
      actionName: string
    ) => {
      if (actionName === 'edit') {
        this.editTimeImputation(row);
      }
    };

    this.timeImputationsList.onRowClick = (row: TimeImputation) => {
      this.editTimeImputation(row);
    };

    this.timeImputationsList.addItem = () => {
      this.store.dispatch(
        new AddListItem({ entityName: EntitiesEnum.TimeImputations })
      );
    };
  }

  addNew() {
    this.store.dispatch(
      new AddListItem({ entityName: EntitiesEnum.TimeImputations })
    );
  }

  private editTimeImputation(row: TimeImputation) {
    this.store.dispatch(
      new EditListItem({ entityName: EntitiesEnum.TimeImputations, data: row })
    );
  }
}
