import { Component, OnInit } from '@angular/core';
import { TcPage, IPageState } from '@tc/core';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import { ProposalDbStatus } from 'src/app/modules/core/enums/proposal-db-status.enum';
import { ToggleFilterModel } from 'src/app/modules/core/models/toggle-filter.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-proposals-page',
  templateUrl: './proposals-page.component.html',
  styleUrls: ['./proposals-page.component.scss']
})
export class ProposalsPageComponent extends TcPage implements OnInit {

  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'proposals-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }

}
