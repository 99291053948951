import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionsListComponent } from "./components/smart/missions-list/missions-list.component";
import { MissionDetailComponent } from "./components/smart/mission-detail/mission-detail.component";
import { TcCoreModule } from "@tc/core";
import { CoreModule } from "../core/core.module";
import { EffectsModule } from "@ngrx/effects";
import { MissionsEffects } from "./store/missions.effects";

const smartComponents = [MissionsListComponent, MissionDetailComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule,
    EffectsModule.forFeature([MissionsEffects]),
  ],
  entryComponents: [MissionDetailComponent],
  exports: [...smartComponents],
})
export class MissionsModule {}
