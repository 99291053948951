import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { EstimationLine } from 'src/generated/api/krakenn-api';
import { TcGenericListComponent, TcListRowActionButtonsPosition, TcSmartComponent, TcListFilterType, TcListSortType, TcListFilterModel, TcTranslateService } from '@tc/core';
import { Store } from '@ngrx/store';
import { EditListItem, AddListItem } from 'src/app/modules/core/store/actions/list.actions';
import { EntityCollectionServiceFactory } from 'ngrx-data';

@Component({
  selector: 'app-estimation-lines-list',
  templateUrl: './estimation-lines-list.component.html',
  styleUrls: ['./estimation-lines-list.component.scss']
})
export class EstimationLinesListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  estimationId: number;
  @Input() estimation: number;
  @Input() estimationDetail: boolean;

  constructor(
    private store: Store<any>,
    private translate: TcTranslateService) {
      super();
      this.estimationDetail = false;
  }

  estimationLinesList: TcGenericListComponent<EstimationLine>;
  @ViewChild('estimationLinesList', { static: true }) set setEstimationLinesList(values: TcGenericListComponent<EstimationLine>) {
    this.estimationLinesList = values;
    this.estimationLinesList.entityName = EntitiesEnum.EstimationLines;
  }

  @ViewChild('colRiskLevelTemplate', { static: true }) colRiskLevelTemplate: TemplateRef<any>;
  @ViewChild('colStatusTemplate', { static: true }) colStatusTemplate: TemplateRef<any>;

  ngAfterViewInit() {
    // this.estimationLinesList.service.clearCache();
    // this.estimationLinesList.service.getWithQuery({ initial: 'true' });

    if (this.estimation) {
      this.estimationId = this.estimation;
      this.loadEstimationLines(this.estimationId);
      this.estimationDetail = true;
    } else {
      this.estimationDetail = false;
      this.loadEstimationLines(this.estimationId);
    }
  }

  ngOnInit() {
    if (this.estimation) {
      this.estimationId = this.estimation;
      this.estimationDetail = true;
    } else {
      this.estimationDetail = false;
    }

    // this.activatedRoute.queryParams.subscribe(s => {
    //   if (s.estimationId) {
    //     this.estimationLinesList.queryParams = {
    //       estimationId: s.estimationId
    //     };
    //     this.estimationId = Number(s.estimationId);
    //   } else {
    //     if (this.estimationLinesList.service) {
    //       this.estimationLinesList.service.getAll();
    //     }
    //   }

    // });

    this.estimationLinesList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.estimationLinesList.showTotalInActionsHeader = true;

    this.estimationLinesList.sortType = TcListSortType.Server;
    this.estimationLinesList.filterType = this.isEstimationDetail()?  TcListFilterType.Disabled : TcListFilterType.Server;
    this.estimationLinesList.hasFixedHeader = true;

    this.estimationLinesList.hasAddButton = !this.isEstimationDetail();
    this.estimationLinesList.addItemWhenKeyPresed = true;

    this.estimationLinesList.hasActionsLabel = false;
    this.estimationLinesList.isPaged = false;

    this.estimationLinesList.onScrollDown = () => {
      this.loadEstimationLines(this.estimationId);
    };

    this.estimationLinesList.columns = [
      {
        propertyName: 'estimation.name',
        visible: true
      },
      {
        propertyName: 'category',
        visible: true
      },
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'workloadProduction',
        visible: true
      },
      {
        propertyName: 'workloadSales',
        visible: true
      },
      {
        propertyName: 'riskLevel',
        visible: true,
        htmlTemplate: this.colRiskLevelTemplate
      },
      {
        propertyName: 'status',
        visible: true,
        htmlTemplate: this.colStatusTemplate
      }
    ];

    this.estimationLinesList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true
      }
    ];

    this.estimationLinesList.onRowAction = (row: EstimationLine, actionName: string) => {
      if (actionName === 'edit') {
        this.editEstimationLine(row);
      }
    };

    this.estimationLinesList.onRowClick = (row: EstimationLine) => {
      this.editEstimationLine(row);
    };

    this.estimationLinesList.addItem = () => {
      this.addItem();
    };
  }

  loadEstimationLines(estimationId?: number) {
    if (!estimationId) {
      this.estimationLinesList.service.getAll();
    } else {
      const filter = {
        estimationId: {
          filterType: "Equal",
          value: estimationId + "",
        },
      } as TcListFilterModel;

      const service = this.estimationLinesList.service;
      const query = {
        filter: JSON.stringify(filter),
      } as any;

      service.clearCache();

      service.getWithQuery(query);
    }
  }

  private addItem = () => {

    if (this.estimationIdIsDefined()) {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.EstimationLines,  args: { estimationId: this.estimationId } }));
    } else {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.EstimationLines }));
    }
  };

  private editEstimationLine(row: EstimationLine) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.EstimationLines, data: row }));
  }

  getRiskLevelLabel(estimationLine: EstimationLine) {
    const result = new SelectValues().estimationLineRiskLevel(this.translate).find(p => p.value === estimationLine.riskLevel);
    return result ? result.label : '';
  }

  getStatusLabel(estimationLine: EstimationLine) {
    const result = new SelectValues().estimationLineStatus(this.translate).find(p => p.value === estimationLine.status);
    return result ? result.label : '';
  }

  private estimationIdIsDefined(): boolean {
    return this.estimationId && this.estimationId > 0;
  }

  private isEstimationDetail = (): boolean => {
    return this.estimationDetail;
  }
}
