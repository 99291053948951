import { Activity } from '../api/krakenn-api';

export class ActivityType {
  static className = 'Activity';
  static gqlRootQueryName = 'activity';
  static gqlColumns: string[] = [
      '_id',
      'leadId',
      'contactId',
      'collaboratorId',
      'type',
      'done',
      'comments',
      'targetDate',
      'createdOn',
      'updatedOn',
  ];

  static gqlNodeMapper = (node: any): Activity => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Activity;
    }

}
