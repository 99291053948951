import { Component, OnInit } from '@angular/core';
import { TcPage, IPageState } from '@tc/core';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import { ToggleFilterModel } from 'src/app/modules/core/models/toggle-filter.model';
import { OpportunityDbStatus } from 'src/app/modules/core/enums/opportunity-db-status.enum';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-opportunities-page',
  templateUrl: './opportunities-page.component.html',
  styleUrls: ['./opportunities-page.component.scss']
})
export class OpportunitiesPageComponent extends TcPage implements OnInit {

  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'opportunities-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
