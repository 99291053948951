import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, AfterViewInit } from '@angular/core';
import { TcGenericListComponent, TcSmartComponent, TcListRowActionButtonsPosition, TcListSortType,
   TcListFilterType, TcListDisplayColumnType, TcCurrencyFormat, TcListDefaultAction, TcFilterTypes, FilterTypesEnum, TcTranslateService } from '@tc/core';
import { CommercialImputation } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { Store, select } from '@ngrx/store';
import { EditListItem, AddListItem, DuplicateListItem } from 'src/app/modules/core/store/actions/list.actions';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { Subscription } from 'rxjs';
import { SelectCommercialImputation, DeselectCommercialImputation, GenerateInvoice, ResetSelectedCommercialImputations } from '../../../store/actions/invoice.actions';
import { getInvoiceResult } from '../../../store/invoice.selectors';
import { CommercialImputationDbStatus } from 'src/app/modules/core/enums/commercial-imputation-db-status.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-commercial-imputations-list',
  templateUrl: './commercial-imputations-list.component.html',
  styleUrls: ['./commercial-imputations-list.component.scss']
})
export class CommercialImputationsListComponent extends TcSmartComponent implements OnInit, OnDestroy, AfterViewInit {

  listId = 'commercial-imputations-list';

  private selectionSubscription: Subscription;
  private reloadPageSubscription: Subscription;

  constructor(private store: Store<any>,
    private tcCurrencyFormat: TcCurrencyFormat,
    private translate: TcTranslateService) {
    super();
  }

  commercialImputationsList: TcGenericListComponent<CommercialImputation>;
  @ViewChild('commercialImputationsList', { static: true }) set setCommercialImputationsList(values: TcGenericListComponent<CommercialImputation>) {
    this.commercialImputationsList = values;
    this.commercialImputationsList.entityName = EntitiesEnum.CommercialImputations;
  }

  @ViewChild('colClientNameTemplate', { static: true }) colClientNameTemplate: TemplateRef<any>;
  @ViewChild('colProjectNameTemplate', { static: true }) colProjectNameTemplate: TemplateRef<any>;

  @ViewChild('colStatusTemplate', { static: true }) colStatusTemplate: TemplateRef<any>;
  @ViewChild('colTotalTemplate', { static: true }) colTotalTemplate: TemplateRef<any>;
  @ViewChild('invoiceNumberTemplate', { static: true }) invoiceNumberTemplate: TemplateRef<any>;

  @ViewChild('workDoneTemplate', { static: true }) workDoneTemplate: TemplateRef<any>;

  ngAfterViewInit() {

  }

  ngOnInit() {

    this.store.dispatch(new ResetSelectedCommercialImputations());

    this.commercialImputationsList.hasTotalLine = true;
    this.commercialImputationsList.totalLineColumns = ['quantity', 'priceHT', 'total'];
    this.commercialImputationsList.totalDisplayColumn = 'workDoneDescription';
    this.commercialImputationsList.totalCustomFunctions = {
      total: (row: CommercialImputation) => {
        return (row.quantity ? row.quantity : 0) * (row.priceHT ? row.priceHT : 0);
      }
    };

    this.commercialImputationsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;

    this.commercialImputationsList.showTotalInActionsHeader = true;

    this.commercialImputationsList.hasActionsLabel = false;
    this.commercialImputationsList.isPaged = false;

    this.commercialImputationsList.sortType = TcListSortType.Server;
    this.commercialImputationsList.filterType = TcListFilterType.Server;

    this.commercialImputationsList.hasAddButton = true;
    this.commercialImputationsList.addItemWhenKeyPresed = true;

    this.commercialImputationsList.hasFixedHeader = true;

    this.commercialImputationsList.onScrollDown = () => {
      this.commercialImputationsList.service.getAll();
    };

    this.commercialImputationsList.filters = [
      {
        key: TcFilterTypes.anyFieldContains,
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.anyFieldContains,
        label: `${this.listId}.filter-label`,
        options: {
          minLength: 1,
          debounceTime: 500,
        }
      },
      {
        key: 'date',
        type: TcFilterTypes.daterange,
        label: `${this.listId}.filter.date`,
        hidden: false,
        options: {
          format: 'DD/MM/YYYY',
          default: {
            start: moment(new Date(), 'DD/MM/YYYY').subtract(1, "months").startOf('month'),
            end: moment(new Date(), 'DD/MM/YYYY'),
          },
          hasReset: true
        }
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.all`,
          onLabel: `${this.listId}.filter.toggles.active`,
          default: true,
          onValue: [CommercialImputationDbStatus.GeneratedNotYetValidated]
        }
      },
      {
        key: 'invoiceId',
        filterType: FilterTypesEnum.IsNull,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.all`,
          onLabel: `${this.listId}.filter.toggles.notyetinvoiced`,
          default: false,
          onValue: []
        }
      },
    ];

    this.commercialImputationsList.columns = [
      {
        propertyName: 'client.name',
        visible: true,
        htmlTemplate: this.colClientNameTemplate
      },
      {
        propertyName: 'project.name',
        visible: true,
        htmlTemplate: this.colProjectNameTemplate
      },
      {
        propertyName: 'mission.name',
        visible: true
      },
      {
        propertyName: 'invoice.number',
        visible: true,
        htmlTemplate: this.invoiceNumberTemplate
      },
      {
        propertyName: 'date',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'workDoneDescription',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Html
      },
      {
        propertyName: 'quantity',
        visible: true
      },
      {
        propertyName: 'priceHT',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Currency
      },
      {
        propertyName: 'total',
        visible: true,
        htmlTemplate: this.colTotalTemplate,
        displayColumnType: TcListDisplayColumnType.Currency
      },
      {
        propertyName: 'status',
        visible: true,
        htmlTemplate: this.colStatusTemplate
      }
    ];

    this.commercialImputationsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Delete,
        icon: 'delete',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Duplicate,
        icon: 'content_copy',
        visible: true
      }
    ];

    this.commercialImputationsList.bulkActions = [
      {
        actionName: 'generate-invoice',
        icon: 'sentiment_very_satisfied',
        hasText: true,
        visible: true
      }
    ];

    this.commercialImputationsList.onRowAction = (row: CommercialImputation, actionName: string) => {
      if (actionName === 'edit') {
        this.editCommercialImputation(row);
      }
    };


    this.commercialImputationsList.onBulkAction = (actionName: string) => {
      if (actionName === 'generate-invoice') {
        this.store.dispatch(new GenerateInvoice(this.commercialImputationsList.selection.selected));
      }
    };

    this.commercialImputationsList.onRowClick = (row: CommercialImputation) => {
      this.editCommercialImputation(row);
    };

    this.commercialImputationsList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.CommercialImputations }));
    };


    this.selectionSubscription = this.commercialImputationsList.selection.changed.subscribe(selection => {
      if (selection.added.length > 0) {
        this.store.dispatch(new SelectCommercialImputation(selection.added[0]));
      }
      if (selection.removed.length > 0) {
        this.store.dispatch(new DeselectCommercialImputation(selection.removed[0]));
      }
    });

    this.reloadPageSubscription = this.store.pipe(select(getInvoiceResult)).subscribe(result => {
      if (result) {
        (result.commercialImputationIds as number[]).forEach(id => {
          const index = this.commercialImputationsList.tcList.dataSource.data.findIndex(i => i.id === id);
          if (index >= 0) {
            const row = this.commercialImputationsList.tcList.dataSource.data[index];
            const updated = Object.assign({}, row);
            updated.invoice = { number: result.invoiceNumber, date: result.invoiceDate };
            updated.invoiceId = result.invoiceId;
            this.commercialImputationsList.service.upsertOneInCache(updated as CommercialImputation)
          }
        });
        this.commercialImputationsList.selection.clear();
      }
    });

    this.commercialImputationsList.onDuplicateRow = (row: CommercialImputation) => {
      this.store.dispatch(new DuplicateListItem({entityName: EntitiesEnum.CommercialImputations, data: row}));
    }

  }

  ngOnDestroy() {
    if (this.selectionSubscription) {
      this.selectionSubscription.unsubscribe();
    }

    if (this.reloadPageSubscription) {
      this.reloadPageSubscription.unsubscribe();
    }
  }

  private editCommercialImputation(row: CommercialImputation) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.CommercialImputations, data: row }));
  }

  getStatusLabel(row: CommercialImputation) {
    const result = new SelectValues().commercialImputationStatus(this.translate).find(p => p.value === row.status);
    return result ? result.label : '';
  }

  getTotal = (row: CommercialImputation): string => {
    if (row.quantity && row.priceHT) {
      return this.tcCurrencyFormat.transform((row.quantity ? row.quantity : 0) * (row.priceHT ? row.priceHT : 0));
    }
  }

}
