export enum OpportunityDbStatus {
  Received = '0',
  Contacted = 1,
  FirstSalesMeetingFixed = 2,
  EstimationToBeDone = 3,
  EstimationDone = 4,
  ProposalToBeDone = 5,
  ProposalSentToFollow = 6,
  NOGO = 7,
  Won = 8,
  Lost = 9,
  Abandoned = 10,
  InStandBy = 11
}
