import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/smart/dashboard/dashboard.component';
import { TcCoreModule } from '@tc/core';
import { CoreModule } from '../core/core.module';
import { DashboardMyTasksListComponent } from './components/smart/dashboard-my-tasks-list/dashboard-my-tasks-list.component';
import { DashboardHolidaysListComponent } from './components/smart/dashboard-holidays-list/dashboard-holidays-list.component';
import { DashboardHotOpportunitiesListComponent } from './components/smart/dashboard-hot-opportunities-list/dashboard-hot-opportunities-list.component';
import { DashboardEstimationsListComponent } from './components/smart/dashboard-estimations-list/dashboard-estimations-list.component';
import { DashboardMissionsAdvancementComponent } from './components/smart/dashboard-missions-advancement/dashboard-missions-advancement.component';
import { DashboardProjectAdvancementComponent } from './components/smart/dashboard-project-advancement/dashboard-project-advancement.component';
import { DashboardCommercialImputationsToInvoiceComponent } from './components/smart/dashboard-commercial-imputations-to-invoice/dashboard-commercial-imputations-to-invoice.component';
import { DashboardPaymentsExpectedDelayedListComponent } from './components/smart/dashboard-payments-expected-delayed-list/dashboard-payments-expected-delayed-list.component';
import { DashboardCommercialImputationsToValidateComponent } from './components/smart/dashboard-commercial-imputations-to-validate/dashboard-commercial-imputations-to-validate.component';
import { DashboardProposalsToFollowComponent } from './components/smart/dashboard-proposals-to-follow/dashboard-proposals-to-follow.component';
import { TcLayoutModule } from '@tc/layout';
import { DashboardTiPopUpComponent } from './components/smart/dashboard-ti-pop-up/dashboard-ti-pop-up.component';
import { DashboardEffects } from './store/dashboard.effects';
import { EffectsModule } from '@ngrx/effects';
import { DashboardUnassignedTasksListComponent } from './components/smart/dashboard-unassigned-tasks-list/dashboard-unassigned-tasks-list.component';

const smartComponents = [
  DashboardComponent,

  DashboardMyTasksListComponent,
  DashboardUnassignedTasksListComponent,

  DashboardHolidaysListComponent,

  DashboardHotOpportunitiesListComponent,
  DashboardEstimationsListComponent,
  DashboardProposalsToFollowComponent,

  DashboardMissionsAdvancementComponent,
  DashboardProjectAdvancementComponent,

  DashboardCommercialImputationsToInvoiceComponent,
  DashboardCommercialImputationsToValidateComponent,
  DashboardPaymentsExpectedDelayedListComponent,
  DashboardTiPopUpComponent,
];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    TcLayoutModule,
    CoreModule,
    EffectsModule.forFeature([DashboardEffects]),
  ],
  entryComponents: [DashboardTiPopUpComponent],
  exports: [...smartComponents],
})
export class DashboardModule {}
