import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HolidayDetailComponent } from './components/smart/holiday-detail/holiday-detail.component';
import { HolidaysListComponent } from './components/smart/holidays-list/holidays-list.component';
import { TcCoreModule } from '@tc/core';
import { CoreModule } from '../core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { HolidaysEffects } from './store/holidays.effects';

const smartComponents = [
  HolidayDetailComponent,
  HolidaysListComponent
];

@NgModule({
  declarations: [
    ...smartComponents
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule,
    EffectsModule.forFeature([HolidaysEffects]),
  ],
  entryComponents: [
    HolidayDetailComponent
  ],
  exports: [
    ...smartComponents
  ]
})
export class HolidaysModule { }
