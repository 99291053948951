import { Rights } from '../api/krakenn-api';

export class RightsType {
  static className = 'Rights';
  static gqlRootQueryName = 'rights';
  static gqlColumns: string[] = [
      'collaboratorId',
      'roleId',
  ];

  static gqlNodeMapper = (node: any): Rights => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Rights;
    }

}
