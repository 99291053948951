import { Holiday } from 'src/generated/api/krakenn-api/model/holiday';

export class DashboardHolidaysType {
    static className = 'Holiday';
    static gqlRootQueryName = 'holiday';
    static gqlColumns: string[] = [
        '_id',
        'label',
        'startDate',
        'stopDate',
        `collaborator {
            _id,
            firstName,
            lastName,
        }`
    ];

    static gqlNodeMapper = (node: any): Holiday => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Holiday;
    }

}
