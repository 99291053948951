import { Client, Mission, Project } from 'src/generated/api/krakenn-api';
import { TcReportingQueryService, TcReportingQuery } from '@tc/core';
import { Injectable } from '@angular/core';
import { TcService } from '@tc/core';
import { QlAutcompleteService } from './core/ql-autocomplete.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EntitiesEnum } from '../modules/core/enums/entities-enum';
import { PermissionService } from './permission.service';
import { UserRole } from '../modules/core/user-role.enum';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ReportingService extends TcService {
  private initiated = false;

  constructor(
    private reportingQueries: TcReportingQueryService,
    private qlAutocompleteService: QlAutcompleteService,
    private permissionService: PermissionService,
    private store: Store<any>
  ) {
    super();
  }

  public async init(apiBasePath: string) {
    // Check if the service has already been initiated. If yes, no need to call it again.
    if (this.initiated) {
      return;
    }

    // Register backend path
    this.reportingQueries.registerBackEndDomain(apiBasePath);

    const currentUser = this.permissionService.authUser;
    const isAdmin = this.permissionService.authUserHasRole(UserRole.Admin);

    // Queries declaration
    const queries: TcReportingQuery[] = [
      {
        id: 'reportingTMA',
        name: 'Reporting TMA',
        limit: 500,
        parentCategory: 'Clients',
        category: 'TMA',
        display: isAdmin,
        filters: [
          {
            key: 'clientId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) =>
                this.qlAutocompleteService.getAutocompleteValues<Client>(
                  EntitiesEnum.Clients,
                  term,
                  'id',
                  'shortName,name'
                ),
              listValue: 'client.name',
            },
            validators: {
              clientId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'missionId',
            type: 'autocomplete-list',
            templateOptions: {
              required: false,
              filter: (term) =>
                this.qlAutocompleteService.getAutocompleteValues<Mission>(
                  EntitiesEnum.Missions,
                  term,
                  'id',
                  'name'
                ),
              listValue: 'mission.name',
            },
            validators: {
              missionId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
        ],
      },
      {
        id: 'ClientWorkAndInvoicing',
        name: 'Work and Invoicing',
        limit: 500,
        parentCategory: 'Clients',
        category: 'TMA',
        display: isAdmin,
        filters: [
          {
            key: 'clientId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) =>
                this.qlAutocompleteService.getAutocompleteValues<Client>(
                  EntitiesEnum.Clients,
                  term,
                  'id',
                  'shortName,name'
                ),
              listValue: 'client.name',
            },
            validators: {
              clientId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
        ],
      },
      /*{
        id: 'AnalyseFinanciereProjet',
        name: 'Financial Analysis Project',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Analysis',
        display: isAdmin,
        filters: [
          {
            key: 'projectId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) =>
                this.qlAutocompleteService.getAutocompleteValues<Project>(
                  EntitiesEnum.Projects,
                  term,
                  'id',
                  'name'
                ),
              listValue: 'project.name',
            },
            validators: {
              clientId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'missionId',
            type: 'autocomplete-list',
            templateOptions: {
              required: false,
              filter: (term) =>
                this.qlAutocompleteService.getAutocompleteValues<Mission>(
                  EntitiesEnum.Missions,
                  term,
                  'id',
                  'name'
                ),
              listValue: 'mission.name',
            },
            validators: {
              missionId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss'
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd'
            },
          },
        ],
      },*/
      {
        id: 'DetailedActivity',
        name: 'Detailed Activity Per Collab',
        limit: 500,
        parentCategory: 'Production',
        category: 'Activity',
        display: isAdmin,
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
        ],
      },
      {
        id: 'MissionActivityPerCollab',
        name: 'Mission Activity Per Collab',
        limit: 500,
        parentCategory: 'Production',
        category: 'Activity',
        display: isAdmin,
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
          {
            key: 'trigram',
            type: 'input',
            templateOptions: {
              type: 'text',
            },
          },
        ],
      },
      {
        id: 'ProjectActivityPerCollab',
        name: 'Project Activity Per Collab',
        limit: 500,
        parentCategory: 'Production',
        category: 'Activity',
        display: isAdmin,
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
          {
            key: 'trigram',
            type: 'input',
            templateOptions: {
              type: 'text',
            },
          },
        ],
      },
      {
        id: 'WorkloadPerProject',
        name: 'Workload Per Project',
        limit: 500,
        parentCategory: 'Production',
        category: 'Activity',
        display: isAdmin,
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
        ],
      },
      {
        id: 'WorkloadPerMission',
        name: 'Workload Per Mission',
        limit: 500,
        parentCategory: 'Production',
        category: 'Activity',
        display: isAdmin,
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
        ],
      },
      {
        id: 'ActivityPerCollab',
        name: 'Activity Per Collaborator',
        limit: 500,
        parentCategory: 'Production',
        category: 'Activity',
        display: isAdmin,
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
        ],
      },
      {
        id: 'SalesRevenuPerMonth',
        name: 'Sales Revenu Per Month',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Sales Revenu',
        display: isAdmin,
      },
      {
        id: 'InvoicesByDate',
        name: 'Invoices by Date',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Sales Revenu',
        display: isAdmin,
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'yyyy-MMMM-dd',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'yyyy-MMMM-dd',
              },
            },
          },
        ],
      },
      {
        id: 'SalesRevenuPerQuarter',
        name: 'Sales Revenu Per Quarter',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Sales Revenu',
        display: isAdmin,
      },
      {
        id: 'SalesRevenuPerYear',
        name: 'Sales Revenu Per Year',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Sales Revenu',
        display: isAdmin,
      },
      {
        id: 'TopClientsSalesRevenue',
        name: 'Sales Revenu Per Client',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Sales Revenu',
        display: isAdmin,
      },
      {
        id: 'DayWorkloadPerCollab',
        name: 'Day Workload Per Collaborator',
        limit: 500,
        parentCategory: 'Production',
        category: 'Workload',
        display: isAdmin,
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
        ],
      },
      /*{
        id: 'AnalyseFinanciereGlobale',
        name: 'Global Financial Analysis',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Analysis',
        display: isAdmin,
      },*/
      {
        id: 'MarginClientGlobal',
        name: 'Margin/Client Global',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Margin',
        display: isAdmin,
      },
      {
        id: 'MissionOverwatch',
        name: 'Mission Overwatch',
        limit: 500,
        parentCategory: 'Internal Steering Comitee',
        category: 'Mission Overwatch',
        display: isAdmin,
      },
      {
        id: 'MarginProjectGlobal',
        name: 'Margin/Project Global',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Margin',
        display: isAdmin,
      },
      {
        id: 'MarginClient2Y',
        name: 'Margin/Client Last 2 Years',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Margin',
        display: isAdmin,
      },
      {
        id: 'MarginProject2Y',
        name: 'Margin/Project Last 2 Years',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Margin',
        display: isAdmin,
      },
      {
        id: 'MarginClient1Y',
        name: 'Margin/Client Last 12 Months',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Margin',
        display: isAdmin,
      },
      {
        id: 'MarginProject1Y',
        name: 'Margin/Project Last 12 Months',
        limit: 500,
        parentCategory: 'Finance',
        category: 'Margin',
        display: isAdmin,
      },
      {
        id: 'reportingTI',
        name: 'My imputations',
        limit: 500,
        category: 'Time Imputations',
        filters: [
          {
            key: 'collaboratorId',
            type: 'input',
            defaultValue: currentUser.id,
            hide: true,
            templateOptions: {
              type: 'text',
            },
          },
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              type: 'text',
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              type: 'text',
            },
          },
        ],
      },
      {
        id: 'reportingTM',
        name: 'Unassigned Tasks',
        limit: 500,
        category: 'Task market',
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              type: 'text',
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              type: 'text',
            },
          },
        ],
      },
      {
        id: 'reportingWorkloadPlan',
        name: 'Workload Plan',
        limit: 500,
        category: 'Task market',
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              type: 'text',
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              type: 'text',
            },
          },
        ],
      },
      {
        id: 'reportingCurrentWeekAchievement',
        name: 'Week Achievement',
        limit: 500,
        category: 'Task market',
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
        ],
      },
      {
        id: 'reportingCurrentWeekAchievementPerCollab',
        name: 'Week Achievement / Collab',
        limit: 500,
        category: 'Task market',
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
        ],
      },
      {
        id: 'reportingTerminatedTasksLastWeekPerCollab',
        name: 'Terminated Tasks Last Week Per Collaborator',
        limit: 500,
        category: 'Task market',
        filters: [
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              datepickerPopup: 'yyyy-MMMM-dd',
              type: 'text',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              },
            },
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              label: 'Au',
              datepickerPopup: 'yyyy-MMMM-dd',
            },
          },
        ],
      },
    ];

    // Save queries into the main service in Tc/Core
    this.reportingQueries.registerQueries(queries);

    // Set that the service was instancied
    this.initiated = true;
  }
}
