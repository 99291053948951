import { ActivitysActions, ActivitysActionTypes } from './activitys.actions';
import { ActivitysState, initialActivitysState } from './activitys.state';

export function activitysReducer(
    state = initialActivitysState,
    action: ActivitysActions
): ActivitysState {
    switch (action.type) {

        case ActivitysActionTypes.LOAD_ACTIVITYS_LIST_SUCCESS: {

            return {
                ...state,
                activitysList: action.payload
            };
        }

        case ActivitysActionTypes.DISPLAY_ACTIVITYS_LIST: {
            return {
                ...state,
                activitysList: []
            };
        }

        case ActivitysActionTypes.ADD_ACTIVITY_SUCCESS: {

            const activitys = state.activitysList;
            const activitysClone = Object.assign([], activitys);
            activitysClone.push(action.payload);

            return {
                ...state,
                activitysList: activitysClone
            };
        }

        case ActivitysActionTypes.EDIT_ACTIVITY_SUCCESS: {

            const activitys = [...state.activitysList];

            const activityIndex = activitys.findIndex(x => x.id === action.payload.id);

            if(activityIndex >= 0){
                activitys[activityIndex] = action.payload;
            }

            return {
                ...state,
                activitysList: activitys
            };
        }

        case ActivitysActionTypes.DELETE_ACTIVITY_SUCCESS: {

            const activitys = [...state.activitysList];

            const activitysClone = Object.assign([], activitys);

            const activityIndex = activitys.findIndex(x => x.id === action.payload);
            if(activityIndex >= 0){
                activitys.splice(activityIndex, 1);
            }

            return {
                ...state,
                activitysList: activitys
            };
        }

        case ActivitysActionTypes.MARK_ACTIVITY_AS_DONE_SUCCESS: {
          const activitys = [...state.activitysList];

          const activityIndex = activitys.findIndex(x => x.id === action.payload.id);

          if(activityIndex >= 0){
              activitys[activityIndex] = action.payload;
          }

          return {
              ...state,
              activitysList: activitys
          };
        }

        default: {
            return state;
        }
    }
}
