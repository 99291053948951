import { Estimation } from 'src/generated/api/krakenn-api/model/estimation';

export class DashboardEstimationsType {
    static className = 'Estimation';
    static gqlRootQueryName = 'estimation';
    static gqlColumns: string[] = [
        '_id',
        'name'
    ];

    static gqlNodeMapper = (node: any): Estimation => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Estimation;
    }

}
