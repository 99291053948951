import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CollaboratorWorkload } from './../../../collaborators.interfaces';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'app-workload-per-collab',
  templateUrl: './workload-per-collab.component.html',
  styleUrls: ['./workload-per-collab.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkloadPerCollabComponent extends TcDumbComponent implements OnInit {
  @Input() collaboratorWorkload: CollaboratorWorkload = null;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
