import { Component, ElementRef, OnInit } from "@angular/core";
import { TcTranslateService, TcGenericFormComponent } from "@tc/core";
import { Invoice, Client } from "src/generated/api/krakenn-api";
import { EntityCollectionServiceFactory } from "ngrx-data";
import { EntitiesEnum } from "src/app/modules/core/enums/entities-enum";
import { SelectValues } from "src/app/modules/core/enums/select-values";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { QlAutcompleteService } from "src/app/services/core/ql-autocomplete.service";
import { InvoiceDbStatus } from "src/app/modules/core/enums/invoice.db-status.enum";
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: "app-invoice-detail",
  templateUrl: "./invoice-detail.component.html",
  styleUrls: ["./invoice-detail.component.scss"],
})
export class InvoiceDetailComponent extends TcGenericFormComponent<Invoice> implements OnInit {
  readOnly = false;

  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {
    super(
      EntitiesEnum.Invoices,
      entityCollectionServiceFactory,
      translate,
      elem,
      dateAdapter,
    );

    this.fields = [
      {
        fieldGroupClassName: "form-display-grid",
        fieldGroup: [
          {
            key: "clientId",
            type: "autocomplete-list",
            templateOptions: {
              required: true,
              filter: (term) =>
                this.qlAutocompleteService.getAutocompleteValues<Client>(
                  EntitiesEnum.Clients,
                  term,
                  "id",
                  "name"
                ),
              listValue: "client.name",
            },
            validators: {
              clientId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
            expressionProperties: {
              "templateOptions.disabled": "model.id",
            },
          },
          {
            key: "number",
            type: "input",
            templateOptions: {
              type: "number",
              required: true,
            },
            expressionProperties: {
              "templateOptions.disabled": "model.id",
            },
          },
          {
            key: "date",
            type: "datepicker",
            templateOptions: {
              required: true,
              type: "text",
              datepickerPopup: "dd-MMMM-yyyy",
              datepickerOptions: {
                format: "dd-MM-yyyy hh:mm:ss",
              },
            },
            expressionProperties: {
              "templateOptions.disabled": "model.id",
            },
          },
          {
            key: "amountHT",
            type: "input",
            templateOptions: {
              type: "number",
              required: true,
            },
            expressionProperties: {
              "templateOptions.disabled": "model.id",
            },
          },
          {
            key: "amountTVA",
            type: "input",
            templateOptions: {
              type: "number",
              required: true,
            },
            expressionProperties: {
              "templateOptions.disabled": "model.id",
            },
          },
          {
            key: "amountTTC",
            type: "input",
            templateOptions: {
              type: "number",
              required: true,
            },
            expressionProperties: {
              "templateOptions.disabled": "model.id",
            },
          },
          {
            key: "status",
            type: "select",
            templateOptions: {
              required: true,
              options: new SelectValues().invoiceStatus(this.translate),
              change: this.onChangeStatus,
            },
          },
          {
            key: "payedDate",
            type: "datepicker",
            templateOptions: {
              type: "text",
              datepickerPopup: "dd-MMMM-yyyy",
              datepickerOptions: {
                format: "dd-MM-yyyy hh:mm:ss",
              },
            },
          },
        ],
      },
    ];
  }

  onChangeStatus = () => {
    if ((this.model as any).status === InvoiceDbStatus.Payed) {
      const payedDate = (new Date().getTime()).toString();

      this.model = {
        ...this.model,
        payedDate,
      };
    }
  };

  // ngOnInit() {
  //   super.ngOnInit();

  //   /*if ((this.model as any).id) {
  //     (this.model as any).readOnly = true;
  //   } else {
  //     (this.model as any).readOnly = false;
  //   }*/
  // }
}
