import { Component, ElementRef } from '@angular/core';
import { TcTranslateService, TcGenericFormComponent } from '@tc/core';
import { CommercialImputation, Mission, Invoice } from 'src/generated/api/krakenn-api';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-commercial-imputation-detail',
  templateUrl: './commercial-imputation-detail.component.html',
  styleUrls: ['./commercial-imputation-detail.component.scss']
})
export class CommercialImputationDetailComponent extends TcGenericFormComponent<CommercialImputation> {

  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {

    super(EntitiesEnum.CommercialImputations, entityCollectionServiceFactory, translate, elem, dateAdapter);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'missionId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Mission>(EntitiesEnum.Missions, term, 'id', 'name,project{name client{name}}',
                (item: any) => {
                  const clientName = item.project.client.name.substring(0, 10);
                  const projectName = item.project.name.substring(0, 10);
                  return `${clientName} / ${projectName} / ${item.name}`;
                },
                // {key: 'client.name', order: 'ASC'}
              ),
              listValue: 'mission.name'
            },
            validators: {
              missionId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            }
          },
          {
            key: 'invoiceId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Invoice>(EntitiesEnum.Invoices, term, 'id', 'number'),
              listValue: 'invoice.number'
            },
            validators: {
              invoiceId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            }
          },
          {
            key: 'date',
            type: 'datepicker',
            templateOptions: {
              required: true,
              type: 'text',
              datepickerPopup: 'dd-MMMM-yyyy',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss'
              }
            }
          },
          {
            key: 'quantity',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: true
            }
          },
          {
            key: 'priceHT',
            type: 'input',
            templateOptions: {
              type: 'number',
            }
          },
          {
            key: 'status',
            type: 'select',
            templateOptions: {
              required: true,
              options: new SelectValues().commercialImputationStatus(this.translate)

            }
          }
        ]
      },
      {
        key: 'workDoneDescription',
        type: 'textarea',
        templateOptions: {
          autosize: true,
          autosizeMinRows: 10,
          autosizeMaxRows: 10,
        }
      },
      // {

      //   fieldGroupClassName: 'display-row',
      //   fieldGroup: [
      //     {
      //       key: 'workDoneDescription',
      //       type: 'textarea-editor',
      //       className: 'textarea-form-content textarea-form-label'
      //     },
      //   ]
      // }
    ];

  }

}
