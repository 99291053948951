import { Component, OnInit, Inject, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TcGenericEntity, TcNotificationService, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-create-edit-popup',
  templateUrl: './create-edit-popup.component.html',
  styleUrls: ['./create-edit-popup.component.scss']
})
export class CreateEditPopupComponent implements OnInit, OnDestroy {

  @ViewChild('target', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef;

  componentRef: ComponentRef<any>;

  title = '';

  constructor(
    private resolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<CreateEditPopupComponent>,
    private notificationService: TcNotificationService,
    private translateService: TcTranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onClose() {
    this.dialogRef.close(null);
  }

  afterSave(model: TcGenericEntity) {

    const message = this.translateService.instant(`${model.id ? 'updated' : 'added'}-message`);

    this.notificationService.success(message);

    this.dialogRef.close(model);
  }

  ngOnInit() {

    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.vcRef.createComponent(factory);

    this.componentRef.instance.dialogRef = this.dialogRef;
    this.componentRef.instance.notificationService = this.notificationService;
    this.componentRef.instance.translateService = this.translateService;
    this.componentRef.instance.afterSave = this.afterSave;

    if (this.data.data) {
      this.componentRef.instance.model = Object.assign({}, this.data.data);
    }

    if (this.data.args) {
      if (this.data.args.readOnly) {
        this.componentRef.instance.readOnly = this.data.args.readOnly;
      } else if (this.data.args.estimationId) {
        this.componentRef.instance.estimation = this.data.args.estimationId;
      }
    }

    if (this.data.entityName) {
      this.title = `${this.data.entityName.toLowerCase()}.${this.data.data && this.data.data.id ? 'edit' : 'new'}`;
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
