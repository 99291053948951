import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ClientsService } from './api/clients.service';
import { CollaboratorService } from './api/collaborator.service';
import { CommentService } from './api/comment.service';
import { CommercialImputationService } from './api/commercialImputation.service';
import { EstimationService } from './api/estimation.service';
import { InvoiceService } from './api/invoice.service';
import { MissionService } from './api/mission.service';
import { ProjectService } from './api/project.service';
import { ProposalService } from './api/proposal.service';
import { RoleService } from './api/role.service';
import { TaskService } from './api/task.service';
import { TimeImputationService } from './api/timeImputation.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
