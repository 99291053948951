import { Assign } from '../api/krakenn-api';

export class AssignType {
  static className = 'Assign';
  static gqlRootQueryName = 'assign';
  static gqlColumns: string[] = [
      'collaboratorId',
      'taskId',
  ];

  static gqlNodeMapper = (node: any): Assign => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Assign;
    }

}
