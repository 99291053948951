import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import { TcGenericListComponent, TcSmartComponent, TcListRowActionButtonsPosition, TcListSortType, TcListFilterType, TcListDisplayColumnType, TcFilterTypes, FilterTypesEnum, TcTranslateService } from '@tc/core';
import { Holiday } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { Store, select } from '@ngrx/store';
import { EditListItem, AddListItem } from 'src/app/modules/core/store/actions/list.actions';
import { Observable, Subscription } from 'rxjs';
import { TcUser, getTcAuthUser } from '@tc/auth';
import { UserRole } from 'src/app/modules/core/user-role.enum';
import { HolidayDbStatus } from 'src/app/modules/core/enums/holiday-db-status.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-holidays-list',
  templateUrl: './holidays-list.component.html',
  styleUrls: ['./holidays-list.component.scss']
})
export class HolidaysListComponent extends TcSmartComponent implements OnInit, OnDestroy, AfterViewInit {

  listId = 'holidays-list';

  isAdmin = false;
  currentUser$: Observable<TcUser>;
  currentUser: TcUser;
  currentUserSubscription: Subscription;

  constructor(
    private store: Store<any>,
    private translate: TcTranslateService) {
    super();
  }

  @ViewChild('colStatusTemplate', { static: true }) colStatusTemplate: TemplateRef<any>;
  @ViewChild('colTypeTemplate', { static: true }) colTypeTemplate: TemplateRef<any>;

  @ViewChild('rowActionEditTemplate', { static: true }) rowActionEditTemplate: TemplateRef<any>;
  @ViewChild('rowActionDeleteTemplate', { static: true }) rowActionDeleteTemplate: TemplateRef<any>;


  holidaysList: TcGenericListComponent<Holiday>;
  @ViewChild('holidaysList', { static: true }) set setHolidaysList(values: TcGenericListComponent<Holiday>) {

    this.holidaysList = values;
    this.holidaysList.entityName = EntitiesEnum.Holidays;
  }

  ngOnDestroy(): void {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
  }

  ngOnInit() {

    this.currentUserSubscription = this.store.pipe(select(getTcAuthUser)).subscribe(user => {
      if (user) {
        this.currentUser = user;
        if ((user as any).roles && (user as any).roles.find(r => r === UserRole.Admin)) {
          this.isAdmin = true;
        }
      }
    });

    this.holidaysList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.holidaysList.showTotalInActionsHeader = true;

    this.holidaysList.sortType = TcListSortType.Server;
    this.holidaysList.filterType = TcListFilterType.Server;
    this.holidaysList.hasFixedHeader = true;

    this.holidaysList.hasAddButton = true;
    this.holidaysList.addItemWhenKeyPresed = true;

    this.holidaysList.hasActionsLabel = false;
    this.holidaysList.isPaged = false;

    this.holidaysList.onScrollDown = () => {
      console.log('scroll');
      this.holidaysList.service.getAll();
    };

    this.holidaysList.filters = [
      {
        key: TcFilterTypes.anyFieldContains,
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.anyFieldContains,
        label: `${this.listId}.filter-label`,
        options: {
          minLength: 1,
          debounceTime: 500,
        }
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.inactive`,
          onLabel: `${this.listId}.filter.toggles.active`,
          default: true,
          onValue: [HolidayDbStatus.ToBeValidated]
        }
      },
      {
        key: 'startDate',
        type: TcFilterTypes.daterange,
        label: `${this.listId}.filter.period`,
        hidden: false,
        options: {
          format: 'DD/MM/YYYY',
          default: {
            start: moment(new Date(), 'DD/MM/YYYY'),
            end: moment(new Date(), 'DD/MM/YYYY').add(3, "month"),
          },
          hasReset: true
        }
      }
    ];

    this.holidaysList.columns = [
      {
        propertyName: 'collaborator.name',
        visible: true,
        sortExpression: 'collaborator.firstName'
      },
      {
        propertyName: 'label',
        visible: true
      },
      {
        propertyName: 'startDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'stopDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'type',
        visible: true,
        htmlTemplate: this.colTypeTemplate
      },
      {
        propertyName: 'status',
        visible: true,
        htmlTemplate: this.colStatusTemplate
      },
      {
        propertyName: 'comments',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Html
      }
    ];

    this.holidaysList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true,
        htmlTemplate: this.rowActionEditTemplate
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true,
        htmlTemplate: this.rowActionDeleteTemplate
      }
    ];

    this.holidaysList.onRowAction = (row: Holiday, actionName: string) => {
      if (actionName === 'edit') {
        this.editHoliday(row);
      }
    };


    this.holidaysList.onRowClick = (row: Holiday) => {
      this.editHoliday(row);
    };

    this.holidaysList.addItem = () => {

      this.store.dispatch(new AddListItem({
        entityName: EntitiesEnum.Holidays, args: {
          collaboratorId: this.currentUser.id,
          collaborator: {
            _id: this.currentUser.id,
            firstName: (this.currentUser as any).firstName,
            lastName: (this.currentUser as any).lastName,
            name: (this.currentUser as any).firstName + ' ' + (this.currentUser as any).lastName
          }
        }
      }));
    };

  }

  editHoliday(row: Holiday) {
    if (this.actionIsVisible(row)) {
      this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Holidays, data: row }));
    }
  }

  deleteHoliday(row: Holiday) {
    if (this.actionIsVisible(row)) {
      this.holidaysList.tcList.onDeleteAction(row);
    }
  }

  getStatusLabel(holiday: Holiday) {
    const result = new SelectValues().holidayStatus(this.translate).find(p => p.value === holiday.status);
    return result ? result.label : '';
  }

  getTypeLabel(holiday: Holiday) {
    const result = new SelectValues().holidayType(this.translate).find(p => p.value === holiday.type);
    return result ? result.label : '';
  }

  actionIsVisible(holiday: Holiday) {
    return this.isAdmin || (!this.isAdmin && holiday.collaboratorId + '' === this.currentUser.id + '');
  }

}
