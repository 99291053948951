import { EntityMetadataMap } from 'ngrx-data';
import { EntitiesEnum } from './EntitiesEnum';

import { ActivityType } from './modelMetadata/ActivityType';
import { AlertType } from './modelMetadata/AlertType';
import { AssignType } from './modelMetadata/AssignType';
import { ClientType } from './modelMetadata/ClientType';
import { CollaboratorType } from './modelMetadata/CollaboratorType';
import { CommentType } from './modelMetadata/CommentType';
import { CommercialImputationType } from './modelMetadata/CommercialImputationType';
import { ContactType } from './modelMetadata/ContactType';
import { CoverType } from './modelMetadata/CoverType';
import { DbVersionType } from './modelMetadata/DbVersionType';
import { EstimationType } from './modelMetadata/EstimationType';
import { EstimationLineType } from './modelMetadata/EstimationLineType';
import { HolidayType } from './modelMetadata/HolidayType';
import { InvoiceType } from './modelMetadata/InvoiceType';
import { LeadType } from './modelMetadata/LeadType';
import { MissionType } from './modelMetadata/MissionType';
import { OpportunityType } from './modelMetadata/OpportunityType';
import { ParamType } from './modelMetadata/ParamType';
import { PaymentType } from './modelMetadata/PaymentType';
import { ProjectType } from './modelMetadata/ProjectType';
import { ProposalType } from './modelMetadata/ProposalType';
import { RightsType } from './modelMetadata/RightsType';
import { RoleType } from './modelMetadata/RoleType';
import { TaskType } from './modelMetadata/TaskType';
import { TimeImputationType } from './modelMetadata/TimeImputationType';

export const entityMetadata: EntityMetadataMap = {
  Activitys: {},
  Alerts: {},
  Assigns: {},
  Clients: {},
  Collaborators: {},
  Comments: {},
  CommercialImputations: {},
  Contacts: {},
  Covers: {},
  DbVersions: {},
  Estimations: {},
  EstimationLines: {},
  Holidays: {},
  Invoices: {},
  Leads: {},
  Missions: {},
  Opportunities: {},
  Params: {},
  Payments: {},
  Projects: {},
  Proposals: {},
  Rightss: {},
  Roles: {},
  Tasks: {},
  TimeImputations: {},
};


export const pluralNames = {
  Activitys: 'activitys',
  Alerts: 'alerts',
  Assigns: 'assigns',
  Clients: 'clients',
  Collaborators: 'collaborators',
  Comments: 'comments',
  CommercialImputations: 'commercialimputations',
  Contacts: 'contacts',
  Covers: 'covers',
  DbVersions: 'dbversions',
  Estimations: 'estimations',
  EstimationLines: 'estimationlines',
  Holidays: 'holidays',
  Invoices: 'invoices',
  Leads: 'leads',
  Missions: 'missions',
  Opportunities: 'opportunities',
  Params: 'params',
  Payments: 'payments',
  Projects: 'projects',
  Proposals: 'proposals',
  Rightss: 'rightss',
  Roles: 'roles',
  Tasks: 'tasks',
  TimeImputations: 'timeimputations',
};

export const typesConfig: { [key: string]: any } = { };
typesConfig[EntitiesEnum.Invoices] = InvoiceType;
typesConfig[EntitiesEnum.Activitys] = ActivityType;
typesConfig[EntitiesEnum.Alerts] = AlertType;
typesConfig[EntitiesEnum.Assigns] = AssignType;
typesConfig[EntitiesEnum.Clients] = ClientType;
typesConfig[EntitiesEnum.Collaborators] = CollaboratorType;
typesConfig[EntitiesEnum.Comments] = CommentType;
typesConfig[EntitiesEnum.CommercialImputations] = CommercialImputationType;
typesConfig[EntitiesEnum.Contacts] = ContactType;
typesConfig[EntitiesEnum.Covers] = CoverType;
typesConfig[EntitiesEnum.DbVersions] = DbVersionType;
typesConfig[EntitiesEnum.Estimations] = EstimationType;
typesConfig[EntitiesEnum.EstimationLines] = EstimationLineType;
typesConfig[EntitiesEnum.Holidays] = HolidayType;
typesConfig[EntitiesEnum.Invoices] = InvoiceType;
typesConfig[EntitiesEnum.Leads] = LeadType;
typesConfig[EntitiesEnum.Missions] = MissionType;
typesConfig[EntitiesEnum.Opportunities] = OpportunityType;
typesConfig[EntitiesEnum.Params] = ParamType;
typesConfig[EntitiesEnum.Payments] = PaymentType;
typesConfig[EntitiesEnum.Projects] = ProjectType;
typesConfig[EntitiesEnum.Proposals] = ProposalType;
typesConfig[EntitiesEnum.Rightss] = RightsType;
typesConfig[EntitiesEnum.Roles] = RoleType;
typesConfig[EntitiesEnum.Tasks] = TaskType;
typesConfig[EntitiesEnum.TimeImputations] = TimeImputationType;
