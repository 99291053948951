import { TcAction } from "@tc/auth";
import { TcGenericEntity } from "@tc/core";
import { Comment } from 'src/generated/api/krakenn-api';

export enum CommentsActionTypes {
  DISPLAY_COMMENTS_LIST = "[Comments] Display Comments List",
  LOAD_COMMENTS_LIST = "[Comments] Load Comments List",
  LOAD_COMMENTS_LIST_SUCCESS = "[Comments] Load Comments List Success",
  ADD_COMMENT = "[Comments] Add Comment",
  ADD_COMMENT_SUCCESS = "[Comments] Add Comment Success",
  EDIT_COMMENT = "[Comments] Edit Comment",
  EDIT_COMMENT_SUCCESS = "[Comments] Edit Comment Success",
  DELETE_COMMENT = "[Comments] Delete Comment",
  DELETE_COMMENT_SUCCESS = "[Comments] Delete Comment Success"
}

export class DisplayCommentsList implements TcAction {
  readonly type = CommentsActionTypes.DISPLAY_COMMENTS_LIST;
  constructor(
    public payload: {
      table: string;
      id: number;
      args?: any
    }
  ) { }
}

export class LoadCommentsList implements TcAction {
  readonly type = CommentsActionTypes.LOAD_COMMENTS_LIST;
  constructor(
    public payload: {
      data: { id: number, table: string };
    }
  ) { }
}

export class LoadCommentsListSuccess implements TcAction {
  readonly type = CommentsActionTypes.LOAD_COMMENTS_LIST_SUCCESS;
  constructor(public payload: Comment[]) { }
}

export class AddComment implements TcAction {
  readonly type = CommentsActionTypes.ADD_COMMENT;
  constructor(public payload: Comment) { }
}

export class AddCommentSuccess implements TcAction {
  readonly type = CommentsActionTypes.ADD_COMMENT_SUCCESS;
  constructor(public payload: Comment) { }
}

export class EditComment implements TcAction {
  readonly type = CommentsActionTypes.EDIT_COMMENT;
  constructor(public payload: Comment) { }
}

export class EditCommentSuccess implements TcAction {
  readonly type = CommentsActionTypes.EDIT_COMMENT_SUCCESS;
  constructor(public payload: Comment) { }
}

export class DeleteComment implements TcAction {
  readonly type = CommentsActionTypes.DELETE_COMMENT;
  constructor(public payload: number) { }
}

export class DeleteCommentSuccess implements TcAction {
  readonly type = CommentsActionTypes.DELETE_COMMENT_SUCCESS;
  constructor(public payload: number) { }
}

export type CommentsActions =
  DisplayCommentsList
  | LoadCommentsList
  | LoadCommentsListSuccess
  | AddComment
  | AddCommentSuccess
  | EditComment
  | EditCommentSuccess
  | DeleteComment
  | DeleteCommentSuccess;
