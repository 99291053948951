import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { PermissionService } from 'src/app/services/permission.service';
import { DeleteComment } from '../../../store/comments.actions';
import { EditCommentPopupComponent } from '../edit-comment-popup/edit-comment-popup.component';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent implements OnInit {

  @Input()
  comment: Comment;

  authUserId: string;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.authUserId = this.permissionService.authUser.id;
  }

  edit(comment: any) {
    this.dialog.open(EditCommentPopupComponent, {
      data: comment,
      width: '54.5vw'
    });
  }

  delete(comment: any) {
    this.store.dispatch(new DeleteComment(comment.id));
  }

}
