import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPageState, TcPage } from '@tc/core';

@Component({
  selector: 'app-contacts-page',
  templateUrl: './contacts-page.component.html',
  styleUrls: ['./contacts-page.component.scss']
})
export class ContactsPageComponent extends TcPage implements OnInit {

  constructor(store: Store<any>) {
    super(store);
   }

  ngOnInit(): void {
    const pageInfo: IPageState = {
      title: 'contacts-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }

}
