import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FilterTypesEnum,
  TcAppState,
  TcFilterTypes,
  TcGenericListComponent,
  TcListDefaultAction,
  TcListDisplayColumnType,
  TcListFilterType,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcSmartComponent,
  TcTranslateService,
} from '@tc/core';
import { Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { MissionDbStatus } from 'src/app/modules/core/enums/mission-db-status.enum';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import {
  AddListItem,
  DuplicateListItem,
  EditListItem,
} from 'src/app/modules/core/store/actions/list.actions';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import {
  ListRefreshService,
  ListRefreshType,
} from 'src/app/services/list-refresh.service';
import { Collaborator, Mission, Project } from 'src/generated/api/krakenn-api';
import { AddMissionCommercialImputation } from '../../../store/missions.actions';

@Component({
  selector: 'app-missions-list',
  templateUrl: './missions-list.component.html',
  styleUrls: ['./missions-list.component.scss'],
})
export class MissionsListComponent
  extends TcSmartComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  listId = 'missions-list';

  private refreshSubscription: Subscription;

  @ViewChild('colImputationTypeTemplate', { static: true })
  colImputationTypeTemplate: TemplateRef<any>;
  @ViewChild('colStatusTemplate', { static: true })
  colStatusTemplate: TemplateRef<any>;
  @ViewChild('clientNameTemplate', { static: true })
  clientNameTemplate: TemplateRef<any>;
  @ViewChild('rowActionCommImputation', { static: true })
  rowActionCommImputation: TemplateRef<any>;

  missionsList: TcGenericListComponent<Mission>;
  @ViewChild('missionsList', { static: true }) set setMissionsList(
    values: TcGenericListComponent<Mission>
  ) {
    this.missionsList = values;
    this.missionsList.entityName = EntitiesEnum.Missions;
  }

  constructor(
    private readonly store: Store<TcAppState>,
    private readonly listRefreshService: ListRefreshService,
    private readonly translate: TcTranslateService,
    private qlAutocompleteService: QlAutcompleteService
  ) {
    super();
  }

  ngAfterViewInit() {}

  ngOnInit() {
    this.refreshSubscription = this.listRefreshService.subject
      .pipe(skipWhile((type) => type !== ListRefreshType.Missions))
      .subscribe(() => this.refresh(true));

    this.missionsList.hasTotalLine = true;
    this.missionsList.totalLineColumns = [
      'quantity',
      'managementFactor',
      'priceHTPerUnit',
    ];
    this.missionsList.totalDisplayColumn = 'targetDate';

    this.missionsList.rowActionButtonsPosition =
      TcListRowActionButtonsPosition.BeforeData;
    this.missionsList.showTotalInActionsHeader = true;

    this.missionsList.sortType = TcListSortType.Server;
    this.missionsList.filterType = TcListFilterType.Server;
    this.missionsList.hasFixedHeader = true;

    this.missionsList.hasAddButton = true;
    this.missionsList.addItemWhenKeyPresed = true;

    this.missionsList.hasActionsLabel = false;
    this.missionsList.isPaged = false;

    this.missionsList.onScrollDown = () => {
      this.missionsList.service.getAll();
    };

    this.missionsList.filters = [
      {
        key: TcFilterTypes.anyFieldContains,
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.anyFieldContains,
        label: `${this.listId}.filter-label`,
        options: {
          minLength: 1,
          debounceTime: 500,
        },
      },
      {
        key: 'project.name',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.multiselect,
        options: {
          filter: (term) =>
            this.qlAutocompleteService.getAutocompleteValues<Project>(
              EntitiesEnum.Projects,
              term,
              'name',
              'name'
            ),
          label: `${this.listId}.filter.multiselect.project`,
          removable: true,
        },
      },
      {
        key: 'responsibleCollaboratorId',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.multiselect,
        options: {
          filter: (term) =>
            this.qlAutocompleteService.getAutocompleteValues<Collaborator>(
              EntitiesEnum.Collaborators,
              term,
              'id',
              'trigram',
              null,
              false,
              [{ property: 'expirationDate', filterType: 'IsNull', value: '1' }]
            ),
          label: `${this.listId}.filter.multiselect.responsible`,
          removable: true,
        },
      },
      {
        key: 'targetDate',
        type: TcFilterTypes.daterange,
        label: `${this.listId}.filter.targetDate`,
        hidden: false,
        options: {
          hasReset: true,
        },
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.notStarted`,
          onLabel: ``,
          default: false,
          onValue: [MissionDbStatus.NotStarted],
        },
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.inProgress`,
          onLabel: ``,
          default: true,
          onValue: [MissionDbStatus.InProgress],
        },
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.closed`,
          onLabel: ``,
          default: false,
          onValue: [MissionDbStatus.Closed],
        },
      },
    ];

    this.missionsList.columns = [
      {
        propertyName: 'project.name',
        visible: true,
      },
      {
        propertyName: 'name',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Html,
      },
      {
        propertyName: 'jiraSprint',
        visible: true,
      },
      {
        propertyName: 'targetDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date,
      },
      {
        propertyName: 'targetInitialWL',
        visible: true,
      },
      {
        propertyName: 'targetInitialDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date,
      },
      {
        propertyName: 'targetReviewedWL',
        visible: true,
      },
      {
        propertyName: 'targetReviewedDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date,
      },
      {
        propertyName: 'changesCounter',
        visible: true,
      },
      {
        propertyName: 'responsible.trigram',
        visible: true,
      },
      {
        propertyName: 'managementFactor',
        visible: false,
      },
      {
        propertyName: 'imputationType',
        visible: false,
        htmlTemplate: this.colImputationTypeTemplate,
      },
      {
        propertyName: 'unit',
        visible: false,
      },
      {
        propertyName: 'quantity',
        visible: false,
      },
      {
        propertyName: 'priceHTPerUnit',
        visible: false,
        displayColumnType: TcListDisplayColumnType.Currency,
      },
      {
        propertyName: 'status',
        visible: true,
        htmlTemplate: this.colStatusTemplate,
      },
      {
        propertyName: 'priority',
        visible: true,
      },
    ];

    this.missionsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true,
      },
      {
        actionName: TcListDefaultAction.Delete,
        icon: 'delete',
        visible: true,
      },
      {
        actionName: TcListDefaultAction.Duplicate,
        icon: 'content_copy',
        visible: true,
      },
      {
        actionName: 'generateImputation',
        icon: 'monetization_on',
        visible: true,
        htmlTemplate: this.rowActionCommImputation,
      },
    ];

    this.missionsList.onRowAction = (row: Mission, actionName: string) => {
      /*const data: Mission = {
        id: row.id,
        projectId: row.projectId,
        jiraSprint: row.jiraSprint,
        name: row.name,
        targetDate: row.targetDate,
        quantity: row.quantity,
        managementFactor: row.managementFactor,
        imputationType: row.imputationType,
        unit: row.unit,
        priceHTPerUnit: row.priceHTPerUnit,
        status: row.status,
        priority: row.priority
      };*/

      if (actionName === 'edit') {
        this.editMission(row);
      } else if (actionName === 'generateImputation') {
        this.addCommercialImputation(row);
      }
    };

    this.missionsList.onRowClick = (row: Mission) => {
      /*const data: Mission = {
        id: row.id,
        projectId: row.projectId,
        jiraSprint: row.jiraSprint,
        name: row.name,
        targetDate: row.targetDate,
        quantity: row.quantity,
        managementFactor: row.managementFactor,
        imputationType: row.imputationType,
        unit: row.unit,
        priceHTPerUnit: row.priceHTPerUnit,
        status: row.status,
        priority: row.priority
      };*/

      this.editMission(row);
    };

    this.missionsList.addItem = () => {
      this.store.dispatch(
        new AddListItem({ entityName: EntitiesEnum.Missions })
      );
    };

    this.missionsList.onDuplicateRow = (row: Mission) => {
      this.store.dispatch(
        new DuplicateListItem({ entityName: EntitiesEnum.Missions, data: row })
      );
    };
  }

  editMission(row: Mission) {
    console.log(row);
    this.store.dispatch(
      new EditListItem({ entityName: EntitiesEnum.Missions, data: row })
    );
  }

  addCommercialImputation(row: Mission) {
    this.store.dispatch(new AddMissionCommercialImputation({ data: row }));
  }

  getImputationTypeLabel(mission: Mission) {
    const result = new SelectValues()
      .missionImputationType(this.translate)
      .find((p) => p.value === mission.imputationType);
    return result ? result.label : '';
  }

  getStatusLabel(mission: Mission) {
    const result = new SelectValues()
      .missionStatus(this.translate)
      .find((p) => p.value === mission.status);
    return result ? result.label : '';
  }

  hasCommercialImputations(row: any) {
    return row.hasCIs && row.hasCIs > 0;
  }

  refresh(initial = false) {
    this.missionsList.refresh(initial);
  }

  ngOnDestroy() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
}
