import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TcConfigService } from '@tc/core';

@Component({
  selector: 'app-force-jira-sync',
  templateUrl: './force-jira-sync.component.html',
  styleUrls: ['./force-jira-sync.component.scss']
})
export class ForceJiraSyncComponent implements OnInit {

  constructor(private configService: TcConfigService, private http: HttpClient) { }

  ngOnInit() {
  }

  forceSync() {
    const subscription = this.http.get<any>((this.configService.config as any).API_BASE_PATH + '/syncJira').subscribe(data => {
      console.log(data);
      subscription.unsubscribe();
    });
  }

}

