import { Component, OnInit } from '@angular/core';
import { TcPage, IPageState } from '@tc/core';
import { Store } from '@ngrx/store';
import { GetActiveCollaborators } from 'src/app/modules/collaborators/store/collaborators.actions';

@Component({
  selector: 'app-tasks-page',
  templateUrl: './tasks-page.component.html',
  styleUrls: ['./tasks-page.component.scss']
})
export class TasksPageComponent extends TcPage implements OnInit {

  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'tasks-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();

    this.store.dispatch(new GetActiveCollaborators());
  }

}
