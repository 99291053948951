import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-comments-popup',
  templateUrl: './comments-popup.component.html',
  styleUrls: ['./comments-popup.component.scss']
})
export class CommentsPopupComponent implements OnInit {

  title = '';
  constructor(
    private dialogRef: MatDialogRef<CommentsPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  onClose() {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (this.dialogData.args?.title) {
      this.title = this.dialogData.args.title;
    }
  }

}

