import { Configuration, BASE_PATH } from "src/generated/api/krakenn-api";
import { Injectable, Optional, Inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { HttpClient } from "@angular/common/http";
import { TcGenericEntity } from "@tc/core";
import { Logout, TcUser, LoginSuccess } from "@tc/auth";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public configuration = new Configuration();
  protected basePath =
    "https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0";

  constructor(
    private store: Store<any>,
    private http: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  logAsUser(data: TcGenericEntity, args?: any) {
    this.store.dispatch(new Logout());

    const url = `${this.configuration.basePath}/log-as-user?id=${data.id}`;

    this.http.get<TcUser>(url).subscribe(
      (response) => {
        const user = response;
        this.store.dispatch(new LoginSuccess({ user }));
      },
      (error) => {
        console.error(error);
      },
      () => { }
    );
  }

  getCookie(name: string) {
    const matches = document.cookie.match(
      new RegExp(
        "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
    /*const splitCookie = document.cookie.split(";");

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < splitCookie.length; i++) {
      const splitEntry = splitCookie[i].split("=");
      const key = splitEntry[0].trim();
      console.log("Before encoding " + splitEntry[1]);
      const value = encodeURIComponent(splitEntry[1].trim());

      console.log("Comparison " + i + " {" + key + "}:{" + name + "}");

      if (key === name.trim()) {
        console.log("Cookie: " + splitEntry);
        console.log("Token: " + value);
        return value;
      }
    }

    return null;*/
  }

  skipLogin(skipLoginEmail: string) {
    const encodedEmail = this.encodeEmail(skipLoginEmail);

    const url = `${this.configuration.basePath}/login?skipLogin=${encodedEmail}`;

    this.http.post<TcUser>(url, {}).subscribe(
      (response) => {
        const user = response;
        this.store.dispatch(new LoginSuccess({ user }));
      },
      (error) => {
        console.error(error);
      },
      () => { }
    );
  }

  encodeEmail(email: string): string {
    return btoa(email);
  }
}
