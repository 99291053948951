import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActivitysState } from './activitys.state';

const getActivitysState = createFeatureSelector<ActivitysState>(
    'activitys'
);

export const getActivitysForLead = createSelector(
    getActivitysState,
    (state: ActivitysState) => state.activitysList
);

