import { Payment } from '../api/krakenn-api';

export class PaymentType {
  static className = 'Payment';
  static gqlRootQueryName = 'payment';
  static gqlColumns: string[] = [
      '_id',
      'date',
      'amount',
      'type',
  ];

  static gqlNodeMapper = (node: any): Payment => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Payment;
    }

}
