import { Observable, Subscriber } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ITcListDataService, TcListFilterModel, TcListConnectionResult, TcListSortModel, TcListPageModel } from '@tc/core';

export class QlListDataService<T> implements ITcListDataService<T> {

  myEntityName = this.typeName;
  entityNameLower = this.typeName.toLowerCase();

  resObserver: Subscriber<any>;
  results$: Observable<any> = new Observable<any>(o => this.resObserver = o);

  gqlColumns: string[] = [];

  constructor(private apollo: Apollo, private typeName: string) {
  }

  columnSelector = (): string => `{ ${this.gqlColumns.join(',')}`;

  resultSelector = (result: any): any => result.data[this.entityNameLower];
  nodeMapper = (node: any): T => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as T;
  }

  getData(filterModel: TcListFilterModel, sortModel: TcListSortModel, paginationModel: TcListPageModel): Promise<TcListConnectionResult<T>> {
    return new Promise<TcListConnectionResult<T>>((resolve, reject) => {

      const variables: any = { first: paginationModel.first };

      if (paginationModel.after) {
        variables.after = paginationModel.after;
      }

      if (sortModel) {
        variables.orderBy = sortModel;
      }

      if (filterModel) {
        variables.filter = filterModel;
      }

      const resultsQuery$ = this.apollo.watchQuery({
        query: this.query(),
        variables
      }).valueChanges;

      const subscription = resultsQuery$.subscribe(value => {
        const connectionResult = this.resultSelector(value);
        connectionResult.edges = (connectionResult.edges as []).map(x => this.nodeMapper((x as any).node));
        // this.resObserver.next(connectionResult);
        // subscription.unsubscribe();
        subscription.unsubscribe();
        resolve(connectionResult);

      });
    });

  }


  query = () => gql`
  query genericEntityQuery($first: Int, $after: String, $orderBy: OrderBy, $filter: ${this.myEntityName}FilterType){
    ${this.entityNameLower} (first: $first, after:$after, orderBy: $orderBy, filter: $filter){
      pageInfo{
        endCursor
      },
      total,
      edges {
        node ${this.columnSelector()}
        }
      }
    }
  }
  `

  getItem(id: string | number): Promise<T> {

    // hack for the case when id contains #
    if (id.toString().includes('#')) {
      // id for list of task is a concatenation between taskId and collaboratorId..we need to keep only taskId
      if (this.myEntityName === 'TasksWithAssign') {
        id = id.toString().split('#')[0];
      }
    }

    return new Promise<T>((resolve, reject) => {
      const resultsQuery$ = this.apollo.watchQuery({
        query: this.query(),
        variables: {
          filter: {
            _id: { filterType: 'Equal', value: id + '' }
          }
        }
      }).valueChanges;

      const subscription = resultsQuery$.subscribe(value => {
        const connectionResult = this.resultSelector(value);
        let result = null;
        if (connectionResult) {
          connectionResult.edges = (connectionResult.edges as []).map(x => this.nodeMapper((x as any).node));
          result = connectionResult.edges[0];
        }
        subscription.unsubscribe();
        resolve(result);
      });
    });
  }
}
