import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TcTranslateService, TcConfirmDialogComponent } from '@tc/core';
import { PermissionService } from 'src/app/services/permission.service';
import { Activity } from 'src/generated/api/krakenn-api';
import { DeleteActivity, MarkActivityAsDone } from '../../../store/activitys.actions';
import { EditActivityPopupComponent } from '../edit-activity-popup/edit-activity-popup.component';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { getActivitysForLead } from '../../../store/activitys.selectors';
import { LoadActivitysList } from '../../../store/activitys.actions';
import { Observable } from 'rxjs';
import { EditContactPopupComponent } from './../../../../contacts/components/smart/edit-contact-popup/edit-contact-popup.component';

@Component({
  selector: 'app-activity-item',
  templateUrl: './activity-item.component.html',
  styleUrls: ['./activity-item.component.scss']
})
export class ActivityItemComponent implements OnInit {

  @Input()
  activity: Activity;

  authUserId: string;

  activitys$: Observable<Activity[]>;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private translate: TcTranslateService,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.authUserId = this.permissionService.authUser.id;
  }

  edit(activity: any) {
    this.dialog.open(EditActivityPopupComponent, {
      data: activity,
      width: '54.5vw'
    }).afterClosed().subscribe((reload) => {
      if (reload){
        this.store.dispatch(new LoadActivitysList({ data: { id: this.activity.leadId, table: 'Lead' } }));
        this.activitys$ = this.store.pipe(select(getActivitysForLead));
      }
    });
  }

  delete(activity: any) {
    const dialog = this.dialog.open(TcConfirmDialogComponent, {
      data: {
        noText: 'no',
        title: 'activitys-list.dialog.title',
        yesText: 'yes',
        message: 'activitys-list.dialog.message',
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.store.dispatch(new DeleteActivity(activity.id));
      }
    });
  }

  markAsDone(activity: any) {
    const dialog = this.dialog.open(TcConfirmDialogComponent, {
      data: {
        noText: 'mark-activity-as-done-dialog.no',
        title: 'mark-activity-as-done-dialog.title',
        yesText: 'mark-activity-as-done-dialog.yes',
        message: 'mark-activity-as-done-dialog.message',
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.store.dispatch(new MarkActivityAsDone(activity.id));
        this.store.dispatch(new LoadActivitysList({ data: { id: this.activity.leadId, table: 'Lead' } }));
        this.activitys$ = this.store.pipe(select(getActivitysForLead));
      }
    });
  }

  getActivityStatus(isDone: boolean) {
    return isDone ? this.translate.instant('done').toUpperCase(): this.translate.instant('todo').toUpperCase();
  }

  getActivityTypeLabel(row: Activity) {
    const result = new SelectValues().activityType(this.translate).find(p => p.value === row.type);
    return result ? result.label : '';
  }

  viewContact(activity: Activity) {
    const data = {
      id: activity.contactId
    };

    this.dialog.open(EditContactPopupComponent, {
      data,
      width: '54.5vw'
    });
  }
}
