import { Holiday } from '../api/krakenn-api';

export class HolidayType {
  static className = 'Holiday';
  static gqlRootQueryName = 'holiday';
  static gqlColumns: string[] = [
      '_id',
      'collaboratorId',
      'label',
      'startDate',
      'stopDate',
      'type',
      'status',
      'comments',
  ];

  static gqlNodeMapper = (node: any): Holiday => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Holiday;
    }

}
