import { Component, OnInit } from '@angular/core';
import { TcConfigService } from '@tc/core';

@Component({
  selector: 'app-download-jira-sync-log',
  templateUrl: './download-jira-sync-log.component.html',
  styleUrls: ['./download-jira-sync-log.component.scss']
})
export class DownloadJiraSyncLogComponent implements OnInit {

  href = '';

  constructor(private configService: TcConfigService) { }

  ngOnInit() {
    this.href = (this.configService.config as any).API_BASE_PATH + '/jiraSync/downloadReport';
  }

}
