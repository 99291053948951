import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { TasksGridComponent } from './components/smart/tasks-grid/tasks-grid.component';

const smartComponents = [TasksGridComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule
  ],
  exports: [
    ...smartComponents
  ]
})
export class TasksGridModule { }
