import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { TcTranslateService, TcFormComponent } from '@tc/core';
import { Activity } from 'src/generated/api/krakenn-api';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoadActivitysList } from '../../../store/activitys.actions';
import { ActivitysTable } from '../../../enums/activitys-table.enum';
import { ActivitysField } from '../../../enums/activitys-field.enum';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from 'src/app/services/permission.service';
import { EditActivityPopupComponent } from '../edit-activity-popup/edit-activity-popup.component';
import { getActivitysForLead } from '../../../store/activitys.selectors';

@Component({
  selector: 'app-activitys-list',
  templateUrl: './activitys-list.component.html',
  styleUrls: ['./activitys-list.component.scss']
})
export class ActivitysListComponent extends TcFormComponent<any> implements OnInit {


  @Input()
  table: string;

  @Input()
  id: number;

  @Input()
  lead: any;

  authUserId: string;

  field: string;


  configuration = [{ table: ActivitysTable.Lead, field: ActivitysField.Lead }];

  activitys$: Observable<Activity[]>;

  constructor(
    translate: TcTranslateService,
    elem: ElementRef,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private store: Store<any>
  ) {

    super(translate, elem);
    this.fields = [
      {
        fieldGroupClassName: "display-row",
        fieldGroup: [
          {
            key: "content",
            type: "textarea-editor",
            className: "textarea-form-content textarea-form-label",
            templateOptions: {},
          },
        ],
      },
    ];
  }

  addActivity() {

    const data = {} as any;
    data[this.field] = this.id;
    data.collaboratorId = this.authUserId;

    if (this.lead) {
      data.lead = this.lead;
    }

    if (this.lead.contact) {
      if (this.lead.contact.firstName !== null && this.lead.contact.firstName !== undefined) {
        const contact = {
          ...this.lead.contact,
          name: this.lead.contact.firstName + ' ' + this.lead.contact.lastName
        }
        data.contact = contact;
      }
    }

    this.dialog.open(EditActivityPopupComponent, {
      data,
      width: '54.5vw'
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.store.dispatch(new LoadActivitysList({ data: { id: this.id, table: this.table } }));
        this.activitys$ = this.store.pipe(select(getActivitysForLead));
      }
    });
  }


  ngOnInit() {

    this.field = this.configuration.find(c => c.table === this.table).field;

    this.authUserId = this.permissionService.authUser.id;

    this.store.dispatch(new LoadActivitysList({ data: { id: this.id, table: this.table } }));
    this.activitys$ = this.store.pipe(select(getActivitysForLead));

  }

}
