import { SelectValues } from './../../../../core/enums/select-values';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { TcTranslateService, TcGenericFormComponent } from '@tc/core';
import { Task, Mission, Collaborator } from 'src/generated/api/krakenn-api';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { getActiveCollaboratorsByTrigram } from 'src/app/modules/collaborators/store/collaborators.selectors';
import { take } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss'],
})
export class TaskDetailComponent
  extends TcGenericFormComponent<Task>
  implements OnInit, AfterViewInit
{
  isReadOnly = false;
  tableName: string;
  missionPriority = 0;

  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    private store: Store<any>,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(
      EntitiesEnum.Tasks,
      entityCollectionServiceFactory,
      translate,
      elem,
      dateAdapter
    );

    console.log((this as any).readOnly);

    this.fields = [
      {
        fieldGroupClassName: 'display-row',
        fieldGroup: [
          {
            key: 'name',
            type: 'textarea',
            templateOptions: {
              required: true,
              autosize: true,
              autosizeMinRows: 5,
              autosizeMaxRows: 5,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.readOnly',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'missionId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) =>
                this.qlAutocompleteService.getAutocompleteValues<Mission>(
                  EntitiesEnum.Missions,
                  term,
                  'id',
                  'name,project{name client{name}},status',
                  (item: any) => {
                    const clientName = item.project.client.name.substring(
                      0,
                      10
                    );
                    const projectName = item.project.name.substring(0, 10);
                    const missionStatus = new SelectValues()
                      .missionStatus(this.translate)
                      .find((p) => p.value === item.status);
                    return `${clientName} / ${projectName} / ${item.name} (${
                      missionStatus?.label ?? ''
                    })`;
                  },
                  false,
                  null
                ),
              listValue: 'mission.name',
            },
            validators: {
              missionId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.readOnly',
            },
          },
          {
            key: 'jiraId',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 50,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.readOnly',
            },
          },
          {
            key: 'type',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 50,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.readOnly',
            },
          },
          {
            key: 'targetPoints',
            type: 'input',
            templateOptions: {
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.readOnly',
            },
          },
          {
            key: 'targetDate',
            type: 'datepicker',
            defaultValue: moment(new Date()).add(7, 'days'),
            templateOptions: {},
            expressionProperties: {
              'templateOptions.disabled': 'model.readOnly',
              'templateOptions.required':
                'model.collaborators && model.collaborators.length > 0',
            },
          },
          {
            key: 'priority',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: true,
              change: this.calcGlobalPriority,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.readOnly',
            },
          },
          {
            key: 'globalPriority',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: false,
              readonly: true,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.readOnly',
            },
          },
          {
            key: 'terminated',
            type: 'checkbox',
            templateOptions: {
              type: 'boolean',
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.readOnly',
            },
          },
          {
            key: 'collaborators',
            type: 'multi-select',
            templateOptions: {
              filter: (term) =>
                this.store.pipe(select(getActiveCollaboratorsByTrigram(term))),
              display: (item) => item.trigram,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.readOnly',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-row',
        fieldGroup: [
          {
            key: 'description',
            type: 'textarea-editor',
            className: 'textarea-form-content textarea-form-label',
          },
        ],
      },
    ];
  }

  async ngOnInit() {
    this.tableName = 'Task';
    super.ngOnInit();
    (this.model as any).collaborators = null;
    (this.model as any).terminated = false;
    if ((this as any)?.readOnly) {
      this.isReadOnly = (this as any).readOnly;
      (this.model as any).readOnly = true;
    }

    if (this.model.targetDate) {
      this.model.targetDate = moment(
        this.dateAdapter.deserialize(this.model.targetDate).format('YYYY-MM-DD')
      ).format('x');
    }
  }

  async ngAfterViewInit() {
    if (this.model.id) {
      const taskService = this.entityCollectionServiceFactory.create(
        EntitiesEnum.Tasks
      );
      const task = (await taskService
        .getByKey(this.model.id)
        .pipe(take(1))
        .toPromise()) as Task;
      this.model = Object.assign({}, task);

      if (this.isReadOnly) {
        (this.model as any).readOnly = true;
      }

      if (task.targetDate) {
        this.model.targetDate = moment(new Date(task.targetDate)).format('x');
      }

      const currentCollaborators = [];
      if (task) {
        ((task as any).collaborators as Collaborator[]).forEach((c) =>
          currentCollaborators.push(c)
        );
      }
      (this.model as any).collaborators = currentCollaborators;

      this.calcGlobalPriority();
    }
  }

  calcGlobalPriority = () => {
    const service = this.entityCollectionServiceFactory.create(
      EntitiesEnum.Missions
    );

    service
      .getByKey(this.model.missionId)
      .toPromise()
      .then((item: Mission) => {
        this.missionPriority = item.priority;

        const globalPriority =
          this.model.priority +
          (!this.missionPriority ? 0 : this.missionPriority) * 1000;
        this.form.get('globalPriority').setValue(globalPriority);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
