import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/components/smart/login/login.component';
import { TestPageComponent } from './pages/test-page/test-page.component';
import { InvoicesPageComponent } from './pages/invoices-page/invoices-page.component';
import { HolidaysPageComponent } from './pages/holidays-page/holidays-page.component';
import { CommercialImputationsPageComponent } from './pages/commercial-imputations-page/commercial-imputations-page.component';
import { TimeImputationsPageComponent } from './pages/time-imputations-page/time-imputations-page.component';
import { TasksPageComponent } from './pages/tasks-page/tasks-page.component';
import { ProjectsPageComponent } from './pages/projects-page/projects-page.component';
import { MissionsPageComponent } from './pages/missions-page/missions-page.component';
import { CollaboratorsPageComponent } from './pages/collaborators-page/collaborators-page.component';
import { ProposalsPageComponent } from './pages/proposals-page/proposals-page.component';
import { OpportunitiesPageComponent } from './pages/opportunities-page/opportunities-page.component';
import { ClientsPageComponent } from './pages/clients-page/clients-page.component';
import { EstimationsPageComponent } from './pages/estimations-page/estimations-page.component';
import { EstimationLinesPageComponent } from './pages/estimation-lines-page/estimation-lines-page.component';
import { AuthGuard } from './services/guards/auth.guard';
import { UserRole } from './modules/core/user-role.enum';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { ReportingPageComponent } from './pages/reporting-page/reporting-page.component';
import { LeadsPageComponent } from './pages/leads-page/leads-page.component';
import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';
import { TasksGridPageComponent } from './pages/tasks-grid-page/tasks-grid-page.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: DashboardPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'estimations',
    component: EstimationsPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'estimation-lines',
    component: EstimationLinesPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'clients',
    component: ClientsPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'opportunities',
    component: OpportunitiesPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'proposals',
    component: ProposalsPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'collaborators',
    component: CollaboratorsPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'missions',
    component: MissionsPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'projects',
    component: ProjectsPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'holidays',
    component: HolidaysPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'leads',
    component: LeadsPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contacts',
    component: ContactsPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'invoices',
    component: InvoicesPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'commercial-imputations',
    component: CommercialImputationsPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'time-imputations',
    component: TimeImputationsPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'tasks',
    component: TasksGridPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'old-tasks',
    component: TasksPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.Admin] }
  },
  {
    path: 'reporting',
    component: ReportingPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'test',
    component: TestPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
exports: [RouterModule]
})
export class AppRoutingModule { }
