import { EntitiesEnum } from './../../../generated/EntitiesEnum';
import { ToggleFilterModel } from './../../modules/core/models/toggle-filter.model';
import { Component, OnInit } from '@angular/core';
import { TcPage, IPageState } from '@tc/core';
import { InvoiceDbStatus } from 'src/app/modules/core/enums/invoice.db-status.enum';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-invoices-page',
  templateUrl: './invoices-page.component.html',
  styleUrls: ['./invoices-page.component.scss']
})
export class InvoicesPageComponent extends TcPage implements OnInit {

  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'invoices-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }

}
