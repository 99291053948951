import { Component, ElementRef } from '@angular/core';
import { TcTranslateService, TcGenericFormComponent } from '@tc/core';
import { Client } from 'src/generated/api/krakenn-api';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent extends TcGenericFormComponent<Client> {

  constructor(
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {

    super(EntitiesEnum.Clients, entityCollectionServiceFactory, translate, elem, dateAdapter);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'shortName',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 50
            },
          },
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 255
            }
          },
          {
            key: 'address1',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 255
            }
          },
          {
            key: 'address2',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: false,
              maxLength: 255
            }
          },
          {
            key: 'postalCode',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 50
            }
          },
          {
            key: 'city',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 50
            }
          },
          {
            key: 'country',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 50
            }
          },
          {
            key: 'incorporationNumber',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: false,
              maxLength: 50
            }
          },
          {
            key: 'vatPercentage',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: true
            }
          },
          {
            key: 'payementDays',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: true
            }
          },
          {
            key: 'vatText',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: false,
              maxLength: 255
            }
          },
          {
            key: 'invoiceContact',
            type: 'textarea',
            templateOptions: {
            }
          }
        ]
      },
      {
        key: 'invoiceEmailList',
        type: 'textarea',
        templateOptions: {
        }
      },
      {
        key: 'invoiceEmailText',
        type: 'textarea',
        templateOptions: {
          autosize: true,
          autosizeMinRows: 8,
          autosizeMaxRows: 8,
        }
      }
    ];

  }

}
