import { Task } from '../api/krakenn-api';

export class TaskType {
  static className = 'Task';
  static gqlRootQueryName = 'task';
  static gqlColumns: string[] = [
      '_id',
      'missionId',
      'jiraId',
      'type',
      'name',
      'targetPoints',
      'targetDate',
      'priority',
      'description',
      'terminated',
  ];

  static gqlNodeMapper = (node: any): Task => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Task;
    }

}
