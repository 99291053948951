import { JiraUrlService } from './../../../../../services/jira-url.service';
import { GetCollaboratorsWorkload } from './../../../../collaborators/store/collaborators.actions';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FilterTypesEnum,
  TcFilterTypes,
  TcGridCellComponent,
  TcGridDataSource,
  TcGridDataSourceSort,
  TcSmartComponent,
  TcTranslateService,
  TcValidationsService,
  TcNotificationService,
  TcGridActionButtonsPositions,
  TcConfirmDialogComponent,
} from '@tc/core';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
} from 'ag-grid-community';
import * as moment from 'moment';
import { Task } from './../../../../../../generated/api/krakenn-api/model/task';
import { TasksGridService } from './../../../../../services/tasks-grid.service';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { Mission, Project } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import { TaskDbTerminated } from 'src/app/modules/core/enums/task-db-terminated.enum';
import { Collaborator } from 'src/generated/api/krakenn-api/model/collaborator';
import { Client } from 'src/generated/api/krakenn-api/model/client';
import { select, Store } from '@ngrx/store';
import { CommentsTable } from 'src/app/modules/comments/enums/comments-table.enum';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { getActiveCollaboratorsByTrigram } from 'src/app/modules/collaborators/store/collaborators.selectors';
import { TaskDetailComponent } from 'src/app/modules/tasks/components/smart/task-detail/task-detail.component';
import { CreateEditPopupComponent } from 'src/app/modules/core/components/smart/create-edit-popup/create-edit-popup.component';
import { CommentsPopupComponent } from 'src/app/modules/comments/smart/components/comments-popup/comments-popup.component';
import {
  decimalMarker,
  decimalMask,
  formatToPrecision,
} from 'src/app/shared/number-utils';
import { Subscription } from 'rxjs';
import {
  ListRefreshService,
  ListRefreshType,
} from './../../../../../services/list-refresh.service';
import { skipWhile } from 'rxjs/operators';
import { TargetDateService } from 'src/app/services/target-date.service';

@Component({
  selector: 'app-tasks-grid',
  templateUrl: './tasks-grid.component.html',
  styleUrls: ['./tasks-grid.component.scss'],
})
export class TasksGridComponent
  extends TcSmartComponent
  implements OnDestroy, OnInit
{
  public autoFocus: { should: boolean; rowIndex: number; colKey: string } = {
    should: false,
    rowIndex: null,
    colKey: null,
  };
  public workloadStart: any;
  public workloadEnd: any;

  private refreshSubscription: Subscription;
  autoFocusDeleteDialog = false;
  deleteYesFocused = true;
  listName = 'tasks-list';

  public readonly paginationLimit = 100;

  public dataSource: TcGridDataSource<Task>;
  public currentFilters = {};

  public readonly gridOptions: GridOptions;

  public totalItems = 0;
  public loadedItems = 0;
  public enableMassUpdate = true;

  public massUpdateCapabilityActivated = true;
  public hasRowActions = true;
  public rowActionsButtonPosition = TcGridActionButtonsPositions.BeforeData;
  public rowActionButtons = [
    {
      icon: 'edit',
      action: async (node) => {
        this.editTask(node);
      },
    },
    {
      icon: 'comment',
      conditionalClass: 'hasComments',
      showConditionalClass: (node) => (node?.nbOfComments === 0 ? false : true),
      action: async (node) => {
        this.showComments(node);
      },
    },
    {
      icon: 'content_copy',
      action: async (node) => {
        this.duplicateRow(node);
      },
    },
    {
      icon: 'delete',
      hidden: (node) =>
        node?.nbOfComments === 0 && node?.nbOfImputations === 0 ? false : true,
      action: async (node) => {
        this.deleteTask(node);
      },
    },
  ];

  public massUpdateTemplateOptions = [
    {
      field: 'missionName',
      templateOptions: {
        required: true,
        filter: (term) =>
          this.qlAutocompleteService.getAutocompleteValues<Mission>(
            EntitiesEnum.Missions,
            term,
            'id',
            'name,project{name client{name}}',
            (item: any) => {
              const clientName = item.project.client.name.substring(0, 10);
              const projectName = item.project.name.substring(0, 10);
              return `${clientName} / ${projectName} / ${item.name}`;
            },
            false,
            [{ property: 'status', filterType: 'In', value: '1' }]
          ),
      },
    },
    {
      field: 'collaboratorsTrigram',
      templateOptions: {
        filter: (term) =>
          this.store.pipe(select(getActiveCollaboratorsByTrigram(term))),
        display: (item) => item.trigram,
      },
    },
  ];

  public MissionsList: any[];
  public CollaboratorsList: any[];

  @ViewChild('colClientNameTemplate', { static: true })
  colClientNameTemplate: TemplateRef<any>;
  @ViewChild('colProjectNameTemplate', { static: true })
  colProjectNameTemplate: TemplateRef<any>;
  @ViewChild('colTerminatedTemplate', { static: true })
  colTerminatedTemplate: TemplateRef<any>;
  @ViewChild('colMissionNameTemplate', { static: true })
  colMissionNameTemplate: TemplateRef<any>;
  @ViewChild('rowActionDeleteTemplate', { static: true })
  rowActionDeleteTemplate: TemplateRef<any>;
  @ViewChild('rowActionCommentTemplate', { static: true })
  rowActionCommentTemplate: TemplateRef<any>;

  public columnsDefinitions: ColDef[];

  format = 'YYYY-MM-DD';
  dateString = moment(new Date()).format(this.format);
  day = moment(this.dateString, this.format).day();

  public filters = [
    {
      key: TcFilterTypes.anyFieldContains,
      filterType: FilterTypesEnum.Equal,
      type: TcFilterTypes.anyFieldContains,
      label: `filter-label`,
      options: {
        minLength: 1,
        debounceTime: 500,
      },
    },
    {
      key: 'terminated',
      filterType: FilterTypesEnum.In,
      type: TcFilterTypes.toggle,
      label: '',
      options: {
        offLabel: `notTerminated`,
        onLabel: ``,
        default: true,
        onValue: [TaskDbTerminated.NotTerminated],
      },
    },
    {
      key: 'terminated',
      filterType: FilterTypesEnum.In,
      type: TcFilterTypes.toggle,
      label: '',
      options: {
        offLabel: `terminated`,
        onLabel: ``,
        default: false,
        onValue: [TaskDbTerminated.Terminated],
      },
    },
    {
      key: 'targetDate',
      filterType: FilterTypesEnum.DateRange,
      type: TcFilterTypes.daterange,
      label: `targetDate`,
      hidden: false,
      options: {
        format: 'DD/MM/YYYY',
        default: this.targetDateService.getDefaultDate(),
        hasReset: true,
      },
    },
    {
      key: 'collaborator.trigram',
      filterType: 'Includes' as FilterTypesEnum,
      type: TcFilterTypes.multiselect,
      options: {
        filter: (term) =>
          this.qlAutocompleteService.getAutocompleteValues<Collaborator>(
            EntitiesEnum.Collaborators,
            term,
            'trigram',
            'trigram',
            null,
            false,
            [{ property: 'expirationDate', filterType: 'IsNull', value: '1' }]
          ),
        label: `collaboratorsTrigram`,
        removable: true,
        autoSelectIfOnlyOneResult: true,
      },
    },
    {
      key: 'project.name',
      filterType: FilterTypesEnum.In,
      type: TcFilterTypes.multiselect,
      options: {
        filter: (term) =>
          this.qlAutocompleteService.getAutocompleteValues<Project>(
            EntitiesEnum.Projects,
            term,
            'name',
            'name'
          ),
        label: `projectId`,
        removable: true,
      },
    },
    {
      key: 'mission.name',
      filterType: FilterTypesEnum.In,
      type: TcFilterTypes.multiselect,
      options: {
        filter: (term) =>
          this.qlAutocompleteService.getAutocompleteValues<Mission>(
            EntitiesEnum.Missions,
            term,
            'name',
            'name,project{name client{name}}',
            (item: any) => {
              const clientName = item.project.client.name.substring(0, 10);
              const projectName = item.project.name.substring(0, 10);
              return `${clientName} / ${projectName} / ${item.name}`;
            },
            false,
            [{ property: 'status', filterType: 'In', value: '1' }]
          ),
        label: `missionId`,
        removable: true,
      },
    },
    {
      key: 'notAssignedTasksOnly',
      filterType: FilterTypesEnum.Equal,
      type: TcFilterTypes.toggle,
      label: '',
      options: {
        offLabel: `notAssignedTasksOnly`,
        onLabel: ``,
        default: false,
        onValue: true,
      },
    },
  ];

  private gridApi: GridApi;
  private gridColumnApi;

  constructor(
    private store: Store<any>,
    private qlAutocompleteService: QlAutcompleteService,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
    private translate: TcTranslateService,
    private dialog: MatDialog,
    private tasksGridService: TasksGridService,
    private notificationService: TcNotificationService,
    private readonly tcValidationsService: TcValidationsService,
    private listRefreshService: ListRefreshService,
    private readonly targetDateService: TargetDateService,
    private jiraUrl: JiraUrlService
  ) {
    super();
    this.gridOptions = {
      rowModelType: 'infinite',
      paginationPageSize: this.paginationLimit,
      infiniteInitialRowCount: 500,
      rowSelection: 'multiple',
      rowHeight: 100,
      headerHeight: 100,
      suppressRowClickSelection: true,
      defaultColDef: {
        pinnedRowCellRenderer: TcGridCellComponent.PinnedCellRenderer,
        sortingOrder: ['asc', 'desc'],
        sortable: true,
        pinnedRowCellRendererParams: {
          nbOfDecimals: 2,
        },
      },
    };

    this.getAllMissions();
    this.getAllCollaborators();
  }

  ngOnInit(): void {
    this.refreshSubscription = this.listRefreshService.subject
      .pipe(skipWhile((type) => type !== ListRefreshType.Tasks))
      .subscribe(() => this.loadAllRows());

    this.columnsDefinitions = [
      {
        field: 'id',
        editable: false,
        resizable: true,
        suppressAutoSize: true,
        minWidth: 80,
        maxWidth: 80,
        cellEditor: TcGridCellComponent.MatInputEditor,
        headerValueGetter: () => this.translate.instant('id'),
        headerTooltip: this.translate.instant('id'),
        sortable: true,
      },
      {
        field: 'projectClient',
        cellStyle: { 'overflow-wrap': 'anywhere' },
        resizable: true,
        suppressAutoSize: false,
        minWidth: 130,
        maxWidth: 150,
        editable: false,
        headerValueGetter: () => this.translate.instant('projectClient.abbrev'),
        headerTooltip: this.translate.instant('projectClient'),
        sortable: true,
      },
      {
        field: 'missionName',
        resizable: true,
        suppressAutoSize: false,
        editable: true,
        sortable: true,
        minWidth: 130,
        headerValueGetter: () => this.translate.instant('mission'),
        headerTooltip: this.translate.instant('mission'),
        cellEditor: TcGridCellComponent.AutoCompleteEditor,
        cellEditorParams: {
          filter: (query: string) => {
            if (!query || query?.toString().toLowerCase() === '') {
              return this.MissionsList;
            }

            const normalized = query?.toString().toLowerCase();
            return this.MissionsList.filter(
              ({ value, label }) =>
                value?.toString()?.toLowerCase().indexOf(normalized) !== -1 ||
                label?.toLowerCase().indexOf(normalized) !== -1
            );
          },
        },
        onCellValueChanged: (params) => this.onMissionValueChange(params),
      },
      {
        field: 'openJiraTicket',
        headerValueGetter: () => '',
        cellRenderer: TcGridCellComponent.ButtonsRenderer,
        cellRendererParams: {
          buttons: [
            {
              icon: 'launch',
              tooltip: '',
              action: (node) => {
                if (node?.jiraId) {
                  const jiraUrl = this.jiraUrl.getJiraUrl(node.jiraId);
                  window.open(jiraUrl, '_blank').focus();
                }
              },
              hidden: (node) => !node?.jiraId,
            },
          ],
        },
        cellClass: 'open-jira-ticket',
        maxWidth: 40,
      },
      {
        field: 'jiraId',
        headerValueGetter: () => this.translate.instant('jiraId'),
        headerTooltip: this.translate.instant('jiraId'),
        resizable: true,
        suppressAutoSize: false,
        maxWidth: 90,
        editable: true,
        cellEditor: TcGridCellComponent.MatInputEditor,
        sortable: true,
        cellClass: 'jiraId',
      },
      {
        field: 'collaboratorsTrigram',
        headerValueGetter: () =>
          this.translate.instant('collaboratorsTrigram.abbrev'),
        editable: true,
        resizable: true,
        suppressAutoSize: false,
        headerTooltip: this.translate.instant('collaboratorsTrigram'),
        maxWidth: 130,
        cellEditor: TcGridCellComponent.MultiSelectEditor,
        cellEditorParams: {
          filter: (query: string) => {
            // Mock implementation of filtering values
            if (!query) {
              return this.CollaboratorsList;
            }

            const normalized = query?.toString().toLowerCase();
            return this.CollaboratorsList.filter(
              ({ value, label }) =>
                value?.toString()?.toLowerCase().indexOf(normalized) !== -1 ||
                label?.toLowerCase().indexOf(normalized) !== -1
            );
          },
          options: {
            autoSelectIfOnlyOneResult: true,
          },
        },
        sortable: true,
        onCellValueChanged: (params) => this.onCollaboratorsChange(params),
      },
      {
        field: 'name',
        headerValueGetter: () => this.translate.instant('task'),
        headerTooltip: this.translate.instant('task'),
        editable: true,
        cellStyle: { 'white-space': 'normal' },
        minWidth: 180,
        cellRenderer: TcGridCellComponent.HtmlRenderer,
        cellEditor: TcGridCellComponent.MatTextAreaEditor,
        cellEditorParams: {
          rows: 5,
        },
        resizable: true,
        suppressAutoSize: false,
        sortable: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        field: 'targetPoints',
        maxWidth: 100,
        minWidth: 70,
        headerValueGetter: () => this.translate.instant('targetPoints.abbrev'),
        headerTooltip: this.translate.instant('targetPoints'),
        editable: true,
        cellEditor: TcGridCellComponent.MatInputEditor,
        cellEditorParams: {
          mask: decimalMask(),
          decimalMarker: decimalMarker(),
        },
        resizable: true,
        suppressAutoSize: false,
        sortable: true,
      },
      {
        field: 'sumTimeImputation',
        maxWidth: 100,
        minWidth: 70,
        headerValueGetter: () =>
          this.translate.instant('sumTimeImputation.abbrev'),
        headerTooltip: this.translate.instant('sumTimeImputation'),
        editable: false,
        resizable: true,
        suppressAutoSize: false,
        sortable: false,
        valueFormatter: (params) => params.data?.sumTimeImputation?.toFixed(2),
      },
      {
        field: 'targetPointsPerCollaborator',
        maxWidth: 100,
        minWidth: 70,
        headerValueGetter: () =>
          this.translate.instant('targetPointsPerCollaborator.abbrev'),
        headerTooltip: this.translate.instant('targetPointsPerCollaborator'),
        editable: false,
        resizable: true,
        suppressAutoSize: false,
        sortable: true,
        valueFormatter: (params) =>
          params.data?.targetPointsPerCollaborator?.toFixed(2),
      },
      {
        field: 'consumedPerCollaborator',
        maxWidth: 100,
        minWidth: 70,
        headerValueGetter: () =>
          this.translate.instant('consumedPerCollaborator.abbrev'),
        headerTooltip: this.translate.instant('consumedPerCollaborator'),
        editable: false,
        resizable: true,
        suppressAutoSize: false,
        sortable: true,
        valueFormatter: (params) =>
          params.data?.consumedPerCollaborator?.toFixed(2),
      },
      {
        field: 'targetDate',
        headerValueGetter: () => this.translate.instant('targetDate.abbrev'),
        headerTooltip: this.translate.instant('targetDate'),
        maxWidth: 160,
        valueFormatter: (params) =>
          params?.value ? moment(params?.value).format('DD/MM/YYYY') : '',
        editable: true,
        resizable: true,
        suppressAutoSize: false,
        cellEditor: TcGridCellComponent.DatePickerEditor,
        sortable: true,
      },
      {
        field: 'priority',
        maxWidth: 75,
        minWidth: 70,
        resizable: true,
        headerValueGetter: () => this.translate.instant('priority.abbrev'),
        headerTooltip: this.translate.instant('priority'),
        editable: true,
        cellEditor: TcGridCellComponent.MatInputEditor,
        sortable: true,
        suppressAutoSize: false,
      },
      {
        field: 'globalPriority',
        maxWidth: 90,
        minWidth: 85,
        resizable: true,
        headerValueGetter: () =>
          this.translate.instant('globalPriority.abbrev'),
        headerTooltip: this.translate.instant('globalPriority'),
        editable: false,
        cellEditor: TcGridCellComponent.MatInputEditor,
        sortable: true,
        suppressAutoSize: false,
      },
      {
        field: 'terminated',
        resizable: true,
        suppressAutoSize: true,
        maxWidth: 45,
        minWidth: 45,
        headerValueGetter: () => this.translate.instant('terminated.abbrev'),
        headerTooltip: this.translate.instant('terminated'),
        editable: true,
        cellRenderer: TcGridCellComponent.CheckboxRenderer,
        cellEditor: TcGridCellComponent.CheckboxEditor,
        sortable: true,
      },
    ];

    this.applyFilter(this.filters);

    this.dataSource = async (
      page: number,
      limit: number,
      sort: TcGridDataSourceSort
    ) => {
      const response = await this.tasksGridService.getTasks(
        page,
        limit,
        this.currentFilters,
        sort
      );

      setTimeout(() => {
        this.updatePagination();
        this.columnsDefinitions = [...this.gridApi.getColumnDefs()];
        this.gridApi.setPinnedBottomRowData(
          this.getTotalsRow(response?.totals)
        );
      });

      return response;
    };
  }

  autoSizeAll(skipHeader) {
    this.gridColumnApi.setColumnWidth('rowActions', 140);
    this.gridApi.sizeColumnsToFit();
  }

  public applyFilter(filters) {
    this.currentFilters = { ...filters };

    const notAssignedTasksOnlyFilterKey = 'notAssignedTasksOnly';
    const notAssignedTasksOnlyFilter = filters[notAssignedTasksOnlyFilterKey];
    if (
      notAssignedTasksOnlyFilter &&
      notAssignedTasksOnlyFilter.value[0] === true
    ) {
      const collaboratorTrigramFilterKey = 'collaborator.trigram';
      if (this.currentFilters[collaboratorTrigramFilterKey]) {
        delete this.currentFilters[collaboratorTrigramFilterKey];
      }
    }

    this.gridApi?.onFilterChanged();

    if (filters.hasOwnProperty('targetDate')) {
      const dateFormat = 'YYYY-MM-DD';

      const targetDate = filters.targetDate;
      const startDate = moment(targetDate.value.start).format(dateFormat);
      const endDate = moment(targetDate.value.end).format(dateFormat);

      this.workloadStart = startDate;
      this.workloadEnd = endDate;

      this.store.dispatch(new GetCollaboratorsWorkload({ startDate, endDate }));
    } else {
      this.store.dispatch(
        new GetCollaboratorsWorkload({ startDate: '', endDate: '' })
      );
    }
  }

  public updatePagination() {
    const loadedBlocks = Object.values<any>(
      this.gridApi.getCacheBlockState() ?? {}
    ).filter((block) => block.pageStatus === 'loaded')?.length;

    this.totalItems = this.gridApi.getInfiniteRowCount();
    this.loadedItems = Math.min(
      loadedBlocks * this.paginationLimit,
      this.totalItems
    );
    this.autoSizeAll(false);
  }

  public onReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;

    this.autoSizeAll(false);
  }

  public onMassUpdate(event) {
    const noRowsUpdated = event.updatedRows.length;

    const updatedRows = event.updatedRows;

    updatedRows.forEach((row) => {
      this.updateRowNode(row);
    });

    this.notificationService.success(
      noRowsUpdated + this.translate.instant('task-grid.dialog.success')
    );
  }

  getAllMissions = () => {
    const service = this.entityCollectionServiceFactory.create(
      EntitiesEnum.Missions
    );

    service
      .getAll()
      .toPromise()
      .then((missions: any[]) => {
        this.MissionsList = missions.map((mission) => {
          const clientName = mission.project.client.name.substring(0, 10);
          const projectName = mission.project.name.substring(0, 10);
          const label = `${clientName} / ${projectName} / ${mission.name}`;

          return { value: mission.id, label };
        });
      })
      .catch((err) => {
        console.log(err);
        this.MissionsList = [];
      });
  };

  getAllCollaborators = () => {
    const service = this.entityCollectionServiceFactory.create(
      EntitiesEnum.Collaborators
    );

    service
      .getAll()
      .toPromise()
      .then((collaborators: any[]) => {
        this.CollaboratorsList = collaborators
          .filter((c) => c.trigram)
          .map((collaborator) => {
            return {
              value: collaborator.trigram,
              label: collaborator.trigram,
              ...collaborator,
            };
          });
      })
      .catch((err) => {
        console.log(err);
        this.CollaboratorsList = [];
      });
  };

  public updateRowNode(data) {
    const task = data;

    this.tasksGridService.edit(task).subscribe((result) => {});
  }

  public updateCell(node) {
    if (node.value === node.oldValue) {
      return;
    }

    if (
      node.column.getColId() !== this.gridApi.getFocusedCell().column.getColId()
    ) {
      this.autoFocus = {
        should: true,
        rowIndex: node.rowIndex,
        colKey: this.gridApi.getFocusedCell().column.getColId(),
      };
    }

    const task = node?.data;

    task.targetDate = moment(task.targetDate).format('YYYY-MM-DD');

    if (node.column.getColId() === 'missionName') {
      task.missionId = node?.data.missionId;
    }

    if (!task?.id) {
      delete (task as any).id;
      this.tasksGridService.save(task).subscribe(
        (result) => {},
        (error) => {
          this.notificationService.error(error.Message);
        }
      );
    } else {
      this.tasksGridService.edit(task).subscribe(
        (result) => {},
        (error) => {
          this.notificationService.error(error.Message);
        }
      );
    }
  }

  public addRow() {
    const entityName = EntitiesEnum.Tasks;

    this.dialog
      .open(CreateEditPopupComponent, {
        panelClass: [`${entityName}-detail`.toLowerCase(), 'add-edit-popup'],
        autoFocus: true,
        data: {
          component: TaskDetailComponent,
          entityName,
        },
      })
      .afterClosed()
      .subscribe((model) => {
        this.loadAllRows();
      });
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  duplicateRow = (row) => {
    const data = {
      ...row,
      mission: {
        id: row.missionId,
        name: row.missionName,
      },
      globalPriority: row.globalPriority,
    };

    const clonedData: any = { ...data, id: null };

    if (clonedData.hasOwnProperty('name')) {
      // tslint:disable-next-line
      clonedData.name = `${this.translate.instant(
        'tc-duplicate-list-message.duplicate_of'
      )} ${clonedData.name}`;
    }

    const entityName = EntitiesEnum.Tasks;

    const targetDate = new Date(clonedData.targetDate);
    clonedData.targetDate = targetDate;

    this.dialog
      .open(CreateEditPopupComponent, {
        panelClass: [`${entityName}-detail`.toLowerCase(), 'add-edit-popup'],
        autoFocus: true,
        data: {
          data: clonedData,
          component: TaskDetailComponent,
          entityName,
        },
      })
      .afterClosed()
      .subscribe((model) => {
        this.loadAllRows();
      });
  };

  editTask = (row) => {
    const data = {
      ...row,
      mission: {
        id: row.missionId,
        name: row.missionName,
      },
      globalPriority: row.globalPriority,
    };

    const entityName = EntitiesEnum.Tasks;

    this.dialog
      .open(CreateEditPopupComponent, {
        panelClass: [`${entityName}-detail`.toLowerCase(), 'add-edit-popup'],
        autoFocus: true,
        data: {
          data,
          component: TaskDetailComponent,
          entityName,
        },
      })
      .afterClosed()
      .subscribe((model) => {
        if (model) {
          this.loadAllRows();
        }
      });
  };

  deleteTask = (row: Task) => {
    const dialog = this.dialog.open(TcConfirmDialogComponent, {
      data: {
        title: this.listName + '.dialog.delete.title',
        message: this.listName + '.dialog.delete.message',
        noText: this.listName + '.dialog.delete.no',
        yesText: this.listName + '.dialog.delete.yes',
        yesFocused: this.deleteYesFocused,
      },
      autoFocus: this.autoFocusDeleteDialog,
      panelClass: this.listName + '-delete-dialog-panel',
    });
    dialog.afterClosed().subscribe(
      (result) => {
        if (result === 'yes') {
          this.tasksGridService
            .delete(row.id)
            .subscribe((data) => this.afterDeleteSuccess(row));
        }
      },
      (error) => {
        this.notificationService.error(
          this.translate.instant('tasks-grid.delete.error')
        );
      }
    );
  };

  showComments(row: Task) {
    const data = {
      table: CommentsTable.Task,
      id: row.id,
      args: { title: 'comments-list.tasks.title' },
    };

    this.dialog
      .open(CommentsPopupComponent, {
        data,
        width: '60vw',
      })
      .afterClosed()
      .subscribe((result) => {
        this.loadAllRows();
      });
  }

  onMissionValueChange(params: any) {
    console.log(params.newValue);
    console.log(isNaN(params.newValue));

    if (params.oldValue === params.newValue || isNaN(params.newValue)) return;

    const node: RowNode = params.node;

    const value = params.newValue;

    const service = this.entityCollectionServiceFactory.create(
      EntitiesEnum.Missions
    );

    service
      .getByKey(value)
      .toPromise()
      .then((item: any) => {
        const missionPriority = item.priority;
        const taskPriority = node.data.priority;

        // Calculate row global priority
        const globalPriority =
          taskPriority + (!missionPriority ? 0 : missionPriority) * 1000;

        // Set dependant columns
        node.setDataValue('globalPriority', globalPriority);
        node.setDataValue('missionName', item.name);

        if (item.hasOwnProperty('project')) {
          const projectClient =
            item?.project?.name + '/' + item?.project?.client?.shortName;

          node.setDataValue('projectClient', projectClient);
        }

        node.data.missionId = value;

        this.updateRowNode(node?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onCollaboratorsChange(params) {
    console.log(params.newValue);

    if (params.oldValue === params.newValue || !params.newValue[0].id) return;

    const rowNode: RowNode = params.node;

    const value = params.newValue;

    const collaborators: any[] = value;

    rowNode.data.collaborators = collaborators;
    const displayCollaborators = collaborators.map((collaborator) => {
      return collaborator.trigram;
    });

    rowNode.setDataValue('collaboratorsTrigram', displayCollaborators);

    this.updateRowNode(rowNode?.data);
  }

  private async loadAllRows() {
    this.dataSource = async (
      page: number,
      limit: number,
      sort: TcGridDataSourceSort
    ) => {
      const response = await this.tasksGridService.getTasks(
        page,
        limit,
        this.currentFilters,
        sort
      );

      setTimeout(() => {
        this.updatePagination();
        this.gridApi.setPinnedBottomRowData(
          this.getTotalsRow(response?.totals)
        );
      });

      return response;
    };
  }

  getTotalsRow(totals: any): any[] {
    let targetPoints = 0;
    let sumTimeImputation = 0;
    let targetPointsPerCollaborator = 0;
    let consumedPerCollaborator = 0;

    if (totals) {
      targetPoints = totals.targetPoints;
      sumTimeImputation = formatToPrecision(totals.sumTimeImputation, 2);
      targetPointsPerCollaborator = totals.targetPointsPerCollaborator;
      consumedPerCollaborator = totals.consumedPerCollaborator;
    }

    return [
      {
        name: 'TOTAL',
        targetPoints,
        sumTimeImputation,
        targetPointsPerCollaborator,
        consumedPerCollaborator,
      },
    ];
  }

  afterDeleteSuccess(row: Task): void {
    this.notificationService.success(
      this.translate.instant('tasks-grid.delete.success')
    );
    this.loadAllRows();
  }

  public refreshWorkload() {
    const startDate = this.workloadStart;
    const endDate = this.workloadEnd;

    if (startDate && endDate) {
      this.store.dispatch(new GetCollaboratorsWorkload({ startDate, endDate }));
    } else {
      this.store.dispatch(
        new GetCollaboratorsWorkload({ startDate: '', endDate: '' })
      );
    }
  }
}
