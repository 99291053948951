import { Subscription } from 'rxjs';
import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { OpenAddTasksMassDialog } from '../../../store/tasks.actions';
import { TranslateService } from '@ngx-translate/core';
import { JQueryService } from 'src/app/services/jQuery.service';

@Component({
  selector: 'app-add-tasks-mass',
  templateUrl: './add-tasks-mass.component.html',
  styleUrls: ['./add-tasks-mass.component.scss']
})
export class AddTasksMassComponent implements OnInit, OnDestroy, AfterViewInit {

  addTaskMassTooltip = ``;

  langSubscription: Subscription;

  constructor(
    private store: Store<any>,
    private translate: TranslateService,
    private jQuery: JQueryService,
    private cd: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.langSubscription = this.translate.onLangChange.subscribe(() => {
      this.setTooltip();
    })
  }

  ngOnDestroy(): void {
    this.langSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.setTooltip();
    this.cd.detectChanges();
  }

  addMassTasks() {
    this.store.dispatch(new OpenAddTasksMassDialog())
  }

  setTooltip() {

    this.addTaskMassTooltip = `
    <ul>
      <li>
        <strong>${this.translate.instant('one-column-title')}:</strong>
        ${this.translate.instant('one-column-message')}
      </li>
      <li>
        <strong>${this.translate.instant('two-column-title')}:</strong>
        ${this.translate.instant('two-column-message')}
      </li>
      <li>
        <strong>${this.translate.instant('three-column-title')}:</strong>
        ${this.translate.instant('three-column-message')}
      </li>
      <li>
        <strong>${this.translate.instant('four-column-title')}:</strong>
        ${this.translate.instant('four-column-message')}
      </li>
    </ul>`;

    this.jQuery.setAddTasksMassButtonTooltip();
  }
}
