import { Param } from '../api/krakenn-api';

export class ParamType {
  static className = 'Param';
  static gqlRootQueryName = 'param';
  static gqlColumns: string[] = [
      '_id',
      'name',
      'value',
      'validityStart',
      'validityEnd',
  ];

  static gqlNodeMapper = (node: any): Param => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Param;
    }

}
