import { Component, OnInit } from '@angular/core';
import { TcPage, IPageState } from '@tc/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-leads-page',
  templateUrl: './leads-page.component.html',
  styleUrls: ['./leads-page.component.scss']
})
export class LeadsPageComponent extends TcPage implements OnInit {

  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'leads-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }

}
