import { CollaboratorWorkload } from './../collaborators.interfaces';
import { Collaborator } from 'src/generated/api/krakenn-api';

export interface CollaboratorsState {
  activeCollaborators: Collaborator[];
  collaboratorsWorkload: CollaboratorWorkload[];
}

export const initialCollaboratorsState: CollaboratorsState = {
  activeCollaborators: [],
  collaboratorsWorkload: []
};
