import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { map } from "rxjs/operators";

import { BypassLogin, BypassLoginActionTypes } from './login.actions';
import { AuthenticationService } from "src/app/services/authentication.service";

@Injectable()
export class BypassLoginEffects {
    constructor(
        private actions$: Actions,
        private authenticationService: AuthenticationService,
    ) { }

    @Effect({ dispatch: false })
    bypassLogin = this.actions$.pipe(
        ofType<BypassLogin>(
            BypassLoginActionTypes.BYPASS_LOGIN
        ),
        map((action: BypassLogin) => {
            this.authenticationService.skipLogin(
                action.payload.email
            );
        })
    );
}