import { ElementRef, Inject, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { ContactsService } from 'src/app/services/contacts.service';
import { TcNotificationService } from '@tc/core';

@Component({
  selector: 'app-edit-contact-popup',
  templateUrl: './edit-contact-popup.component.html',
  styleUrls: ['./edit-contact-popup.component.scss']
})
export class EditContactPopupComponent extends TcFormComponent<any> implements OnInit {
  title = ''

  constructor(
    private dialogRef: MatDialogRef<EditContactPopupComponent>,
    translate: TcTranslateService,
    private notificationService: TcNotificationService,
    private store: Store<any>,
    private service: ContactsService,
    elem: ElementRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(translate, elem);
    this.fields = [
      {
        fieldGroupClassName: "form-display-grid",
        fieldGroup: [
          {
            key: "firstName",
            type: "input",
            templateOptions: {
              type: "text",
              required: true,
              maxLength: 50,
            },
          },
          {
            key: "lastName",
            type: "input",
            templateOptions: {
              type: "text",
              required: true,
              maxLength: 50,
            },
          },
          {
            key: "role",
            type: "input",
            templateOptions: {
              type: "text",
              required: false,
              maxLength: 50,
            },
          },
          {
            key: "phone",
            type: "input",
            templateOptions: {
              label: this.translate.instant('phoneNumber'),
              type: "text",
              required: false,
              maxLength: 50
            },
          },
          {
            key: "email",
            type: "input",
            templateOptions: {
              type: "text",
              required: false,
              maxLength: 255,
            },
          }
        ],
      },
    ];
  }

  onClose() {
    this.dialogRef.close(this.model);
  }

  ngOnInit(): void {
    if (this.data.id) {
      this.service.getContactById(this.data.id).subscribe(contact => {
        this.data = contact;
        this.model = contact;
      },
      err => this.notificationService.error(err));
    }
    super.ngOnInit();
  }

  submit() {
    if (this.form.valid) {
      if(!this.data.id) {
        this.service.save(this.model).subscribe(result => {
          this.dialogRef.close(result);
        },
        err => {
          this.dialogRef.close();
        });
      } else {
        this.service.edit(this.model).subscribe(result => {
          this.dialogRef.close(result)
        },
        err => {
          this.dialogRef.close();
        })
      }
    }
  }
}
