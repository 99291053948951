import { Injectable } from "@angular/core";
import { Effect, ofType, Actions } from "@ngrx/effects";
import { AddMultipleTasks, OpenAddTasksMassDialog, TasksActionTypes, TerminateTask } from "./tasks.actions";
import { TasksService } from "src/app/services/tasks.service";
import { map, withLatestFrom } from "rxjs/operators";
import { TcTranslateService, TcNotificationService } from '@tc/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { DashboardEntitiesEnum } from '../../dashboard/dashboard-entities-enum';
import { select, Store } from '@ngrx/store';
import { myTasks } from './task.selectors';
import { AddTasksMassPopupComponent } from "../components/smart/add-tasks-mass-popup/add-tasks-mass-popup.component";
import { AddTaskMassResponse } from "../interfaces/AddTaskMassResponse";
import { ListRefreshService, ListRefreshType } from "src/app/services/list-refresh.service";

@Injectable()
export class TasksEffects {
  constructor(
    private actions$: Actions,
    private tasksService: TasksService,
    private translateService: TcTranslateService,
    private notificationService: TcNotificationService,
    private dialog: MatDialog,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
    private readonly listRefreshService: ListRefreshService,
    private store: Store<any>) { }

  @Effect({ dispatch: false })
  terminateTask = this.actions$.pipe(
    ofType<TerminateTask>(
      TasksActionTypes.TERMINATE_TASK
    ),
    withLatestFrom(this.store.pipe(select(myTasks))),
    map(([action, tasks]) => {
      this.tasksService.terminateTask(action.payload).subscribe(s => {
        if (s === 'ok') {
          this.notificationService.success(this.translateService.instant('terminate-task.notifications.success'));
          this.dialog.closeAll();
          const task = tasks.filter(t => t.id === (action.payload as any).id);
          if (task) {
            const service = this.entityCollectionServiceFactory.create(DashboardEntitiesEnum.DashboardMyTasks);
            service.clearCache();
            service.addManyToCache([...tasks.filter(t => t.id !== (action.payload as any).id)]);
          }
        }
      })
    })
  );

  @Effect({ dispatch: false })
  openAddTasksMassDialog = this.actions$.pipe(
    ofType<OpenAddTasksMassDialog>(TasksActionTypes.OPEN_ADD_TASKS_MASS_DIALOG),
    map((action: OpenAddTasksMassDialog) => {
        this.dialog.open(AddTasksMassPopupComponent, {
          data: null,
          width: '50vw',
          height: '70vh'
        });
    })
  );

  @Effect({dispatch: false})
  addMultipleTasks = this.actions$.pipe(
    ofType<AddMultipleTasks>(TasksActionTypes.ADD_MULTIPLE_TASKS),
    map((action: AddMultipleTasks) => {
      this.tasksService.addMultipleTasks(action.payload).subscribe((response) => {
        const {status, missionName, lineNumber, errorMessage, rowsUpdated} = response;

        if (status === 'ok') {
          const msg =  rowsUpdated.toString() + ' ' +this.translateService.instant('success-add-task-mass') + ' ' +  missionName;

          this.notificationService.success(msg);

          this.listRefreshService.emitRefresh(ListRefreshType.Tasks);

        } else {
          let errMsg = '';

          if (lineNumber >= 0)
            errMsg = this.translateService.instant('line') + ' ' + lineNumber + ' : '+
                    (errorMessage.data ? errorMessage.data + this.translateService.instant(errorMessage.code) : this.translateService.instant(errorMessage.code));
          else
            errMsg = 'Error 500: ' + errorMessage.data;

          this.notificationService.error(errMsg,'',{disableTimeOut: true});

        }
      })
    })
  )
}
