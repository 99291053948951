import { Component, OnInit } from '@angular/core';
import { TcPage, IPageState } from '@tc/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-collaborators-page',
  templateUrl: './collaborators-page.component.html',
  styleUrls: ['./collaborators-page.component.scss']
})
export class CollaboratorsPageComponent extends TcPage implements OnInit {

  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'collaborators-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
