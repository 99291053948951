import {
  Component,
  OnInit,
  Input,
  Optional,
  Inject,
  ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { sanitizeIdentifier } from '@angular/compiler';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-invoice-preview',
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.scss'],
})
export class InvoicePreviewComponent
  extends TcFormComponent<any>
  implements OnInit
{
  url: SafeResourceUrl;
  clientInvoiceEmailList: string;
  clientInvoiceEmailText: string;

  constructor(
    translate: TcTranslateService,
    elem: ElementRef,
    public dialogRef: MatDialogRef<InvoicePreviewComponent>,
    public sanitizer: DomSanitizer,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(translate, elem);

    this.fields = [
      {
        className: 'textarea-form-label',
        key: 'clientInvoiceEmailList',
        type: 'textarea',
        templateOptions: {
          minRows: 3,
          maxRows: 3,
          required: true,
        },
      },
      // {
      //   className: 'textarea-form-label',
      //   key: 'clientInvoiceEmailText',
      //   type: 'textarea-editor',
      //   templateOptions: {
      //     required: true
      //   }
      // },
      {
        className: 'textarea-form-label',
        key: 'clientInvoiceEmailText',
        type: 'textarea',
        templateOptions: {
          required: true,
          autosize: true,
          autosizeMinRows: 8,
          autosizeMaxRows: 8,
        },
      },
    ];

    if (data) {
      this.model = data;
    }
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.data && this.data.pdfId) {
      const pdfPreviewUrl = `https://drive.google.com/file/d/${this.data.pdfId}/preview`;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(pdfPreviewUrl);
      this.clientInvoiceEmailText = this.data.clientInvoiceEmailText;
      this.clientInvoiceEmailList = this.data.clientInvoiceEmailList;
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onOk() {
    if (this.form.valid) {
      this.dialogRef.close(this.model);
    }
  }
}
