import { MyTask } from "./../../../models/my-task.model";
import { Component, OnInit, ElementRef, Optional, Inject } from "@angular/core";
import { TcTranslateService, TcGenericFormComponent } from "@tc/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EntityCollectionServiceFactory } from "ngrx-data";
import { EntitiesEnum } from "src/generated/EntitiesEnum";
import { TimeImputation } from "src/generated/api/krakenn-api";
import { Store } from "@ngrx/store";
import { InsertTimeImputationSuccess } from "../../../store/dashboard.actions";
import { Utils } from "src/app/modules/core/utils";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { DateAdapter } from "@angular/material/core";
import { JiraUrlService } from "src/app/services/jira-url.service";

@Component({
  selector: "app-dashboard-ti-pop-up",
  templateUrl: "./dashboard-ti-pop-up.component.html",
  styleUrls: ["./dashboard-ti-pop-up.component.scss"],
})
export class DashboardTiPopUpComponent
  extends TcGenericFormComponent<TimeImputation>
  implements OnInit
{
  taskName: string;
  jiraId: string;
  missionName: string;
  projectName: string;

  constructor(
    private store: Store<any>,
    translate: TcTranslateService,
    elem: ElementRef,
    private dialogRef: MatDialogRef<DashboardTiPopUpComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public myTask: MyTask,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    dateAdapter: DateAdapter<any>,
    private jiraUrl: JiraUrlService
  ) {
    super(
      EntitiesEnum.TimeImputations,
      entityCollectionServiceFactory,
      translate,
      elem,
      dateAdapter
    );

    this.fields = [
      {
        key: "date",
        type: "datepicker",
        templateOptions: {
          required: true,
        },
        validators: {
          dateMonday: {
            expression: (c) =>
              !c.value || new Date(c.value) >= this.getLastMonday(new Date()),
            message: (error, field: FormlyFieldConfig) =>
              `${this.translate.instant(
                `dashboard-ti-popup.validations.dateMonday`
              )}`,
          },
          dateLast: {
            expression: (c) =>
              !c.value ||
              !(
                new Date().getDate() === 2 &&
                new Date(c.value) <= this.getFirstDayOfMonth(new Date())
              ),
            message: (error, field: FormlyFieldConfig) =>
              `${this.translate.instant(
                `dashboard-ti-popup.validations.dateLastMonth`
              )}`,
          },
        },
      },
      {
        fieldGroupClassName: "display-col",
        fieldGroup: [
          {
            key: "daysConsumed",
            type: "input",
            templateOptions: {
              type: "number",
              required: true,
            },
          },
          {
            key: "daysEstimatedToFinish",
            type: "input",
            templateOptions: {
              type: "number",
              required: true,
            },
          },
        ],
      },
      {
        key: "workDone",
        type: "textarea",
        templateOptions: {
          required: true,
          autosize: true,
          autosizeMinRows: 4,
          autosizeMaxRows: 8,
        },
      },
    ];
  }

  getLastMonday = (date: Date) => {
    // if current day is monday then lastMonday is current day else calculate last monday of the current week
    let dayOfMonth =
      date.getDay() === 1 ? date.getDate() : date.getDate() - date.getDay() + 1;
    // if current day is sunday, dayOfMonth = last monday
    if (date.getDay() === 0) {
      dayOfMonth = date.getDate() - 6;
    }
    const lastMonday = new Date(
      date.getFullYear(),
      date.getMonth(),
      dayOfMonth,
      0,
      0,
      0,
      0
    );
    return lastMonday;
  };

  getFirstDayOfMonth = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), 0);
  };

  ngOnInit() {
    // call super ng on init
    super.ngOnInit();

    if (this.myTask) {
      this.taskName = this.myTask.name;
      this.jiraId = this.myTask.jiraId;

      if (this.myTask.mission) {
        this.missionName = this.myTask.mission.name;
      }

      if (this.myTask.mission) {
        if ((this.myTask.mission as any).project) {
          this.projectName = ((this.myTask.mission as any).project as any).name;
        }
      }

      this.afterSave = (model: TimeImputation) => {
        // construct new imputation to update data in cache
        const newImputation: MyTask = Object.assign({}, this.myTask);

        newImputation.sumTimeImputation += model.daysConsumed;
        newImputation.lastRaf = model.daysEstimatedToFinish;

        this.store.dispatch(new InsertTimeImputationSuccess(newImputation));

        this.dialogRef.close();
      };
    }
  }
  submit(model: any) {
    if (this.form.valid) {
      this.model.collaboratorId = this.myTask.collaboratorId;
      this.model.taskId = this.myTask.id;
      delete (this.model as any).jiraId;
      this.model.daysConsumed = Utils.convertHoursToDays(
        this.model.daysConsumed
      );
      this.model.daysEstimatedToFinish = Utils.convertHoursToDays(
        this.model.daysEstimatedToFinish
      );
      super.submit(model);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  getJiraUrl(jiraId) {
    return this.jiraUrl.getJiraUrl(jiraId);
  }
}
