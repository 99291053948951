import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { TcSmartComponent, TcGenericListComponent, TcListRowActionButtonsPosition, TcListSortType, TcListFilterType } from '@tc/core';
import { Store } from '@ngrx/store';
import { Holiday } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/generated/EntitiesEnum';
import { AddListItem, EditListItem } from 'src/app/modules/core/store/actions/list.actions';
import { DashboardEntitiesEnum } from '../../../dashboard-entities-enum';

@Component({
  selector: 'app-dashboard-holidays-list',
  templateUrl: './dashboard-holidays-list.component.html',
  styleUrls: ['./dashboard-holidays-list.component.scss']
})
export class DashboardHolidaysListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  constructor(private store: Store<any>) {
    super();
  }


  @ViewChild('colCollaboratorNameTemplate', { static: true }) colCollaboratorNameTemplate: TemplateRef<any>;
  @ViewChild('colStartDateTemplate', { static: true }) colStartDateTemplate: TemplateRef<any>;
  @ViewChild('colDurationTemplate', { static: true }) colDurationTemplate: TemplateRef<any>;

  dashboardHolidaysList: TcGenericListComponent<Holiday>;
  @ViewChild('dashboardHolidaysList', { static: true }) set setDashboardHolidaysList(values: TcGenericListComponent<Holiday>) {
    this.dashboardHolidaysList = values;
    this.dashboardHolidaysList.entityName = DashboardEntitiesEnum.DashboardHolidays;
  }

  ngAfterViewInit() {
    this.dashboardHolidaysList.service.clearCache();
    this.dashboardHolidaysList.service.getWithQuery({ initial: 'true' });
  }

  ngOnInit() {
    this.dashboardHolidaysList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.dashboardHolidaysList.showTotalInActionsHeader = true;

    this.dashboardHolidaysList.sortType = TcListSortType.Server;
    this.dashboardHolidaysList.filterType = TcListFilterType.Disabled;
    this.dashboardHolidaysList.hasFixedHeader = true;

    this.dashboardHolidaysList.hasAddButton = true;
    this.dashboardHolidaysList.addItemWhenKeyPresed = true;

    this.dashboardHolidaysList.hasActionsLabel = false;
    this.dashboardHolidaysList.isPaged = false;

    this.dashboardHolidaysList.onScrollDown = () => {
      this.dashboardHolidaysList.service.getAll();
    };

    this.dashboardHolidaysList.columns = [
      {
        propertyName: 'collaborator.name',
        visible: true,
        htmlTemplate: this.colCollaboratorNameTemplate,
        sortExpression: 'collaborator.firstName'
      },
      {
        propertyName: 'startDate',
        visible: true,
        htmlTemplate: this.colStartDateTemplate
      },
      {
        propertyName: 'duration',
        visible: true,
        htmlTemplate: this.colDurationTemplate
      },
    ];

    this.dashboardHolidaysList.onRowAction = (row: Holiday, actionName: string) => {
      if (actionName === 'edit') {
        this.editHoliday(row);
      }
    };

    // this.dashboardHolidaysList.onRowClick = (row: Holiday) => {
    //   this.editHoliday(row);
    // };

    this.dashboardHolidaysList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Holidays }));
    };
  }

  private editHoliday(row: Holiday) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Holidays, data: row }));
  }


}
