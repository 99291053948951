import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TcGenericListComponent, TcSmartComponent, TcListRowActionButtonsPosition, TcListSortType, TcListFilterType, TcListDefaultAction } from '@tc/core';
import { Client } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { Store } from '@ngrx/store';
import { EditListItem, AddListItem, DuplicateListItem } from 'src/app/modules/core/store/actions/list.actions';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  constructor(private store: Store<any>) {
    super();
  }

  clientsList: TcGenericListComponent<Client>;
  @ViewChild('clientsList', { static: true }) set setClientsList(values: TcGenericListComponent<Client>) {

    this.clientsList = values;
    this.clientsList.entityName = EntitiesEnum.Clients;
  }

  ngAfterViewInit() {
    this.clientsList.service.clearCache();
    this.clientsList.service.getWithQuery({ initial: 'true' });
  }

  ngOnInit() {

    this.clientsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.clientsList.showTotalInActionsHeader = true;

    this.clientsList.sortType = TcListSortType.Server;
    this.clientsList.filterType = TcListFilterType.Server;
    this.clientsList.hasFixedHeader = true;

    this.clientsList.hasAddButton = true;
    this.clientsList.addItemWhenKeyPresed = true;

    this.clientsList.hasActionsLabel = false;
    this.clientsList.isPaged = false;

    this.clientsList.onScrollDown = () => {
      this.clientsList.service.getAll();
    };

    this.clientsList.columns = [
      {
        propertyName: 'shortName',
        visible: true
      },
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'address1',
        visible: true
      },
      {
        propertyName: 'postalCode',
        visible: true
      },
      {
        propertyName: 'city',
        visible: true
      },
      {
        propertyName: 'country',
        visible: true
      }
    ];

    this.clientsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Delete,
        icon: 'delete',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Duplicate,
        icon: 'content_copy',
        visible: true
      }
    ];

    this.clientsList.onRowAction = (row: Client, actionName: string) => {
      if (actionName === 'edit') {
        this.editClient(row);
      }
  }

    this.clientsList.onRowClick = (row: Client) => {
      this.editClient(row);
    };

    this.clientsList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Clients }));
    };

    this.clientsList.onDuplicateRow = (row: Client) => {
      this.store.dispatch(new DuplicateListItem({entityName: EntitiesEnum.Clients, data: row}));
    }
  }

  private editClient(row: Client) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Clients, data: row }));
  }

}
