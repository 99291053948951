import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsListComponent } from './components/smart/projects-list/projects-list.component';
import { ProjectDetailComponent } from './components/smart/project-detail/project-detail.component';
import { TcCoreModule } from '@tc/core';
import { CoreModule } from '../core/core.module';

const smartComponents = [
  ProjectsListComponent,
  ProjectDetailComponent
];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule
  ],
  entryComponents: [
    ProjectDetailComponent
  ],
  exports: [...smartComponents]
})
export class ProjectsModule { }
