import { Injectable, Inject, Optional } from "@angular/core";
import {
    Configuration,
    BASE_PATH,
    Task
} from "src/generated/api/krakenn-api";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  TcService, TcGridPaginationService, TcGridDataSourceSort } from '@tc/core';
import { ListService } from './list.service';
import { TasksResponse } from './../modules/tasks-grid/task-grid.interfaces';
import { Store } from "@ngrx/store";
import { EntityCollectionServiceFactory } from "ngrx-data";
import { EntitiesEnum } from "src/generated/EntitiesEnum";

@Injectable({
  providedIn: 'root'
})

export class TasksGridService  extends TcService{
  public configuration = new Configuration();
  protected basePath =
      "https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0";
  protected defaultHeaders = new HttpHeaders();

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private readonly http: HttpClient,
    private readonly tcGridPaginationService: TcGridPaginationService,
    private store: Store<any>,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) {
    super();

    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
          configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  public getTasks(
    page: number,
    limit: number,
    filter?: any,
    sort?: TcGridDataSourceSort,
  ): Promise<TasksResponse> {
    if (filter?.hasOwnProperty('targetDate')) {
      if (!filter.targetDate.hasOwnProperty('filterType')) {
        filter.targetDate.filterType = 'DateRange';
      }
    }

    const queryString = this.tcGridPaginationService.buildQueryString({ filter, limit, page, sort });
    return this.http.get<TasksResponse>(`${this.configuration.basePath}/task/get-tasks-with-details?${queryString}`).toPromise();
  }

  getAllTasks(): Observable<Task[]> {
    const url = `${this.configuration.basePath}/task/get-tasks-with-details`;

    return this.http.get<Task[]>(url, {withCredentials: true});
  }

  save(data: any): Observable<any> {
    const url = `${this.configuration.basePath}/task`;
    const headers = this.defaultHeaders;

    return this.http.post<Task>(url, data,
        {
            withCredentials: true,
            headers,
            observe: 'body',
            reportProgress: false
        });
  }

  edit(data: any): Observable<any> {
    const url = `${this.configuration.basePath}/task`;
      const headers = this.defaultHeaders;

      return this.http.put<Task>(url, data,
          {
              withCredentials: true,
              headers,
              observe: 'body',
              reportProgress: false
          });
  }

  delete(id: number): Observable<any> {
      const url = `${this.configuration.basePath}/task/${id}`;
      const headers = this.defaultHeaders;
      return this.http.delete<number>(url,
          {
              withCredentials: true,
              headers,
              observe: 'body',
              reportProgress: false
          });
  }
}
