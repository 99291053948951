import { Proposal } from 'src/generated/api/krakenn-api/model/proposal';

export class DashboardProposalsType {
    static className = 'Proposal';
    static gqlRootQueryName = 'proposal';
    static gqlColumns: string[] = [
        '_id',
        'name'
    ];

    static gqlNodeMapper = (node: any): Proposal => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Proposal;
    }

}
