import { Subscription } from 'rxjs';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getTcAuthUser } from '@tc/auth';
import { TcConfigService } from '@tc/core';
import { UserRole } from '../modules/core/user-role.enum';
import { exception } from 'console';

@Injectable({
  providedIn: 'root'
})
export class PermissionService implements OnDestroy {
  private authUserSubscription: Subscription;
  public authUser: import("@tc/auth").TcUser;

  constructor(private configService: TcConfigService, private store: Store<any>) {
    // Get the authenticated user as soon as possible. Do not use OnInit here, as the constructor is faster to find the data in the store.
    this.authUserSubscription = this.store.pipe(select(getTcAuthUser)).subscribe(authUser => {
      if (authUser) {
        this.authUser = authUser;
      }
    });
  }

  ngOnDestroy() {
    this.authUserSubscription.unsubscribe();
  }

  isAdmin = (email: string): boolean => {

    const admins = (this.configService.config as any).APP_ADMINS;
    const adminsArray = (admins as string).split(';');

    return adminsArray.find(e => e === email) != null;

  }

  /**
   * Check if the authenticated user has been found in the store.
   */
  private hasAuthUser() {
    if (this.authUser === undefined) {
      throw new Error('Authenticated user is missing.');
    }
  }

  /**
   * Check if the authenticated user has a specific role
   */
  public authUserHasRole(role: UserRole): boolean {
    this.hasAuthUser();
    if (this.authUser && (this.authUser as any).roles && (this.authUser as any).roles.find(r => r === role)) {
      return true;
    }
    return false;
  }
}
