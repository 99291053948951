import { CollaboratorWorkload } from './../collaborators.interfaces';
import { Inject, Injectable, Optional } from "@angular/core";
import { CollaboratorsActionTypes, GetActiveCollaborators, GetActiveCollaboratorsSuccess, GetCollaboratorsWorkload, GetCollaboratorsWorkloadSuccess, LogAsUser } from "./collaborators.actions";
import { Effect, ofType, Actions } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import { AuthenticationService } from "../../../services/authentication.service";
import { Observable } from 'rxjs';
import { TcAction } from '@tc/auth';
import { BASE_PATH, Collaborator, Configuration } from 'src/generated/api/krakenn-api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CollaboratorsService } from "src/app/services/collaborators.service";

@Injectable()
export class CollaboratorsEffects {

  protected basePath = 'https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0';
  public configuration = new Configuration();

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private collaboratorsService: CollaboratorsService,
    private httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath = configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  @Effect({ dispatch: false })
  logAsUser = this.actions$.pipe(
    ofType<LogAsUser>(CollaboratorsActionTypes.LOG_AS_USER),
    map((action: LogAsUser) => {
      this.authService.logAsUser(action.payload.data, action.payload.args);
    })
  );

  @Effect()
  getActiveCollaborators$: Observable<TcAction> = this.actions$.pipe(
    ofType<GetActiveCollaborators>(
      CollaboratorsActionTypes.GET_ACTIVE_COLLABORATORS
    ),
    mergeMap((action: GetActiveCollaborators) =>

      this.httpClient.get<Collaborator[]>(`${this.configuration.basePath}/collaborator/activeCollaborators`,
        {
          withCredentials: true
        }
      ).pipe(
        map(
          (collaborators: Collaborator[]) =>
            new GetActiveCollaboratorsSuccess(collaborators)
        )
      )
    )
  );

  @Effect()
  getCollaboratorsWorkload$: Observable<TcAction> = this.actions$.pipe(
    ofType<GetCollaboratorsWorkload>(
      CollaboratorsActionTypes.GET_COLLABORATORS_WORKLOAD
    ),
    mergeMap((action: GetCollaboratorsWorkload) =>
      this.collaboratorsService.getCollaboratorsWorkload(
        action.payload.startDate, action.payload.endDate
      ).pipe(
        map(
          (collaboratorsWorload: CollaboratorWorkload[]) =>
            new GetCollaboratorsWorkloadSuccess(collaboratorsWorload)
        )
      )
    )
  );
}
