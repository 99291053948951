import { Component, ElementRef, OnInit } from '@angular/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { TcTranslateService, TcGenericFormComponent } from '@tc/core';
import {
  Collaborator,
  Mission,
  Project,
} from 'src/generated/api/krakenn-api/model/models';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DateAdapter } from '@angular/material/core';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'app-mission-detail',
  templateUrl: './mission-detail.component.html',
  styleUrls: ['./mission-detail.component.scss'],
})
export class MissionDetailComponent
  extends TcGenericFormComponent<Mission>
  implements OnInit
{
  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
    private store: Store<any>
  ) {
    super(
      EntitiesEnum.Missions,
      entityCollectionServiceFactory,
      translate,
      elem,
      dateAdapter
    );

    this.fields = [
      {
        fieldGroupClassName: 'display-row',
        fieldGroup: [
          // {
          //   key: 'name',
          //   type: 'textarea-editor',
          //   className: 'textarea-form-content textarea-form-label',
          //   templateOptions: {
          //     required: true
          //   }
          // },
          {
            key: 'name',
            type: 'textarea',
            templateOptions: {
              required: true,
              autosize: true,
              autosizeMinRows: 5,
              autosizeMaxRows: 5,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'projectId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) =>
                this.qlAutocompleteService.getAutocompleteValues<Project>(
                  EntitiesEnum.Projects,
                  term,
                  'id',
                  'name,client{name}',
                  (item: any) => {
                    const clientName = item.client.name.substring(0, 10);
                    return `${clientName} / ${item.name}`;
                  }
                  // {key: 'client.name', order: 'ASC'}
                ),
              listValue: 'project.name',
            },
            validators: {
              projectId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'jiraSprint',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 50,
            },
          },
          {
            key: 'targetDate',
            type: 'datepicker',
            templateOptions: {},
          },
          {
            key: 'targetInitialWL',
            type: 'input',
            templateOptions: {
              type: 'number',
            },
          },
          {
            key: 'targetInitialDate',
            type: 'datepicker',
            templateOptions: {},
          },
          {
            key: 'targetReviewedWL',
            type: 'input',
            templateOptions: {
              type: 'number',
            },
          },
          {
            key: 'targetReviewedDate',
            type: 'datepicker',
            templateOptions: {},
          },
          {
            key: 'changesCounter',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: true,
            },
          },
          {
            key: 'responsibleCollaboratorId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) =>
                this.qlAutocompleteService.getAutocompleteValues<Collaborator>(
                  EntitiesEnum.Collaborators,
                  term,
                  'id',
                  'trigram',
                  null,
                  false,
                  [
                    {
                      property: 'expirationDate',
                      filterType: 'IsNull',
                      value: '1',
                    },
                  ]
                ),
              listValue: 'responsible.trigram',
            },
            validators: {
              collaboratoId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'managementFactor',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: true,
            },
          },
          {
            key: 'imputationType',
            type: 'select',
            templateOptions: {
              options: new SelectValues().missionImputationType(this.translate),
            },
          },
          {
            key: 'unit',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 50,
            },
          },
          {
            key: 'quantity',
            type: 'input',
            templateOptions: {
              type: 'number',
            },
          },
          {
            key: 'priceHTPerUnit',
            type: 'input',
            templateOptions: {
              type: 'number',
            },
          },
          {
            key: 'status',
            type: 'select',
            templateOptions: {
              required: true,
              options: new SelectValues().missionStatus(this.translate),
            },
          },
          {
            key: 'priority',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: false,
            },
          },
        ],
      },
    ];
  }

  submit(model: Mission) {
    const clone = {
      id: model.id,
      projectId: model.projectId,
      jiraSprint: model.jiraSprint,
      name: model.name,
      targetDate: model.targetDate,
      quantity: model.quantity,
      managementFactor: model.managementFactor,
      imputationType: model.imputationType,
      unit: model.unit,
      priceHTPerUnit: model.priceHTPerUnit,
      status: model.status,
      priority: model.priority,
      targetInitialWL: model.targetInitialWL,
      targetInitialDate: model.targetInitialDate,
      targetReviewedWL: model.targetReviewedWL,
      targetReviewedDate: model.targetReviewedDate,
      changesCounter: model.changesCounter,
      responsibleCollaboratorId: model.responsibleCollaboratorId,
    };

    super.submit(clone);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.model?.id) {
      this.model.targetDate = null;
      this.model.targetInitialDate = null;
      this.model.targetReviewedDate = null;
    }
  }
}
