import { TcAction } from "@tc/auth";
import { TcGenericEntity } from "@tc/core";
import { Activity } from 'src/generated/api/krakenn-api';

export enum ActivitysActionTypes {
  DISPLAY_ACTIVITYS_LIST = "[Activitys] Display Activitys List",
  LOAD_ACTIVITYS_LIST = "[Activitys] Load Activitys List",
  LOAD_ACTIVITYS_LIST_SUCCESS = "[Activitys] Load Activitys List Success",
  ADD_ACTIVITY = "[Activitys] Add Activity",
  ADD_ACTIVITY_SUCCESS = "[Activitys] Add Activity Success",
  EDIT_ACTIVITY = "[Activitys] Edit Activity",
  EDIT_ACTIVITY_SUCCESS = "[Activitys] Edit Activity Success",
  DELETE_ACTIVITY = "[Activitys] Delete Activity",
  DELETE_ACTIVITY_SUCCESS = "[Activitys] Delete Activity Success",
  MARK_ACTIVITY_AS_DONE = "[Activitys] Mark Activity as done",
  MARK_ACTIVITY_AS_DONE_SUCCESS = "[Activitys] Mark activity as done success"
}

export class MarkActivityAsDone implements TcAction {
  readonly type = ActivitysActionTypes.MARK_ACTIVITY_AS_DONE;
  constructor(public payload: number) { }
}

export class MarkActivityAsDoneSuccess implements TcAction {
  readonly type = ActivitysActionTypes.MARK_ACTIVITY_AS_DONE_SUCCESS;
  constructor(public payload: Activity) { }
}
export class DisplayActivitysList implements TcAction {
  readonly type = ActivitysActionTypes.DISPLAY_ACTIVITYS_LIST;
  constructor(
    public payload: {
      table: string;
      id: number;
      args?: any
    }
  ) { }
}

export class LoadActivitysList implements TcAction {
  readonly type = ActivitysActionTypes.LOAD_ACTIVITYS_LIST;
  constructor(
    public payload: {
      data: { id: number, table: string };
    }
  ) { }
}

export class LoadActivitysListSuccess implements TcAction {
  readonly type = ActivitysActionTypes.LOAD_ACTIVITYS_LIST_SUCCESS;
  constructor(public payload: Activity[]) { }
}

export class AddActivity implements TcAction {
  readonly type = ActivitysActionTypes.ADD_ACTIVITY;
  constructor(public payload: Activity) { }
}

export class AddActivitySuccess implements TcAction {
  readonly type = ActivitysActionTypes.ADD_ACTIVITY_SUCCESS;
  constructor(public payload: Activity) { }
}

export class EditActivity implements TcAction {
  readonly type = ActivitysActionTypes.EDIT_ACTIVITY;
  constructor(public payload: Activity) { }
}

export class EditActivitySuccess implements TcAction {
  readonly type = ActivitysActionTypes.EDIT_ACTIVITY_SUCCESS;
  constructor(public payload: Activity) { }
}

export class DeleteActivity implements TcAction {
  readonly type = ActivitysActionTypes.DELETE_ACTIVITY;
  constructor(public payload: number) { }
}

export class DeleteActivitySuccess implements TcAction {
  readonly type = ActivitysActionTypes.DELETE_ACTIVITY_SUCCESS;
  constructor(public payload: number) { }
}

export type ActivitysActions =
  DisplayActivitysList
  | LoadActivitysList
  | LoadActivitysListSuccess
  | AddActivity
  | AddActivitySuccess
  | EditActivity
  | EditActivitySuccess
  | DeleteActivity
  | DeleteActivitySuccess
  | MarkActivityAsDone
  | MarkActivityAsDoneSuccess;
