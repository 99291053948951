import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TargetDateService {
  format = 'YYYY-MM-DD';
  dateString = moment(new Date()).format(this.format);
  day = moment(this.dateString, this.format).day();

  getStartDate() {
    return this.day <= 2
      ? moment(this.dateString, this.format).startOf('week').add(-3, 'day')
      : moment(this.dateString, this.format).startOf('week').add(4, 'day');
  }

  getEndDate() {
    return this.day <= 2
      ? moment(this.dateString, this.format).startOf('week').add(3, 'day')
      : moment(this.dateString, this.format).endOf('week').add(4, 'day');
  }

  getDefaultDate() {
    const defaultDate = {
      start: this.getStartDate(),
      end: this.getEndDate(),
    };
    return defaultDate;
  }

  isInTheWeek(dateString: string) {
    const date = moment(dateString);
    const startDate = this.getStartDate();
    const endDate = this.getEndDate();

    return startDate.isSameOrBefore(date) && endDate.isSameOrAfter(date);
  }

  getIntervalForUnassignedTasks() {
    const startString = this.getStartDate().format(this.format);
    const endString = this.getEndDate().add(1, 'week').format(this.format);
    return `${startString}|${endString}`;
  }
}
