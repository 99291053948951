import { TasksGridComponent } from './../../modules/tasks-grid/components/smart/tasks-grid/tasks-grid.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPageState, TcPage } from '@tc/core';
import { GetActiveCollaborators } from 'src/app/modules/collaborators/store/collaborators.actions';

@Component({
  selector: 'app-tasks-grid-page',
  templateUrl: './tasks-grid-page.component.html',
  styleUrls: ['./tasks-grid-page.component.scss']
})
export class TasksGridPageComponent extends TcPage implements OnInit {
  @ViewChild('tasksGrid') tasksGrid: TasksGridComponent;

  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit(): void {
    const pageInfo: IPageState = {
      title: 'tasks-grid-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();

    this.store.dispatch(new GetActiveCollaborators());
  }

  refreshWorkload() {
    this.tasksGrid.refreshWorkload();
  }
}
