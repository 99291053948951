import { Injectable } from "@angular/core";
import { Effect, ofType, Actions } from "@ngrx/effects";
import {
  MissionsActionTypes,
  AddMissionCommercialImputation,
} from "./missions.actions";
import { MissionsService } from "src/app/services/missions.service";
import { map } from "rxjs/operators";

@Injectable()
export class MissionsEffects {
  constructor(
    private actions$: Actions,
    private missionsService: MissionsService
  ) {}

  @Effect({ dispatch: false })
  addMissionCommercialImputation = this.actions$.pipe(
    ofType<AddMissionCommercialImputation>(
      MissionsActionTypes.ADD_MISSION_COMMERCIAL_IMPUTATION
    ),
    map((action: AddMissionCommercialImputation) => {
      this.missionsService.addMissionCommercialImputation(
        action.payload.data,
        action.payload.args
      );
    })
  );
}
