import { TcAction } from "@tc/core";
import { Mission } from "src/generated/api/krakenn-api";

export enum MissionsActionTypes {
  ADD_MISSION_COMMERCIAL_IMPUTATION = "[Missions] Add mission commercial imputation",
}

export class AddMissionCommercialImputation implements TcAction {
  readonly type = MissionsActionTypes.ADD_MISSION_COMMERCIAL_IMPUTATION;
  constructor(
    public payload: {
      data: Mission;
      args?: any;
    }
  ) {}
}

export type MissionsActions = AddMissionCommercialImputation;
