import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TcTranslateService, TcGenericFormComponent } from '@tc/core';
import { Collaborator, Lead } from 'src/generated/api/krakenn-api';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { Contact, Opportunity } from 'src/generated/api/krakenn-api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DateAdapter } from '@angular/material/core';
import { ActivitysTable } from '../../../enums/activitys-table.enum';
import { EditListItem, AddListItem } from 'src/app/modules/core/store/actions/list.actions';
import { ActivitysListComponent } from '../activitys-list/activitys-list.component';

@Component({
  selector: 'app-lead-detail',
  templateUrl: './lead-detail.component.html',
  styleUrls: ['./lead-detail.component.scss']
})
export class LeadDetailComponent extends TcGenericFormComponent<Lead> implements OnInit {

  tableName = ActivitysTable.Lead;
  @ViewChild(ActivitysListComponent) activityList: ActivitysListComponent;

  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    private dialogRef: MatDialogRef<LeadDetailComponent>,
    private store: Store<any>,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {

    super(EntitiesEnum.Leads, entityCollectionServiceFactory, translate, elem, dateAdapter);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              required: true,
              type: 'text',
              maxLength: 255
            }
          },
          {
            key: 'opportunityId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Opportunity>(EntitiesEnum.Opportunities, term, 'id', 'name'),
              listValue: 'opportunity.name'
            },
            validators: {
              opportunityId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'contactId',
            type: 'autocomplete-list',
            templateOptions: {
              required: false,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Contact>(EntitiesEnum.Contacts, term, 'id', 'firstName,lastName'),
              listValue: 'contact.name'
            },
            validators: {
              contactId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'collaboratorId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Collaborator>(EntitiesEnum.Collaborators, term, 'id', 'firstName,lastName'),
              listValue: 'collaborator.name'
            },
            validators: {
              collaboratorId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            }
          },
          {
            key: 'activityDomain',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 255
            }
          },
          {
            key: 'group',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 255
            }
          },
          {
            key: 'incorporationNumber',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 255
            }
          },
          {
            key: 'collaboratorsNumber',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 255
            }
          },

          {
            key: 'salesRevenu',
            type: 'input',
            templateOptions: {
              type: 'number'
            }
          },
          {
            key: 'salesRevenuYear',
            type: 'input',
            templateOptions: {
              type: 'number'
            }
          },
          {
            key: 'address1',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 255
            }
          },
          {
            key: 'address2',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 255
            }
          },
          {
            key: 'postalCode',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 50
            }
          },
          {
            key: 'city',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 50
            }
          },
          {
            key: 'country',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 50
            }
          },
          {
             key: 'priority',
             type: 'input',
             templateOptions: {
               type: 'number'
             }
          },
          {
            key: "createdOn",
            type: "datepicker",
            templateOptions: {
              disabled: true,
              type: "text",
              readonly: true,
              datepickerPopup: "dd-MMMM-yyyy",
              datepickerOptions: {
                format: "dd-MM-yyyy hh:mm:ss",
              },
            },
          },
          {
            key: "updatedOn",
            type: "datepicker",
            templateOptions: {
              disabled: true,
              type: "text",
              readonly: true,
              datepickerPopup: "dd-MMMM-yyyy",
              datepickerOptions: {
                format: "dd-MM-yyyy hh:mm:ss",
              },
            },
          }        ]
      },
       {
         fieldGroupClassName: 'display-row',
         fieldGroup: [
           {
             key: 'comments',
             type: 'textarea-editor',
             className: 'textarea-form-content textarea-form-label',
             templateOptions: {
             }
           }
         ]
       }
    ];

  }

  addActivity() {
    this.activityList.addActivity();
  }

  submit(model: any) {
    if (this.form.valid) {
      const { id,
        opportunityId,
        contactId,
        collaboratorId,
        name,
        activityDomain,
        group,
        incorporationNumber,
        salesRevenu,
        salesRevenuYear,
        collaboratorsNumber,
        address1,
        address2,
        postalCode,
        city,
        country,
        comments,
        priority,
        createdOn,
        updatedOn } = model;

      const modelToSave: any = {
        opportunityId,
        contactId,
        collaboratorId,
        name,
        activityDomain,
        group,
        incorporationNumber,
        salesRevenu,
        salesRevenuYear,
        collaboratorsNumber,
        address1,
        address2,
        postalCode,
        city,
        country,
        comments,
        priority,
        createdOn,
        updatedOn
      };

      if (id) {
        modelToSave.id = id;
        modelToSave.createdOn = !createdOn ? (new Date().getTime()).toString() : createdOn;
        modelToSave.updatedOn = (new Date().getTime()).toString();;
      } else {
        modelToSave.createdOn = (new Date().getTime()).toString();
        modelToSave.updatedOn = (new Date().getTime()).toString();
      }

      super.submit(modelToSave);
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }
}

