import { TcNotificationService } from '@tc/core';
import { SelectValues } from './../../core/enums/select-values';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { DashboardActionTypes, InsertTimeImputation, InsertTimeImputationSuccess, OpenTerminateTaskDialog } from './dashboard.actions';
import { MatDialog } from '@angular/material/dialog';
import { DashboardTiPopUpComponent } from '../components/smart/dashboard-ti-pop-up/dashboard-ti-pop-up.component';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { DashboardEntitiesEnum } from '../dashboard-entities-enum';
import { TerminateTaskPopupComponent } from '../../tasks/components/smart/terminate-task-popup/terminate-task-popup.component';

@Injectable()
export class DashboardEffects {

  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private notificationService: TcNotificationService,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) { }

  @Effect({ dispatch: false })
  insertTimeImputation = this.actions$.pipe(
    ofType<InsertTimeImputation>(DashboardActionTypes.INSERT_TIME_IMPUTATION),
    map((action: InsertTimeImputation) => {

      // if mission for the task is not in progress => display a message
      if (action.payload.mission.status !== 1) {
        this.notificationService.warning('Mission not started or closed!');
      } else {
        // open popup for time imputation
        this.dialog.open(DashboardTiPopUpComponent, {
          data: action.payload,
          width: '30vw'
        });
      }

    })
  );

  @Effect({ dispatch: false })
  insertTimeImputationSuccess = this.actions$.pipe(
    ofType<InsertTimeImputationSuccess>(DashboardActionTypes.INSERT_TIME_IMPUTATION_SUCCESS),
    map((action: InsertTimeImputationSuccess) => {
      // instantiate service
      const service = this.entityCollectionServiceFactory.create(DashboardEntitiesEnum.DashboardMyTasks);
      // update data in cache
      service.updateOneInCache(action.payload);
    })
  );

  @Effect({ dispatch: false })
  openTerminateTaskDialog = this.actions$.pipe(
    ofType<OpenTerminateTaskDialog>(DashboardActionTypes.OPEN_TERMINATE_TASK_DIALOG),
    map((action: OpenTerminateTaskDialog) => {
        this.dialog.open(TerminateTaskPopupComponent, {
          data: action.payload,
          width: '30vw'
        });
    })
  );

}
