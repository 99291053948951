import { CommercialImputation } from '../api/krakenn-api';

export class CommercialImputationType {
  static className = 'CommercialImputation';
  static gqlRootQueryName = 'commercialimputation';
  static gqlColumns: string[] = [
      '_id',
      'missionId',
      'invoiceId',
      'date',
      'workDoneDescription',
      'quantity',
      'priceHT',
      'status',
  ];

  static gqlNodeMapper = (node: any): CommercialImputation => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as CommercialImputation;
    }

}
