import { QlServiceFactoryConfig } from './services/core/ql-service-factory-config';
import { InvoiceType } from 'src/generated/modelMetadata/InvoiceType';
import {
  entityMetadata,
  pluralNames,
  typesConfig,
} from 'src/generated/entity-metadata';
import { HolidayType } from 'src/generated/modelMetadata/HolidayType';
import { LeadType } from 'src/generated/modelMetadata/LeadType';
import { ActivityType } from './../generated/modelMetadata/ActivityType';
import { EstimationType } from 'src/generated/modelMetadata/EstimationType';
import { ProposalType } from 'src/generated/modelMetadata/ProposalType';
import { CommercialImputationType } from 'src/generated/modelMetadata/CommercialImputationType';
import { TimeImputationType } from 'src/generated/modelMetadata/TimeImputationType';
import { TaskType } from 'src/generated/modelMetadata/TaskType';
import { ProjectType } from 'src/generated/modelMetadata/ProjectType';
import { MissionType } from 'src/generated/modelMetadata/MissionType';
import { EstimationLineType } from 'src/generated/modelMetadata/EstimationLineType';
import { DashboardEntitiesEnum } from './modules/dashboard/dashboard-entities-enum';
import { DashboardHolidaysType } from './modules/dashboard/graphQlTypes/DashboardHolidaysType';
import { DashboardMyTasksType } from './modules/dashboard/graphQlTypes/DashboardMyTasksType';
import { DashboardHotOpportunitiesType } from './modules/dashboard/graphQlTypes/DashboardHotOpportunitiesType';
import { DashboardEstimationsType } from './modules/dashboard/graphQlTypes/DashboardEstimationsType';
import { DashboardProposalsType } from './modules/dashboard/graphQlTypes/DashboardProposalsType';
import { ContactType } from 'src/generated/modelMetadata/ContactType';
import { DashboardUnassignedTasksType } from './modules/dashboard/graphQlTypes/DashboardUnassignedTasksType';

InvoiceType.className = 'CustomInvoice';
InvoiceType.gqlRootQueryName = 'custominvoice';
InvoiceType.gqlColumns.push(`
details,
client {
    _id,
    name,
    shortName
}`);

EstimationType.gqlColumns.push(`
client {
    _id,
    name,
    shortName
}`);

EstimationLineType.gqlColumns.push(`
estimation {
    _id,
    name
}`);

ProposalType.gqlColumns.push(`
estimation {
    _id,
    name
}`);

CommercialImputationType.gqlColumns.push(`
mission {
    _id,
    name
    project {
      name
      client {
        name
        id
        _id
        shortName
      }
    }
}
invoice {
  _id,
  number,
  date
}`);

TimeImputationType.gqlColumns.push(`
task {
    _id,
    name,
    mission {
      name,
      project{
        name,
        client{
          name,
          shortName
        }
      }
    }
}
collaborator {
  _id,
  firstName,
  lastName,
}`);

TaskType.className = 'TasksWithAssign';
TaskType.gqlRootQueryName = 'taskswithassign';
TaskType.gqlColumns.push(`
collaboratorsTrigram,
nbOfImputations,
nbOfComments,
sumTimeImputation,
globalPriority,
targetPointsPerCollaborator,
consumedPerCollaborator,
terminated,
mission {
    _id,
    name,
    project {
      name,
      client {
        name,
        shortName
      }
    },
    priority,
    status
}`);

ContactType.className = 'ContactsWithActivity';
ContactType.gqlRootQueryName = 'contactswithactivity';
ContactType.gqlColumns.push(`
  noOfActivities,
  noOfLeads
`);

ProjectType.gqlColumns.push(`
client {
    _id,
    name,
    shortName
}
proposal {
  _id,
  name
}`);

MissionType.className = 'CustomMission';
MissionType.gqlRootQueryName = 'custommission';
MissionType.gqlColumns.push(`
hasCIs,
responsible {
  _id,
  trigram
}
project {
  _id,
  name,
  client {
    name,
    shortName
  }
}`);
// const defaultInvoiceNodeMapper = InvoiceType.gqlNodeMapper;
// InvoiceType.gqlNodeMapper = (node: any) => {
//   if (node.client) {
//     // node.clientId = node.client._id;
//   }
//   return defaultInvoiceNodeMapper(node);
// };

HolidayType.gqlColumns.push(`
collaborator {
    _id,
    firstName,
    lastName,
}`);

ActivityType.className = 'ActivitysWithContact';
ActivityType.gqlRootQueryName = 'activityswithcontact';
ActivityType.gqlColumns.push(`
  contactName,
  leadName,
  contact {
    _id,
    firstName,
    lastName
  }
  lead {
    _id,
    name
  }
  collaborator {
    _id,
    firstName,
    lastName
  }
`);

LeadType.className = 'LeadsWithContact';
LeadType.gqlRootQueryName = 'leadswithcontact';
LeadType.gqlColumns.push(`
contactName,
opportunityName,
collaboratorName,
contact {
     _id,
     firstName,
     lastName
 },
 opportunity {
   _id,
   name
 },
 collaborator {
   _id,
   firstName,
   lastName
 }`);

const defaultHolidayNodeMapper = HolidayType.gqlNodeMapper;
HolidayType.gqlNodeMapper = (node: any) => {
  if (node.collaborator) {
    node.collaborator.name =
      node.collaborator.firstName + ' ' + node.collaborator.lastName;
  }
  return defaultHolidayNodeMapper(node);
};

const defaultTimeImputationNodeMapper = TimeImputationType.gqlNodeMapper;
TimeImputationType.gqlNodeMapper = (node: any) => {
  if (node.collaborator) {
    node.collaborator.name =
      node.collaborator.firstName + ' ' + node.collaborator.lastName;
  }
  return defaultTimeImputationNodeMapper(node);
};

const defaultContactNodeMapper = LeadType.gqlNodeMapper;
LeadType.gqlNodeMapper = (node: any) => {
  if (node.contact) {
    node.contact.name = node.contact.firstName + ' ' + node.contact.lastName;
  }
  return defaultContactNodeMapper(node);
};

const defaultCollaboratorNodeMapper = LeadType.gqlNodeMapper;
LeadType.gqlNodeMapper = (node: any) => {
  if (node.collaborator) {
    node.collaborator.name =
      node.collaborator.firstName + ' ' + node.collaborator.lastName;
  }
  return defaultCollaboratorNodeMapper(node);
};

const defaultActivityContactNodeMapper = ActivityType.gqlNodeMapper;
ActivityType.gqlNodeMapper = (node: any) => {
  if (node.contact) {
    node.contact.name = node.contact.firstName + ' ' + node.contact.lastName;
  }
  return defaultActivityContactNodeMapper(node);
};

const defaultActivityCollaboratorNodeMapper = ActivityType.gqlNodeMapper;
ActivityType.gqlNodeMapper = (node: any) => {
  if (node.collaborator) {
    node.collaborator.name =
      node.collaborator.firstName + ' ' + node.collaborator.lastName;
  }
  return defaultActivityCollaboratorNodeMapper(node);
};

// Dashboard my tasks panel
entityMetadata.DashboardMyTasks = {};
typesConfig[DashboardEntitiesEnum.DashboardMyTasks] = DashboardMyTasksType;

// Dashboard unassigned tasks panel
entityMetadata.DashboardUnassignedTasks = {};
typesConfig[DashboardEntitiesEnum.DashboardUnassignedTasks] =
  DashboardUnassignedTasksType;

// Dashboard holidays panel
entityMetadata.DashboardHolidays = {};
typesConfig[DashboardEntitiesEnum.DashboardHolidays] = DashboardHolidaysType;

// Dashboard hot opportunities
entityMetadata.DashboardHotOpportunities = {};
typesConfig[DashboardEntitiesEnum.DashboardHotOpportunities] =
  DashboardHotOpportunitiesType;

// Dashboard estimations
entityMetadata.DashboardEstimations = {};
typesConfig[DashboardEntitiesEnum.DashboardEstimations] =
  DashboardEstimationsType;

// Dashboard proposals
entityMetadata.DashboardProposals = {};
typesConfig[DashboardEntitiesEnum.DashboardProposals] = DashboardProposalsType;

export const entityConfig = {
  entityMetadata,
  pluralNames,
};

export const qlServiceFactoryConfig: QlServiceFactoryConfig = {
  typesConfig,
};
