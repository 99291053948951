import { Comment } from 'src/generated/api/krakenn-api';

export interface CommentsState {

    commentsList: Comment[];
}

export const initialCommentsState: CommentsState = {
    commentsList: []
};


