import { MissionDbStatus } from './mission-db-status.enum';
import { OpportunityDbStatus } from './opportunity-db-status.enum';
import { CommercialImputationDbStatus } from './commercial-imputation-db-status.enum';
import { InvoiceDbStatus } from './invoice.db-status.enum';
import { HolidayDbStatus } from './holiday-db-status.enum';
import { ProposalDbStatus } from './proposal-db-status.enum';
import { ActivityType } from './activity-type';
import { TcTranslateService } from '@tc/core';

export class SelectValues {

  proposalStatus(translate: TcTranslateService): any[] {
    return [
      { value: ProposalDbStatus.Initial, label: translate.instant('ProposalStatus.Initial') },
      { value: ProposalDbStatus.InEstimation, label: translate.instant('ProposalStatus.InEstimation') },
      { value: ProposalDbStatus.InValidation, label: translate.instant('ProposalStatus.InValidation') },
      { value: ProposalDbStatus.Sent, label: translate.instant('ProposalStatus.Sent') },
      { value: ProposalDbStatus.Won, label: translate.instant('ProposalStatus.Won') },
      { value: ProposalDbStatus.Lost, label: translate.instant('ProposalStatus.Lost') },
      { value: ProposalDbStatus.Abandoned, label: translate.instant('ProposalStatus.Abandoned') }
    ];
  }

  estimationLineRiskLevel(translate: TcTranslateService): any[] {
    return [
      { value: 0, label: translate.instant('EstimationLineRiskLevel.NoRisk') },
      { value: 1, label: translate.instant('EstimationLineRiskLevel.LowRisk') },
      { value: 2, label: translate.instant('EstimationLineRiskLevel.ModerateRisk') },
      { value: 3, label: translate.instant('EstimationLineRiskLevel.HighRisk') },
      { value: 4, label: translate.instant('EstimationLineRiskLevel.Impossible') }
    ];
  }

  estimationLineStatus(translate: TcTranslateService): any[] {
    return [
      { value: 0, label: translate.instant('EstimationLineStatus.Initial') },
      { value: 1, label: translate.instant('EstimationLineStatus.InProgress') },
      { value: 2, label: translate.instant('EstimationLineStatus.InReview') },
      { value: 3, label: translate.instant('EstimationLineStatus.Ready') },
      { value: 4, label: translate.instant('EstimationLineStatus.InProposal') }
    ];
  }

  missionImputationType(translate: TcTranslateService): any[] {
    return [
      { value: 0, label: translate.instant('ImputationType.TMAOnTimeSpent') },
      { value: 1, label: translate.instant('ImputationType.TMAOnProof') },
      { value: 2, label: translate.instant('ImputationType.PROJECTOnBudget') },
      { value: 4, label: translate.instant('ImputationType.None') }
    ];
  }

  holidayStatus(translate: TcTranslateService): any[] {
    return [
      { value: HolidayDbStatus.ToBeValidated, label: translate.instant('HolidayStatus.ToBeValidated') },
      { value: HolidayDbStatus.Validated, label: translate.instant('HolidayStatus.Validated') },
      { value: HolidayDbStatus.Refused, label: translate.instant('HolidayStatus.Refused') }
    ];
  }

  holidayType(translate: TcTranslateService): any[] {
    return [
      { value: 1, label: translate.instant('HolidayType.Absent') },
      { value: 2, label: translate.instant('HolidayType.LimitedAvailability') }
    ];
  }

  invoiceStatus(translate: TcTranslateService): any[] {
    return [
      { value: InvoiceDbStatus.Initial, label: translate.instant('InvoiceStatus.Initial') },
      { value: InvoiceDbStatus.Sent, label: translate.instant('InvoiceStatus.Sent') },
      { value: InvoiceDbStatus.Payed, label: translate.instant('InvoiceStatus.Payed') },
      { value: InvoiceDbStatus.UnpaidLost, label: translate.instant('InvoiceStatus.UnpaidLost') }
    ];
  }

  opportunityStatus(translate: TcTranslateService): any[] {
    return [
      { value: OpportunityDbStatus.Received, label: translate.instant('OpportunityStatus.Received') },
      { value: OpportunityDbStatus.Contacted, label: translate.instant('OpportunityStatus.Contacted') },
      { value: OpportunityDbStatus.FirstSalesMeetingFixed, label: translate.instant('OpportunityStatus.FirstSalesMeetingFixed') },
      { value: OpportunityDbStatus.EstimationToBeDone, label: translate.instant('OpportunityStatus.EstimationTBD') },
      { value: OpportunityDbStatus.EstimationDone, label: translate.instant('OpportunityStatus.EstimationDone') },
      { value: OpportunityDbStatus.ProposalToBeDone, label: translate.instant('OpportunityStatus.ProposalTBD') },
      { value: OpportunityDbStatus.ProposalSentToFollow, label: translate.instant('OpportunityStatus.ProposalSent') },
      { value: OpportunityDbStatus.NOGO, label: translate.instant('OpportunityStatus.NOGO') },
      { value: OpportunityDbStatus.Won, label: translate.instant('OpportunityStatus.Won') },
      { value: OpportunityDbStatus.Lost, label: translate.instant('OpportunityStatus.Lost') },
      { value: OpportunityDbStatus.Abandoned, label: translate.instant('OpportunityStatus.Abandoned') },
      { value: OpportunityDbStatus.InStandBy, label: translate.instant('OpportunityStatus.InStandBy') }
    ];
  }

  commercialImputationStatus(translate: TcTranslateService): any[] {
    return [
      { value: CommercialImputationDbStatus.Manual, label: translate.instant('CIStatus.Manual') },
      { value: CommercialImputationDbStatus.GeneratedNotYetValidated, label: translate.instant('CIStatus.Generated') },
      { value: CommercialImputationDbStatus.Validated, label: translate.instant('CIStatus.Validated') },
      { value: CommercialImputationDbStatus.Abandoned, label: translate.instant('CIStatus.Abandoned') }
    ];
  }

  missionStatus(translate: TcTranslateService): any[] {
    return [
      { value: MissionDbStatus.NotStarted, label: translate.instant('MissionStatus.NotStarted') },
      { value: MissionDbStatus.InProgress, label: translate.instant('MissionStatus.InProgress') },
      { value: MissionDbStatus.Closed, label: translate.instant('MissionStatus.Closed') }
    ];
  }

  activityType(translate: TcTranslateService): any[] {
    return [
      { value: ActivityType.Call, label: translate.instant('call')},
      { value: ActivityType.Meeting, label: translate.instant('meeting')},
      { value: ActivityType.Task, label: translate.instant('task') },
      { value: ActivityType.Deadline, label: translate.instant('deadline')},
      { value: ActivityType.Email, label: translate.instant('email')},
      { value: ActivityType.Lunch, label: translate.instant('lunch') }
    ]
  }
}
