import { Injectable, Inject, Optional } from "@angular/core";
import {
    Configuration,
    BASE_PATH,
    Comment
} from "src/generated/api/krakenn-api";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommentsTable } from '../modules/comments/enums/comments-table.enum';

@Injectable({
    providedIn: "root",
})
export class CommentsService {
    public configuration = new Configuration();
    protected basePath =
        "https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0";
    protected defaultHeaders = new HttpHeaders();

    constructor(
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration,
        private http: HttpClient
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath =
                configuration.basePath || basePath || this.basePath;
        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    getComments(id: number, table: string): Observable<Comment[]> {
        let url = '';
        if(table === CommentsTable.Task) {
            url = `${this.configuration.basePath}/comment/get-task-comments/${id}`;
        }

        return this.http.get<Comment[]>(url, {withCredentials: true});
    }

    save(data: any): Observable<any> {
        const url = `${this.configuration.basePath}/comment`;
        const headers = this.defaultHeaders;
        return this.http.post<Comment>(url, data,
            {
                withCredentials: true,
                headers,
                observe: 'body',
                reportProgress: false
            });
    }

    edit(data: any): Observable<any> {
        const url = `${this.configuration.basePath}/comment`;
        const headers = this.defaultHeaders;
        return this.http.put<Comment>(url, data,
            {
                withCredentials: true,
                headers,
                observe: 'body',
                reportProgress: false
            });
    }

    delete(id: number): Observable<any> {
        const url = `${this.configuration.basePath}/comment/${id}`;
        const headers = this.defaultHeaders;
        return this.http.delete<number>(url,
            {
                withCredentials: true,
                headers,
                observe: 'body',
                reportProgress: false
            });
    }

}
