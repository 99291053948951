import { Component, ElementRef, OnInit } from '@angular/core';
import { TcTranslateService, TcGenericFormComponent } from '@tc/core';
import { Holiday, Collaborator } from 'src/generated/api/krakenn-api';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SendEmailNewHolidayRequest } from '../../../store/holidays.actions';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-holiday-detail',
  templateUrl: './holiday-detail.component.html',
  styleUrls: ['./holiday-detail.component.scss']
})
export class HolidayDetailComponent extends TcGenericFormComponent<Holiday> implements OnInit {

  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    private dialogRef: MatDialogRef<HolidayDetailComponent>,
    private store: Store<any>,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {

    super(EntitiesEnum.Holidays, entityCollectionServiceFactory, translate, elem, dateAdapter);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'collaboratorId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              disabled: this.model.collaboratorId && this.model.collaboratorId > 0,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Collaborator>(EntitiesEnum.Collaborators, term, 'id', 'firstName,lastName'),
              listValue: 'collaborator.name'
            },
            validators: {
              collaboratorId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            }
          },
          {
            key: 'label',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 255
            }
          },
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              required: true
            }
          },
          {
            key: 'stopDate',
            type: 'datepicker',
            templateOptions: {
              required: true
            }
          },
          {
            key: 'type',
            type: 'select',
            templateOptions: {
              required: true,
              options: new SelectValues().holidayType(this.translate)
            }
          },
          {
            key: 'status',
            type: 'select',
            templateOptions: {
              required: true,
              options: new SelectValues().holidayStatus(this.translate)

            }
          }
        ]
      },
      {
        fieldGroupClassName: 'display-row',
        fieldGroup: [
          {
            key: 'comments',
            type: 'textarea-editor',
            className: 'textarea-form-content textarea-form-label',
            templateOptions: {
            }
          }
        ]
      }
    ];

  }

  ngOnInit() {
    super.ngOnInit();

    const date = new Date();
    if (!this.model.startDate) {
      this.model.startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0).toString();
    }
    if (!this.model.stopDate) {
      this.model.stopDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0).toString();
    }

    this.afterSave = (model: Holiday) => {

      if (!model.id) {
        const holiday: Holiday = Object.assign({}, model);

        this.store.dispatch(new SendEmailNewHolidayRequest({ data: holiday }));
      }

      this.dialogRef.close();
    };
  }
}

