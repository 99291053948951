import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { ListActionTypes, AddListItem, EditListItem, filterListItems, CreateListItem, DuplicateListItem } from '../actions/list.actions';
import { map, tap } from 'rxjs/operators';
import { ListService } from 'src/app/services/list.service';

@Injectable()
export class ListEffects {

  constructor(
    private actions$: Actions,
    private listService: ListService
  ) { }

  @Effect({ dispatch: false })
  addListItem = this.actions$.pipe(
    ofType<AddListItem>(ListActionTypes.ADD_LIST_ITEM),
    map((action: AddListItem) => {
      this.listService.addListItem(action.payload.entityName, action.payload.args);
    })
  );

  @Effect({ dispatch: false })
  editListItem = this.actions$.pipe(
    ofType<EditListItem>(ListActionTypes.EDIT_LIST_ITEM),
    map((action: EditListItem) => {
      this.listService.editListItem(action.payload.entityName, action.payload.data, action.payload.args);
    })
  );

  @Effect({ dispatch: false })
  createListItem = this.actions$.pipe(
    ofType<CreateListItem>(ListActionTypes.CREATE_LIST_ITEM),
    map((action: CreateListItem) => {
      this.listService.createListItem(action.payload.entityName, action.payload.data, action.payload.args);
    })
  );

  filterListItems = createEffect(() =>
    this.actions$.pipe(
      ofType(filterListItems),
      tap(({ entityName, filter, order, initial }) => {
        this.listService.filterListItems(entityName, filter, order, initial);
      })),
    { dispatch: false }
  );

  @Effect({dispatch: false})
  duplicateListItem = this.actions$.pipe(
    ofType<DuplicateListItem>(ListActionTypes.DUPLICATE_LIST_ITEM),
    map((action: DuplicateListItem) => {
      this.listService.duplicateListItem(action.payload.entityName, action.payload.data, action.payload.args);
    })
  );
}
