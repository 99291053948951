import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { TcGenericListComponent, TcSmartComponent, TcListRowActionButtonsPosition, TcListSortType, TcListFilterType, TcListDisplayColumnType, TcListDefaultAction } from '@tc/core';
import { Project } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { Store } from '@ngrx/store';
import { EditListItem, AddListItem, DuplicateListItem } from 'src/app/modules/core/store/actions/list.actions';


@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  constructor(
    private store: Store<any>) {
    super();
  }

  @ViewChild('colClientNameTemplate', { static: true }) colClientNameTemplate: TemplateRef<any>;
  @ViewChild('colProposalNameTemplate', { static: true }) colProposalNameTemplate: TemplateRef<any>;

  projectsList: TcGenericListComponent<Project>;
  @ViewChild('projectsList', { static: true }) set setProjectsList(values: TcGenericListComponent<Project>) {

    this.projectsList = values;
    this.projectsList.entityName = EntitiesEnum.Projects;
  }

  ngAfterViewInit() {
    this.projectsList.service.clearCache();
    this.projectsList.service.getWithQuery({ initial: 'true' });
  }

  ngOnInit() {

    this.projectsList.hasTotalLine = true;
    this.projectsList.totalLineColumns = ['soldAmount', 'soldDays'];
    this.projectsList.totalDisplayColumn = 'name';

    this.projectsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.projectsList.showTotalInActionsHeader = true;

    this.projectsList.sortType = TcListSortType.Server;
    this.projectsList.filterType = TcListFilterType.Server;
    this.projectsList.hasFixedHeader = true;

    this.projectsList.hasAddButton = true;
    this.projectsList.addItemWhenKeyPresed = true;

    this.projectsList.hasActionsLabel = false;
    this.projectsList.isPaged = false;

    this.projectsList.onScrollDown = () => {
      this.projectsList.service.getAll();
    };

    this.projectsList.columns = [
      {
        propertyName: 'client.name',
        visible: true,
        htmlTemplate: this.colClientNameTemplate
      },
      {
        propertyName: 'proposal.name',
        visible: true
      },
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'soldAmount',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Currency
      },
      {
        propertyName: 'soldDays',
        visible: true
      },
      {
        propertyName: 'jiraProject',
        visible: true
      }
    ];

    this.projectsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Delete,
        icon: 'delete',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Duplicate,
        icon: 'content_copy',
        visible: true
      }
    ];

    this.projectsList.onRowAction = (row: Project, actionName: string) => {
      if (actionName === 'edit') {
        this.editProject(row);
      }
    };


    this.projectsList.onRowClick = (row: Project) => {
      this.editProject(row);
    };

    this.projectsList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Projects }));
    };

    this.projectsList.onDuplicateRow = (row: Project) => {
      this.store.dispatch(new DuplicateListItem({entityName: EntitiesEnum.Projects, data: row}));
    }
  }

  private editProject(row: Project) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Projects, data: row }));
  }

}
