import { Task } from 'src/generated/api/krakenn-api/model/models';

export class DashboardUnassignedTasksType {
  static className = 'UnassignedTasks';
  static gqlRootQueryName = 'unassignedtasks';
  static gqlColumns: string[] = [
    '_id',
    'jiraId',
    'name',
    'priority',
    'missionId',
    'globalPriority',
    'description',
    'terminated',
    'targetDate',
    'targetPoints',
    'nbOfComments',
    `mission {
            _id,
            name,
            status,
            project {
                name
            }
        }`,
  ];

  static gqlNodeMapper = (node: any): Task => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as Task;
  };
}
