import { Component, ElementRef, Input, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { Observable } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';
import { Comment } from 'src/generated/api/krakenn-api';
import { CommentsField } from '../../../enums/comments-field.enum';
import { CommentsTable } from '../../../enums/comments-table.enum';
import { LoadCommentsList } from '../../../store/comments.actions';
import { getCommentsForTask } from '../../../store/comments.selectors';
import { EditCommentPopupComponent } from '../edit-comment-popup/edit-comment-popup.component';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss']
})
export class CommentsListComponent extends TcFormComponent<any> implements OnInit {

  @Input()
  table: string;

  @Input()
  id: number;

  authUserId: string;

  field: string;

  configuration = [{ table: CommentsTable.Task, field: CommentsField.Task }];

  comments$: Observable<Comment[]>;

  constructor(
    translate: TcTranslateService,
    elem: ElementRef,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private store: Store<any>
  ) {

    super(translate, elem);
    // this.displayAddCommentArea = false;
    this.fields = [
      {
        fieldGroupClassName: "display-row",
        fieldGroup: [
          {
            key: "content",
            type: "textarea-editor",
            className: "textarea-form-content textarea-form-label",
            templateOptions: {},
          },
        ],
      },
    ];
  }

  addComment() {

    const data = {} as any;
    data[this.field] = this.id;
    data.collaboratorId = this.authUserId;

    this.dialog.open(EditCommentPopupComponent, {
      data,
      width: '54.5vw'
    });
  }


  ngOnInit() {

    this.field = this.configuration.find(c => c.table === this.table).field;

    this.authUserId = this.permissionService.authUser.id;

    this.store.dispatch(new LoadCommentsList({ data: { id: this.id, table: this.table } }));
    this.comments$ = this.store.pipe(select(getCommentsForTask));

  }

}
