import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-big-texts',
  templateUrl: './big-texts.component.html',
  styleUrls: ['./big-texts.component.scss']
})
export class BigTextsComponent implements OnInit {

  @Input()
  charactersNumber: number;

  @Input()
  text: string;

  isExpanded: boolean;
  splitText: string;

  constructor() { }

  ngOnInit() {
    this.isExpanded = false;
    if (this.text) {
      this.splitText = this.text.substring(0, this.charactersNumber);
    }
  }

  expandColapse($event) {
    $event.stopPropagation();
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded) {
      this.splitText = this.text;
    } else {
      this.splitText = this.text.substring(0, this.charactersNumber);
    }
  }

}
