import { Injectable } from "@angular/core";
import { TcConfigService } from "@tc/core";

@Injectable({
  providedIn: "root",
})
export class JiraUrlService {
  constructor(private configService: TcConfigService) {}

  getJiraUrl(jiraId) {
    const jiraBaseUrl = (this.configService.config as any).JIRA_BASE_URL;
    if (jiraBaseUrl && jiraId) {
      return `${jiraBaseUrl}${jiraId}`;
    }
    return "";
  }
}
