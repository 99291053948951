import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { TcSimpleFilterComponent, TcSmartComponent } from '@tc/core';
import { ToggleFilterModel } from '../../../models/toggle-filter.model';
import { EntitiesEnum } from '../../../enums/entities-enum';
import { Store } from '@ngrx/store';
import { filterListItems } from '../../../store/actions/list.actions';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent extends TcSmartComponent implements OnInit {

  @Input()
  entityName: EntitiesEnum;

  @Input()
  translatePrefix: string;

  @Input()
  toggles: ToggleFilterModel[];

  // @Input()
  // toggleProperty: string;

  filterValue: string;

  tcSimpleFilter: TcSimpleFilterComponent;
  @ViewChild('tcSimpleFilter', { static: true }) set setTcSimpleFilterComponent(values: TcSimpleFilterComponent) {
    this.tcSimpleFilter = values;
  }

  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit() {
    this.toggles.forEach(toggle => {
      if (toggle.afterLabel) {
        toggle.afterKey = this.translatePrefix + '.filter.toggles.' + toggle.afterLabel;
      }
      if (toggle.beforeLabel) {
        toggle.beforeKey = this.translatePrefix + '.filter.toggles.' + toggle.beforeLabel;
      } else {
        toggle.beforeKey = this.translatePrefix + '.filter.toggles.' + toggle.name;
      }
    });

    this.applyFilter(true);
  }

  getFilter = () => {
    const filter = {} as any;
    this.toggles.forEach(toggle => {
      if (toggle.isChecked) {
        if (filter[toggle.toggleProperty]) {
          filter[toggle.toggleProperty].value += ',' + toggle.values.join(',');
        } else {
          filter[toggle.toggleProperty] = {
            filterType: toggle.filterType,
            value: toggle.values.join(','),
          };
        }
      }
    });

    if (this.filterValue) {
      filter.anyFieldContains = this.filterValue;
    }
    return filter;
  }

  applyFilter = (initial?: boolean) => {
    this.store.dispatch(filterListItems({
      entityName: this.entityName,
      filter: this.getFilter(),
      order: null,
      initial
    }));
  }

  onSearch = (filterValue: string) => {
    this.filterValue = filterValue;
    this.applyFilter();
  }

  toogleChange = (name: string) => {
    const toggle = this.toggles.find(t => t.name === name);
    if (toggle) {
      toggle.isChecked = !toggle.isChecked;
      this.applyFilter();
    }
  }

}
