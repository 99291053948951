import { Component, ElementRef, ViewChild, OnInit, AfterViewInit, TemplateRef } from '@angular/core';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { TcTranslateService, TcGenericFormComponent } from '@tc/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { Estimation, Client, Opportunity, EstimationLine } from 'src/generated/api/krakenn-api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-estimation-detail',
  templateUrl: './estimation-detail.component.html',
  styleUrls: ['./estimation-detail.component.scss']
})
export class EstimationDetailComponent extends TcGenericFormComponent<Estimation> {
  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {

    super(EntitiesEnum.Estimations, entityCollectionServiceFactory, translate, elem, dateAdapter);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 50,
            },
          },
          {
            key: 'clientId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Client>(EntitiesEnum.Clients, term, 'id', 'name'),
              listValue: 'client.name'
            },
            validators: {
              clientId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'opportunityId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Opportunity>(EntitiesEnum.Opportunities, term, 'id', 'name'),
              listValue: 'opportunity.name'
            },
            validators: {
              opportunityId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'creationDate',
            type: 'datepicker',
            templateOptions: {
              required: true
            }
          },
          {
            key: 'targetDate',
            type: 'datepicker',
            templateOptions: {
              required: true
            }
          },
          {
            key: 'targetMargin',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: false
            },
          }
        ]
      }
    ];
  }

  private estimationIdIsDefined(): boolean {
    return this.model.id && this.model.id > 0;
  }
}
