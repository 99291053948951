import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { AddComment, AddCommentSuccess, EditComment } from '../../../store/comments.actions';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-edit-comment-popup',
  templateUrl: './edit-comment-popup.component.html',
  styleUrls: ['./edit-comment-popup.component.scss']
})
export class EditCommentPopupComponent extends TcFormComponent<any> implements OnInit {

  title = '';

  constructor(
    private dialogRef: MatDialogRef<EditCommentPopupComponent>,
    translate: TcTranslateService,
    private store: Store<any>,
    elem: ElementRef,
    private dateAdapter: DateAdapter<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(translate, elem);
    this.fields = [
      {
        key: 'collaboratorName',
        type: 'input',
        hideExpression: '!model.id',
        templateOptions: {
          type: 'text',
          disabled: true
        }
      },
      {
        key: 'creationDate',
        type: 'datepicker',
        hideExpression: '!model.id',
        templateOptions: {
          type: 'text',
          disabled: true
        },
      },
      {
        fieldGroupClassName: "display-row",
        fieldGroup: [
          {
            key: "content",
            type: "textarea-editor",
            className: "textarea-form-content textarea-form-label",
            templateOptions: {
              required: true
            },
          },
        ],
      }
    ];
  }

  onClose() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.model = Object.assign({}, this.data);
    if (this.data.creationDate) {
      this.model.creationDate = moment(new Date(this.data.creationDate)).format('x');
    }
    super.ngOnInit();
  }

  submit() {
    if (this.form.valid) {
      if (!this.data?.id) {
        delete (this.model as any).collaboratorName;
        this.model.creationDate = this.dateAdapter.deserialize(new Date()).format('YYYY-MM-DD HH:mm:ss');
        this.store.dispatch(new AddComment(this.model));
      } else {
        this.model.creationDate = this.dateAdapter.deserialize(this.model.creationDate).format('YYYY-MM-DD HH:mm:ss');
        this.model.lastUpdate = this.dateAdapter.deserialize(new Date()).format('YYYY-MM-DD HH:mm:ss');
        this.store.dispatch(new EditComment(this.model));
      }
      this.dialogRef.close();
    }
  }

}
