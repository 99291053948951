import { DashboardEntitiesEnum } from './../modules/dashboard/dashboard-entities-enum';
import { Injectable, Optional, Inject } from '@angular/core';
import { EntitiesEnum } from '../modules/core/enums/entities-enum';
import {
  TcConfirmDialogComponent,
  TcGenericEntity,
  TcListFilterModel,
  TcListSortModel,
  TcTranslateService,
} from '@tc/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateEditPopupComponent } from '../modules/core/components/smart/create-edit-popup/create-edit-popup.component';
import { InvoiceDetailComponent } from '../modules/invoices/components/smart/invoice-detail/invoice-detail.component';
import { OpportunityDetailComponent } from '../modules/opportunities/components/smart/opportunity-detail/opportunity-detail.component';
import { EstimationDetailComponent } from '../modules/estimations/components/smart/estimation-detail/estimation-detail.component';
import { EstimationLineDetailComponent } from '../modules/estimations/components/smart/estimation-line-detail/estimation-line-detail.component';
import { ProposalDetailComponent } from '../modules/proposals/components/smart/proposal-detail/proposal-detail.component';
import { CommercialImputationDetailComponent } from '../modules/commercial-imputations/components/smart/commercial-imputation-detail/commercial-imputation-detail.component';
import { HolidayDetailComponent } from '../modules/holidays/components/smart/holiday-detail/holiday-detail.component';
import { LeadDetailComponent } from '../modules/leads/components/smart/lead-detail/lead-detail.component';
import { CollaboratorDetailComponent } from '../modules/collaborators/components/smart/collaborator-detail/collaborator-detail.component';
import { ClientDetailComponent } from '../modules/clients/components/smart/client-detail/client-detail.component';
import { ProjectDetailComponent } from '../modules/projects/components/smart/project-detail/project-detail.component';
import { MissionDetailComponent } from '../modules/missions/components/smart/mission-detail/mission-detail.component';
import { TaskDetailComponent } from '../modules/tasks/components/smart/task-detail/task-detail.component';
import { TimeImputationDetailComponent } from '../modules/time-imputations/components/smart/time-imputation-detail/time-imputation-detail.component';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import {
  BASE_PATH,
  Configuration,
  Estimation,
  Proposal,
} from 'src/generated/api/krakenn-api';
import { AddOpportunityProposal } from '../modules/opportunities/store/opportunities.actions';
import { ProposalDbStatus } from '../modules/core/enums/proposal-db-status.enum';
import { OpportunityDbStatus } from '../modules/core/enums/opportunity-db-status.enum';
import { CreateListItem } from '../modules/core/store/actions/list.actions';
import { EBADF } from 'constants';
import { CreateOpportunityEstimation } from './../modules/opportunities/store/opportunities.actions';
import { DateAdapter } from '@angular/material/core';
import { ListRefreshService } from './list-refresh.service';

@Injectable({
  providedIn: 'root',
})
export class ListService {
  public configuration = new Configuration();
  protected basePath =
    'https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0';

  constructor(
    private dialog: MatDialog,
    private store: Store<any>,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
    private translate: TcTranslateService,
    private readonly listRefreshService: ListRefreshService,
    private dateAdapter: DateAdapter<any>
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  addListItem(entityName: EntitiesEnum, args?: any) {
    this.openPopup(entityName, null, args);
  }

  editListItem(entityName: EntitiesEnum, data: TcGenericEntity, args?: any) {
    this.openPopup(entityName, data, args);
  }

  createListItem(entityName: EntitiesEnum, data: TcGenericEntity, args?: any) {
    this.createItemFromEntity(entityName, data, args);
  }

  filterListItems = (
    entityName: EntitiesEnum | DashboardEntitiesEnum,
    filterModel: TcListFilterModel,
    order?: TcListSortModel,
    initial?: boolean
  ) => {
    const service = this.entityCollectionServiceFactory.create(entityName);

    const query = {
      filter: JSON.stringify(filterModel),
    } as any;

    if (initial) {
      query.initial = 'true';
    }

    service.clearCache();
    if (order) {
      query.orderBy = JSON.stringify(order);
    }

    service.getWithQuery(query);
  };

  private openPopup(
    entityName: EntitiesEnum,
    data?: TcGenericEntity,
    args?: any
  ) {
    this.dialog
      .open(CreateEditPopupComponent, {
        panelClass: [`${entityName}-detail`.toLowerCase(), 'add-edit-popup'],
        autoFocus: true,
        data: {
          data,
          component: this.getDetailComponent(entityName),
          entityName,
          args,
        },
      })
      .afterClosed()
      .subscribe((model) => {
        // Check if Entity has option to create a new entity on status change
        // tslint:disable: no-string-literal
        if (args && args['toCreate']) {
          // Check if there is a change in status
          if (data && model && data['status'] !== model['status']) {
            this.createItemFromEntity(entityName, model, args);
          }
        }
        if (args && args['refreshListAfterClose']) {
          this.listRefreshService.emitRefresh(args['refreshListAfterClose']);
        }
      });
  }

  private getDetailComponent(entityName: EntitiesEnum) {
    switch (entityName) {
      case EntitiesEnum.Opportunities:
        return OpportunityDetailComponent;
      case EntitiesEnum.Estimations:
        return EstimationDetailComponent;
      case EntitiesEnum.EstimationLines:
        return EstimationLineDetailComponent;
      case EntitiesEnum.Proposals:
        return ProposalDetailComponent;
      case EntitiesEnum.Invoices:
        return InvoiceDetailComponent;
      case EntitiesEnum.CommercialImputations:
        return CommercialImputationDetailComponent;
      case EntitiesEnum.Holidays:
        return HolidayDetailComponent;
      case EntitiesEnum.Leads:
        return LeadDetailComponent;
      case EntitiesEnum.Collaborators:
        return CollaboratorDetailComponent;
      case EntitiesEnum.Clients:
        return ClientDetailComponent;
      case EntitiesEnum.Projects:
        return ProjectDetailComponent;
      case EntitiesEnum.Missions:
        return MissionDetailComponent;
      case EntitiesEnum.Tasks:
        return TaskDetailComponent;
      case EntitiesEnum.TimeImputations:
        return TimeImputationDetailComponent;
      default:
        return null;
    }
  }

  createItemFromEntity(
    entityName: EntitiesEnum,
    data: TcGenericEntity,
    args: any
  ) {
    switch (entityName) {
      case EntitiesEnum.Opportunities: {
        switch (args.toCreate.createEntity) {
          case EntitiesEnum.Proposals:
            this.generateProposalFromOpportunity(data);
            break;
          case EntitiesEnum.Estimations:
            this.generateEstimationFromOpportunity(data);
            break;
        }
      }
    }
  }

  generateEstimationFromOpportunity(model: TcGenericEntity) {
    const opportunityId: any = model.id;

    const estimation: any = {
      id: 0,
      clientId: 0,
      opportunityId,
      opportunity: {
        id: opportunityId,
        name: model['name'],
      },
      name: model['name'],
      creationDate: Date.now().toString(),
      targetDate: '',
    };

    this.store.dispatch(
      new CreateOpportunityEstimation({
        entityName: EntitiesEnum.Estimations,
        data: estimation,
      })
    );
  }

  generateProposalFromOpportunity(data: TcGenericEntity) {
    // tslint:disable-next-line: no-string-literal
    if (data['status'] === OpportunityDbStatus.ProposalSentToFollow) {
      const dialog = this.dialog.open(TcConfirmDialogComponent, {
        data: {
          noText: 'opportunity-detail-dialog.proposal.no',
          title: 'opportunity-detail-dialog.proposal.title',
          yesText: 'opportunity-detail-dialog.proposal.yes',
          message: 'opportunity-detail-dialog.proposal.message',
        },
        panelClass: 'opportunity-detail-proposal-dialog-panel',
      });

      dialog.afterClosed().subscribe((result) => {
        if (result === 'yes') {
          this.createProposal(data);
        }
      });
    }
  }

  createProposal(model: TcGenericEntity) {
    const filter = {
      opportunityId: {
        filterType: 'Equal',
        value: model.id + '',
      },
    } as TcListFilterModel;

    const service = this.entityCollectionServiceFactory.create(
      EntitiesEnum.Estimations
    );

    const query = {
      filter: JSON.stringify(filter),
    } as any;

    service.clearCache();

    service.getWithQuery(query).subscribe(
      (data) => {
        let estimationId = null;
        if (data.length > 0) {
          estimationId = data[0]['id'];
        }

        const proposal: Proposal = {
          id: 0,
          name: model['name'],
          sentDate: this.dateAdapter
            .deserialize(new Date())
            .format('YYYY-MM-DD'),
          status: ProposalDbStatus.Sent,
          estimationId,
        };

        this.store.dispatch(
          new AddOpportunityProposal({
            entityName: EntitiesEnum.Proposals,
            data: proposal,
          })
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  duplicateListItem(
    entityName: EntitiesEnum,
    data: TcGenericEntity,
    args?: any
  ) {
    const clonedData: TcGenericEntity = { ...data, id: null };

    if (clonedData.hasOwnProperty('name')) {
      clonedData['name'] = `${this.translate.instant(
        'tc-duplicate-list-message.duplicate_of'
      )} ${clonedData['name']}`;
    }
    this.openPopup(entityName, clonedData, args);
  }
}
