import { createAction, props } from '@ngrx/store';
import { TcAction } from "@tc/auth";
import { TcGenericEntity } from "@tc/core";
import { Collaborator } from 'src/generated/api/krakenn-api';
import { CollaboratorWorkload } from '../collaborators.interfaces';

export enum CollaboratorsActionTypes {
  LOG_AS_USER = "[Collaborators] Login as user",
  GET_ACTIVE_COLLABORATORS = "[Collaborators] Get Active Collaborators",
  GET_ACTIVE_COLLABORATORS_SUCCESS = "[Collaborators] Get Active Collaborators Success",
  GET_COLLABORATORS_WORKLOAD = "[Collaborators] Get Active Collaborators Workload",
  GET_COLLABORATORS_WORKLOAD_SUCCESS = "[Collaborators] Get Collaborators Workload Success"
}

export class LogAsUser implements TcAction {
  readonly type = CollaboratorsActionTypes.LOG_AS_USER;
  constructor(
    public payload: {
      data: TcGenericEntity;
      args?: any;
    }
  ) { }
}

export class GetActiveCollaborators implements TcAction {
  readonly type = CollaboratorsActionTypes.GET_ACTIVE_COLLABORATORS;
  constructor(
  ) { }
}

export class GetActiveCollaboratorsSuccess implements TcAction {
  readonly type = CollaboratorsActionTypes.GET_ACTIVE_COLLABORATORS_SUCCESS;
  constructor(
    public payload: Collaborator[]
  ) { }
}

export class GetCollaboratorsWorkload implements TcAction {
  readonly type = CollaboratorsActionTypes.GET_COLLABORATORS_WORKLOAD;
  constructor(
    public payload: {
      startDate: any,
      endDate: any
    }
  ) { }
}

export class GetCollaboratorsWorkloadSuccess implements TcAction {
  readonly type = CollaboratorsActionTypes.GET_COLLABORATORS_WORKLOAD_SUCCESS;
  constructor(
    public payload: CollaboratorWorkload[]
  ) { }
}

export type CollaboratorActions =
  | LogAsUser
  | GetActiveCollaborators
  | GetActiveCollaboratorsSuccess
  | GetCollaboratorsWorkload
  | GetCollaboratorsWorkloadSuccess;
