import { TcAction } from "@tc/auth";
import { TcGenericEntity } from "@tc/core";
import { EntitiesEnum } from "../../core/enums/entities-enum";

export enum OpportunitiesActionTypes {
  ADD_OPPORTUNITY_PROPOSAL = "[Opportunity] Add Opportunity Proposal",
  CREATE_OPPORTUNITY_ESTIMATION = "[Opportunity] Create Opportunity Estimation",
}

export class AddOpportunityProposal implements TcAction {
  readonly type = OpportunitiesActionTypes.ADD_OPPORTUNITY_PROPOSAL;

  constructor(
    public payload: {
      entityName: EntitiesEnum;
      data: TcGenericEntity;
      args?: any;
    }
  ) {}
}

export class CreateOpportunityEstimation implements TcAction {
  readonly type = OpportunitiesActionTypes.CREATE_OPPORTUNITY_ESTIMATION;

  constructor(
    public payload : {
      entityName: EntitiesEnum;
      data: TcGenericEntity;
      args?: any
    }
  ) {}
}

export type OpportunitiesActions = AddOpportunityProposal | CreateOpportunityEstimation;
