import { Component, OnInit } from '@angular/core';
import { TcPage, IPageState } from '@tc/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss']
})
export class ProjectsPageComponent extends TcPage implements OnInit {

  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'projects-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }

}
