import { Injectable, Inject, Optional } from "@angular/core";
import {
    Configuration,
    BASE_PATH,
    Activity
} from "src/generated/api/krakenn-api";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivitysTable } from '../modules/leads/enums/activitys-table.enum';

@Injectable({
    providedIn: "root",
})
export class ActivitysService {
    public configuration = new Configuration();
    protected basePath =
        "https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0";
    protected defaultHeaders = new HttpHeaders();

    constructor(
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration,
        private http: HttpClient
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath =
                configuration.basePath || basePath || this.basePath;
        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    getActivitys(id: number, table: string): Observable<Activity[]> {
        let url = '';
        if(table === ActivitysTable.Lead) {
            url = `${this.configuration.basePath}/activity/get-lead-activitys/${id}`;
        }

        return this.http.get<Activity[]>(url, {withCredentials: true});
    }

    save(data: any): Observable<any> {
        const url = `${this.configuration.basePath}/activity`;
        const headers = this.defaultHeaders;
        return this.http.post<Activity>(url, data,
            {
                withCredentials: true,
                headers,
                observe: 'body',
                reportProgress: false
            });
    }

    edit(data: any): Observable<any> {
        const url = `${this.configuration.basePath}/activity`;
        const headers = this.defaultHeaders;
        return this.http.put<Activity>(url, data,
            {
                withCredentials: true,
                headers,
                observe: 'body',
                reportProgress: false
            });
    }

    delete(id: number): Observable<any> {
        const url = `${this.configuration.basePath}/activity/${id}`;
        const headers = this.defaultHeaders;
        return this.http.delete<number>(url,
            {
                withCredentials: true,
                headers,
                observe: 'body',
                reportProgress: false
            });
    }

    markActivityAsDone(id: number): Observable<any> {
        const url = `${this.configuration.basePath}/activity/mark-activity-as-done/${id}`;
        const headers = this.defaultHeaders;
        return this.http.post<number>(url,
            {
                withCredentials: true,
                headers,
                observe: 'body',
                reportProgress: false
            });
    }

}
