import { Client } from '../api/krakenn-api';

export class ClientType {
  static className = 'Client';
  static gqlRootQueryName = 'client';
  static gqlColumns: string[] = [
      '_id',
      'shortName',
      'name',
      'address1',
      'address2',
      'postalCode',
      'city',
      'country',
      'incorporationNumber',
      'invoiceContact',
      'vatPercentage',
      'payementDays',
      'vatText',
      'invoiceEmailText',
      'invoiceEmailList',
  ];

  static gqlNodeMapper = (node: any): Client => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Client;
    }

}
