import { Component, ElementRef } from '@angular/core';
import { TcGenericFormComponent, TcTranslateService } from '@tc/core';
import { Proposal, Estimation } from 'src/generated/api/krakenn-api';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.scss']
})
export class ProposalDetailComponent extends TcGenericFormComponent<Proposal> {

  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {

    super(EntitiesEnum.Proposals, entityCollectionServiceFactory, translate, elem, dateAdapter);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'estimationId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Estimation>(EntitiesEnum.Estimations, term, 'id', 'name'),
              listValue: 'estimation.name'
            },
            validators: {
              estimationId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            }
          },
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 50
            }
          },
          {
            key: 'sentDate',
            type: 'datepicker',
            templateOptions: {
              required: false
            }
          },
          {
            key: 'status',
            type: 'select',
            templateOptions: {
              required: true,
              options: new SelectValues().proposalStatus(this.translate)
            },
          }
        ]
      }
    ];

  }

}
