import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeImputationsListComponent } from './components/smart/time-imputations-list/time-imputations-list.component';
import { TimeImputationDetailComponent } from './components/smart/time-imputation-detail/time-imputation-detail.component';
import { TcCoreModule } from '@tc/core';
import { CoreModule } from '../core/core.module';

const smartComponents = [TimeImputationsListComponent, TimeImputationDetailComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule
  ],
  entryComponents: [TimeImputationDetailComponent],
  exports: [...smartComponents]
})
export class TimeImputationsModule { }
