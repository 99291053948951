import { EstimationLine } from '../api/krakenn-api';

export class EstimationLineType {
  static className = 'EstimationLine';
  static gqlRootQueryName = 'estimationline';
  static gqlColumns: string[] = [
      '_id',
      'estimationId',
      'category',
      'name',
      'workloadProduction',
      'workloadSales',
      'riskLevel',
      'status',
  ];

  static gqlNodeMapper = (node: any): EstimationLine => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as EstimationLine;
    }

}
