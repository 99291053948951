import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, InjectionToken } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  TcCoreModule,
  TcApiConfig,
  TcAppReducers,
  TcMetaReducers,
  TC_TINY_CONFIG,
  TC_LIST_DEFAULT_NB_LINES,
} from "@tc/core";
import { TcLayoutModule } from "@tc/layout";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { MatButtonModule } from "@angular/material/button";
import { entityConfig, qlServiceFactoryConfig } from "./entity-metadata";
import {
  NgrxDataModule,
  DefaultDataServiceConfig,
  EntityDispatcherDefaultOptions,
  DefaultDataServiceFactory,
  PersistenceResultHandler,
  EntityCollectionReducerMethodsFactory,
} from "ngrx-data";
import { EstimationsModule } from "./modules/estimations/estimations.module";
import { ClientsModule } from "./modules/clients/clients.module";
import { OpportunitiesModule } from "./modules/opportunities/opportunities.module";
import { ProposalsModule } from "./modules/proposals/proposals.module";
import { CoreModule } from "./modules/core/core.module";
import { CollaboratorsModule } from "./modules/collaborators/collaborators.module";
import { MissionsModule } from "./modules/missions/missions.module";
import { ProjectsModule } from "./modules/projects/projects.module";
import { HolidaysModule } from "./modules/holidays/holidays.module";
import { LeadsModule } from "./modules/leads/leads.module";
import { ListPageComponent } from "./pages/list-page/list-page.component";
import { InvoicesModule } from "./modules/invoices/invoices.module";
import { LoginModule } from "./modules/login/login.module";
import { localStorageSync } from "ngrx-store-localstorage";
import { StoreModule, ActionReducer, MetaReducer } from "@ngrx/store";
import {
  TcAuthModule,
  TC_AUTH_PROVIDERS_CONFIG,
  TC_AUTH_LOGIN_URL,
  TC_AUTH_LOGOUT_URL,
} from "@tc/auth";
import { TcGoogleLoginProvider } from "@tc/google-login-provider";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HTTPCredentialsInterceptor } from "./modules/core/interceptors/http-credentials.interceptor";
import { CommercialImputationsModule } from "./modules/commercial-imputations/commercial-imputations.module";
import { GraphQLModule } from "./graphql.module";
import { HttpClientModule } from "@angular/common/http";
import { TestPageComponent } from "./pages/test-page/test-page.component";
import { QlDefaultDataServiceFactory } from "./services/core/ql-default-data-service-factory";
import { QlServiceFactoryConfig } from "./services/core/ql-service-factory-config";
import { InvoicesPageComponent } from "./pages/invoices-page/invoices-page.component";
import { AdditionalPropertyPersistenceResultHandler } from "./services/additional-property-persistence";
import { AdditionalEntityCollectionReducerMethodsFactory } from "./services/additional-entity-collection-reducer-methods.factory";
import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { TasksModule } from "./modules/tasks/tasks.module";
import { TimeImputationsModule } from "./modules/time-imputations/time-imputations.module";
import { HolidaysPageComponent } from "./pages/holidays-page/holidays-page.component";
import { ClientsPageComponent } from "./pages/clients-page/clients-page.component";
import { CollaboratorsPageComponent } from "./pages/collaborators-page/collaborators-page.component";
import { MissionsPageComponent } from "./pages/missions-page/missions-page.component";
import { TasksPageComponent } from "./pages/tasks-page/tasks-page.component";
import { ProposalsPageComponent } from "./pages/proposals-page/proposals-page.component";
import { ProjectsPageComponent } from "./pages/projects-page/projects-page.component";
import { OpportunitiesPageComponent } from "./pages/opportunities-page/opportunities-page.component";
import { CommercialImputationsPageComponent } from "./pages/commercial-imputations-page/commercial-imputations-page.component";
import { EstimationsPageComponent } from "./pages/estimations-page/estimations-page.component";
import { TimeImputationsPageComponent } from "./pages/time-imputations-page/time-imputations-page.component";
import { EstimationLinesPageComponent } from "./pages/estimation-lines-page/estimation-lines-page.component";
import { FormlyModule } from "@ngx-formly/core";
import { AppFormlyAutocompleteComponent } from "./modules/core/autocomplete.component";
import { BASE_PATH } from "src/generated/api/krakenn-api";
import { EditorModule } from "@tinymce/tinymce-angular";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { ReportingPageComponent } from "./pages/reporting-page/reporting-page.component";
import { MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CommentsModule } from './modules/comments/comments.module';
import { ContactsModule } from './modules/contacts/contacts.module';
import { LeadsPageComponent } from "./pages/leads-page/leads-page.component";
import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';
import { CookieService } from 'ngx-cookie-service';
import { TasksGridPageComponent } from './pages/tasks-grid-page/tasks-grid-page.component';
import { TasksGridModule } from "./modules/tasks-grid/tasks-grid.module";
import { ContactEditorFieldComponent } from './modules/leads/components/smart/contact-editor-field/contact-editor-field.component';

export function getTcTinyConfig(): any {
  return {
    plugins: [
      `print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons`,
    ],
    menubar: "[]",
    toolbar: `undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl`,
  } as any;
}

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: require("../assets/config.json").API_BASE_PATH,
  timeout: 60000,
  delete404OK: false,
};

const entityDispatcherDefaultOptions: EntityDispatcherDefaultOptions = {
  optimisticAdd: false,
  optimisticDelete: false,
  optimisticUpdate: false,
  optimisticUpsert: false,
  optimisticSaveEntities: false,
};

const apiConfig: TcApiConfig = {};

const modules = [
  CoreModule,
  ClientsModule,
  OpportunitiesModule,
  EstimationsModule,
  ProposalsModule,
  CollaboratorsModule,
  MissionsModule,
  ProjectsModule,
  HolidaysModule,
  LeadsModule,
  InvoicesModule,
  LoginModule,
  CommercialImputationsModule,
  DashboardModule,
  CommercialImputationsModule,
  TasksModule,
  TimeImputationsModule,
  CommentsModule,
  ContactsModule,
  TasksGridModule
];

const pages = [
  ListPageComponent,
  InvoicesPageComponent,
  HolidaysPageComponent,
  LeadsPageComponent,
  DashboardPageComponent,
  ClientsPageComponent,
  CollaboratorsPageComponent,
  MissionsPageComponent,
  TasksPageComponent,
  ProposalsPageComponent,
  ProjectsPageComponent,
  OpportunitiesPageComponent,
  CommercialImputationsPageComponent,
  EstimationsPageComponent,
  TimeImputationsPageComponent,
  EstimationLinesPageComponent,
  ReportingPageComponent,
  ContactsPageComponent,
  TasksGridPageComponent
];

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ["tc-auth"],
    rehydrate: true,
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [
  ...TcMetaReducers,
  localStorageSyncReducer,
];

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
      dateInput: 'DD/MM/YYYY',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [...pages, AppComponent, TestPageComponent],
  imports: [
...modules,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TcLayoutModule,

    TcCoreModule,
    TcAuthModule,
    EditorModule,

    MatButtonModule,

    StoreModule.forRoot(TcAppReducers, { metaReducers }),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: "KrakennFront",
        }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    NgrxDataModule.forRoot(entityConfig),

    GraphQLModule,

    HttpClientModule,
    FormlyModule.forRoot({
      types: [
        {
          name: "autocomplete-list",
          component: AppFormlyAutocompleteComponent,
          wrappers: ["form-field"],
        },
        {
          name: "contact-editor-field",
          component: ContactEditorFieldComponent,
          wrappers: ["form-field"]
        }
      ],
    }),
  ],
  providers: [
    CookieService,
    { provide: BASE_PATH, useValue: require("../assets/config.json").API_BASE_PATH },
    { provide: TcApiConfig, useValue: apiConfig },
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    {
      provide: EntityDispatcherDefaultOptions,
      useValue: entityDispatcherDefaultOptions,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPCredentialsInterceptor,
      multi: true,
    },
    { provide: QlServiceFactoryConfig, useValue: qlServiceFactoryConfig },
    {
      provide: DefaultDataServiceFactory,
      useClass: QlDefaultDataServiceFactory,
    },
    {
      provide: TC_AUTH_PROVIDERS_CONFIG,
      useValue: [
        {
          id: "Google",
          provider: new TcGoogleLoginProvider(
            require("../assets/config.json").GOOGLE_CLIENT_ID
          ),
        },
      ],
    },
    {
      provide: TC_AUTH_LOGIN_URL,
      useValue: require("../assets/config.json").TC_AUTH_LOGIN_URL,
    },
    {
      provide: TC_AUTH_LOGOUT_URL,
      useValue: require("../assets/config.json").TC_AUTH_LOGOUT_URL,
    },
    { provide: TC_TINY_CONFIG, useValue: getTcTinyConfig() },
    {
      provide: PersistenceResultHandler,
      useClass: AdditionalPropertyPersistenceResultHandler,
    },
    {
      provide: EntityCollectionReducerMethodsFactory,
      useClass: AdditionalEntityCollectionReducerMethodsFactory,
    },
    {
      provide: TC_LIST_DEFAULT_NB_LINES,
      useValue: require("../assets/config.json").DEFAULT_NB_LINES,
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'fr-FR',
    },
    {
      provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
