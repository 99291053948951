import { Component, OnInit } from '@angular/core';
import { TcPage, IPageState } from '@tc/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-estimations-page',
  templateUrl: './estimations-page.component.html',
  styleUrls: ['./estimations-page.component.scss']
})
export class EstimationsPageComponent extends TcPage implements OnInit {

  constructor(
    store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'estimations-page.title'
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
