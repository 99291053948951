import { Injectable, Inject, Optional } from "@angular/core";
import {
  Configuration,
  BASE_PATH,
  Task
} from "src/generated/api/krakenn-api";
import { EntityCollectionServiceFactory } from "ngrx-data";
import { TcNotificationService, TcGenericEntity } from "@tc/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";
import { AddTaskMassResponse } from "../modules/tasks/interfaces/AddTaskMassResponse";
import * as moment from "@tc/core/node_modules/moment";

@Injectable({
  providedIn: "root",
})
export class TasksService {
  public configuration = new Configuration();
  protected basePath =
    "https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0";
  protected defaultHeaders = new HttpHeaders();

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private http: HttpClient,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
    private notificationService: TcNotificationService,
    private dialog: MatDialog,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  terminateTask(data: any): Observable<any> {
    const url = `${this.configuration.basePath}/task/terminate`;
    const headers = this.defaultHeaders;
    return this.http.post<Task>(url, data,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      });
  }

  addMultipleTasks(data: any): Observable<any> {
    const mission = data.mission;
    const tasks: string = data.tasks;

    // Split string into respective rows
    const rows = this.convertInput(tasks);

    const tasksData = [];
    const colCount = rows[0].length;
    const cols = ['description', 'type', 'targetpoints', 'collaborators'];

    // calculate target date
    const curDate = new Date();
    curDate.setDate(curDate.getDate() + (curDate.getDay() === 3 ? 0 : 7));
    const targetDate = this.getNextDayOfWeek(curDate, 3);

    for (let i = 0; i < rows.length; i++) {
      const taskArr = rows[i];

      // remove blank line
      if (taskArr.length === 1 && taskArr[0] === '' && i === rows.length - 1)
        continue;

      const task: any = {};
      task.lineNumber = i + 1;
      task.targetDate = moment(targetDate).format('YYYY-MM-DD');
      for (let j = 0; j < colCount; j++) {
        task[cols[j].toLowerCase()] = taskArr[j];
      }

      if (!task.hasOwnProperty('priority'))
        task.priority = 999 - i; // Priority=999-line number (from the copy-paste order)

      tasksData.push(task);
    }

    const tasksObj = {
      mission,
      cols,
      tasks: tasksData
    };

    const url = `${this.configuration.basePath}/task/add-multiple-tasks`;
    const headers = this.defaultHeaders;
    return this.http.post<AddTaskMassResponse>(url, tasksObj,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      });
  }

  // Calculate the next day of week date
  getNextDayOfWeek = (date: Date, dayOfWeek) => {
    const resultDate = new Date(date.getTime());
    resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay() - 1) % 7 + 1);
    return resultDate;
  }

  convertInput = (data: string): any[][] => {
    let cr = false;
    let ignore = false;
    let str = "";
    const rows = [];
    let row = [];

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < data.length; i++) {
      let c = data[i];

      if (ignore === true && c !== '"') {
        str += c;
      } else {
        switch (c) {
          case '"':
            ignore = !ignore;
            // str += c;
            cr = false;
            break;
          case "\r":
            cr = true;
            break;
          case "\n":
            if (ignore === false) {
              row.push(str);
              rows.push(row);
              row = [];
              str = "";
              c = "";
              cr = false;
            }
            break;
          case "\t":
            row.push(str);
            str = "";
            cr = false;
            break;
          default:
            str += c;
            cr = false;
            break;
        }
      }
    }

    row.push(str);
    rows.push(row);

    return rows;
  }

}
