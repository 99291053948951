import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { Estimation } from 'src/generated/api/krakenn-api';
import { TcGenericListComponent, TcSmartComponent, TcListRowActionButtonsPosition, TcListSortType, TcListFilterType, TcListDisplayColumnType, TcListDefaultAction, FilterTypesEnum, TcFilterTypes } from '@tc/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { Store } from '@ngrx/store';
import { EditListItem, AddListItem, DuplicateListItem } from 'src/app/modules/core/store/actions/list.actions';
import * as moment from 'moment';

@Component({
  selector: 'app-estimations-list',
  templateUrl: './estimations-list.component.html',
  styleUrls: ['./estimations-list.component.scss']
})
export class EstimationsListComponent extends TcSmartComponent implements OnInit, AfterViewInit {
  listId = 'estimations-list';

  constructor(
    private store: Store<any>,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  estimationsList: TcGenericListComponent<Estimation>;
  @ViewChild('estimationsList', { static: true }) set setEstimationsList(values: TcGenericListComponent<Estimation>) {
    this.estimationsList = values;
    this.estimationsList.entityName = EntitiesEnum.Estimations;
  }

  ngAfterViewInit() {
  }

  ngOnInit() {

    // this.activatedRoute.queryParams.subscribe(s => {
    //   if (s.clientId) {
    //     this.estimationsList.queryParams = {
    //       clientId: s.clientId
    //     };
    //   } else {
    //     if (this.estimationsList.service) {
    //       this.estimationsList.service.getAll();
    //     }
    //   }

    // });

    this.estimationsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.estimationsList.showTotalInActionsHeader = true;

    this.estimationsList.sortType = TcListSortType.Server;
    this.estimationsList.filterType = TcListFilterType.Server;
    this.estimationsList.hasFixedHeader = true;

    this.estimationsList.hasAddButton = true;
    this.estimationsList.addItemWhenKeyPresed = true;

    this.estimationsList.hasActionsLabel = false;
    this.estimationsList.isPaged = false;

    this.estimationsList.onScrollDown = () => {
      this.estimationsList.service.getAll();
    };

    this.estimationsList.filters = [
      {
        key: TcFilterTypes.anyFieldContains,
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.anyFieldContains,
        label: `${this.listId}.filter-label`,
        options: {
          minLength: 1,
          debounceTime: 500,
        }
      },
      {
        key: 'targetDate',
        type: TcFilterTypes.daterange,
        label: `${this.listId}.filter.targetDate`,
        hidden: false,
        options: {
          format: 'DD/MM/YYYY',
          default: {
            start: moment(new Date(), 'DD/MM/YYYY'),
            end: moment(new Date(), 'DD/MM/YYYY').add(1, "month"),
          },
          hasReset: true
        }
      },
    ];

    this.estimationsList.columns = [
      {
        propertyName: 'client.name',
        visible: true,
      },
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'creationDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'targetDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'targetMargin',
        visible: true
      },
    ];

    this.estimationsList.rowActions = [
      {
        actionName: 'estimation-lines',
        icon: 'open_in_new',
        visible: true
      },
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Delete,
        icon: 'delete',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Duplicate,
        icon: 'content_copy',
        visible: true
      }
    ];

    this.estimationsList.onRowAction = (row: Estimation, actionName: string) => {
      if (actionName === 'edit') {
        this.editEstimation(row);
      }
      if (actionName === 'estimation-lines') {
        this.router.navigate(['/estimation-lines/'], { queryParams: { estimationId: row.id } });
      }
    };

    this.estimationsList.onRowClick = (row: Estimation) => {
      this.editEstimation(row);
    };

    this.estimationsList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Estimations }));
    };

    this.estimationsList.onDuplicateRow = (row: Estimation) => {
      this.store.dispatch(new DuplicateListItem({ entityName: EntitiesEnum.Estimations, data: row }));
    }
  }

  private editEstimation(row: Estimation) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Estimations, data: row }));
  }

}
