import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { AppFormlyAutocompleteComponent } from 'src/app/modules/core/autocomplete.component';

@Component({
  selector: 'app-contact-editor-field',
  templateUrl: './contact-editor-field.component.html',
  styleUrls: ['./contact-editor-field.component.scss']
})
export class ContactEditorFieldComponent extends AppFormlyAutocompleteComponent implements OnInit, OnDestroy{
  addNewContact($event){
    if (this.to.addNewContact) {
      this.to.addNewContact($event);
    }
  }

  viewContact($event) {
    if (this.to.viewContact) {
      this.to.viewContact($event);
    }
  }
}
