import { Injectable, Optional, Inject } from "@angular/core";
import { Configuration, BASE_PATH } from "src/generated/api/krakenn-api";
import { MatDialog } from "@angular/material/dialog";
import { TcGenericEntity } from "@tc/core";
import { EntitiesEnum } from "../modules/core/enums/entities-enum";
import { CreateEditPopupComponent } from "../modules/core/components/smart/create-edit-popup/create-edit-popup.component";
import { ProposalDetailComponent } from "../modules/proposals/components/smart/proposal-detail/proposal-detail.component";
import { EstimationDetailComponent } from '../modules/estimations/components/smart/estimation-detail/estimation-detail.component';

@Injectable({
  providedIn: "root",
})
export class OpportunityService {
  public configuration = new Configuration();
  protected basePath =
    "https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0";

  constructor(
    private dialog: MatDialog,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  addOpportunityProposal(
    entityName: EntitiesEnum,
    data: TcGenericEntity,
    args?: any
  ) {
    this.openPopup(entityName, data, args);
  }

  createOpportunityEstimation(
    entityName: EntitiesEnum,
    data: TcGenericEntity,
    args?: any
  ) {
    this.openPopup(entityName, data, args);
  }

  private openPopup(
    entityName: EntitiesEnum,
    data?: TcGenericEntity,
    args?: any
  ) {
    this.dialog.open(CreateEditPopupComponent, {
      panelClass: "add-edit-popup",
      autoFocus: false,
      data: {
        data,
        component: this.getDetailComponent(entityName),
        args,
        entityName
      },
    });
  }

  private getDetailComponent(entityName: EntitiesEnum) {
    switch (entityName) {
      case EntitiesEnum.Proposals:
        return ProposalDetailComponent;
      case EntitiesEnum.Estimations:
        return EstimationDetailComponent;
      default:
        return null;
    }
  }
}
