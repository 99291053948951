import { TcAppComponent, TcConfigService, TcNavMenuNodes, TcMenuComponent, TcMenuItem, TcTranslateService, ActionSettingsChangeTheme } from '@tc/core';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { getTcAuthUser, Logout } from '@tc/auth';
import { Observable, Subscription } from 'rxjs';
import { VersionService } from './services/version.service';
import { UserRole } from './modules/core/user-role.enum';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends TcAppComponent implements OnInit, OnDestroy {
  title = 'Krakenn';
  prop = 'test';
  version: string;

  themeCookieName = 'KRN-THEME';
  langCookieName = 'KRN-LANG';

  userMenuItems = [
    // dashboard
    { icon: 'dashboard', label: 'menu.items.dashboard', class: 'menu-item-bold', },
    {
      icon: 'sync_alt', label: 'menu.items.sales', class: 'menu-item-bold', items: [
        { icon: 'phone_enabled', label: 'menu.items.leads' },
        { icon: 'contacts', label: 'menu.items.contacts' },
      ],
        expanded: true
      },
    // management
    {
      icon: 'equalizer', label: 'menu.items.management', class: 'menu-item-bold', items: [
        { icon: 'flight_takeoff', label: 'menu.items.holidays' }
      ],
      expanded: true
    },
    { icon: 'cloud_download', label: 'menu.items.reporting' }
  ];

  adminMenuItems = [
    // dashboard
    { icon: 'dashboard', label: 'menu.items.dashboard', class: 'menu-item-bold', },
    // sales
    {
      icon: 'sync_alt', label: 'menu.items.sales', class: 'menu-item-bold', items: [
        { icon: 'phone_enabled', label: 'menu.items.leads' },
        { icon: 'contacts', label: 'menu.items.contacts' },
        { icon: 'thumb_up', label: 'menu.items.opportunities' },
        { icon: 'add_shopping_cart', label: 'menu.items.estimations' },
        { icon: 'monetization_on', label: 'menu.items.proposals' },
        { icon: 'sentiment_very_satisfied', label: 'menu.items.invoices' },
        { icon: 'account_tree', label: 'menu.items.commercial-imputations' }
      ],
      expanded: true
    },
    // management
    {
      icon: 'equalizer', label: 'menu.items.management', class: 'menu-item-bold', items: [
        { icon: 'restore', label: 'menu.items.time-imputations', class: 'time-imputations' },
        { icon: 'bookmarks', label: 'menu.items.tasks' },
        { icon: 'flight_takeoff', label: 'menu.items.holidays' }
      ],
      expanded: true
    },
    // master data
    {
      icon: 'storage', label: 'menu.items.master-data', class: 'menu-item-bold', items: [
        { icon: 'assignment_ind', label: 'menu.items.collaborators' },
        { icon: 'supervised_user_circle', label: 'menu.items.clients' },
        { icon: 'assignment', label: 'menu.items.projects' },
        { icon: 'timeline', label: 'menu.items.missions' },
        { icon: 'cloud_download', label: 'menu.items.reporting' },
      ],
      expanded: true
    }
    // { icon: 'payment', label: 'menu.items.payments' },
    // { icon: 'notification_important', label: 'menu.items.alerts' }
  ];

  appMenuItems: any[] = [];

  user$: Observable<any>; // TODO define UserModel
  currentUserSubscription: Subscription;

  currentUserMenu: TcMenuComponent;
  @ViewChild('currentUserMenu', { static: true }) set appCurrentUserMenu(value: TcMenuComponent) {
    this.currentUserMenu = value;
  }

  constructor(
    configService: TcConfigService,
    private store: Store<any>,
    private versionService: VersionService,
    private router: Router,
    private readonly translateService: TcTranslateService,
    private cookieService: CookieService) {
    super(configService);
  }

  onSelectedNavMenuItem(event: TcNavMenuNodes) {
    // todo: add store method
    if (event.label === 'menu.items.dashboard') {
      this.router.navigate(['']);
    } else {
      this.router.navigate([event.label.replace('menu.items.', '')]);
    }
  }

  ngOnInit() {

    super.ngOnInit();


    this.loadSavedTheme();
    this.loadSavedLang();

    this.version = this.versionService.getVersion();

    this.appMenuItems = this.userMenuItems;

    this.user$ = this.store.pipe(select(getTcAuthUser));
    this.currentUserSubscription = this.user$.subscribe(user => {
      if (user) {
        if ((user as any).roles && (user as any).roles.find(r => r === UserRole.Admin)) {
          this.appMenuItems = this.adminMenuItems;
        } else {
          this.appMenuItems = this.userMenuItems;
        }
      }
    });


    this.currentUserMenu.items = [
      {
        name: 'logout',
        icon: 'power_settings_new'
      },
      {
        name: 'dark-theme',
        icon: 'dark_mode'
      },
      {
        name: 'light-theme',
        icon: 'light_mode'
      }
    ];


    this.currentUserMenu.onMenuItemClick = (item: TcMenuItem) => {
      if (item.name === 'logout') {
        this.store.dispatch(new Logout());
      }
      if (item.name === 'dark-theme') {
        this.changeTheme('dark');
      }
      if (item.name === 'light-theme') {
        this.changeTheme('light');
      }

    };

  }

  ngOnDestroy() {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  private loadSavedTheme() {
    const savedTheme = this.cookieService.get(this.themeCookieName);
    if (savedTheme && savedTheme !== '') {
      this.changeTheme(savedTheme);
    }
  }

  private loadSavedLang() {
    const savedLang = this.cookieService.get(this.langCookieName);
    if (savedLang && savedLang !== '') {
      this.changeLang(savedLang);
    }
  }

  changeLang(lang: string) {
    this.cookieService.set(this.langCookieName, lang);
    this.translateService.use(lang);
  }

  changeTheme(theme: string) {
    this.cookieService.set(this.themeCookieName, theme);
    this.store.dispatch(new ActionSettingsChangeTheme(theme));
  }

}
