import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'app-add-entity',
  templateUrl: './add-entity.component.html',
  styleUrls: ['./add-entity.component.scss']
})
export class AddEntityComponent extends TcDumbComponent implements OnInit {

  @Output()
  add: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  onAddClick() {
    this.add.emit();
  }

}
