import { Component, ElementRef } from "@angular/core";
import {
  TcTranslateService,
  TcGenericFormComponent
} from "@tc/core";
import {
  Opportunity
} from "src/generated/api/krakenn-api";
import { EntityCollectionServiceFactory } from "ngrx-data";
import { EntitiesEnum } from "src/app/modules/core/enums/entities-enum";
import { SelectValues } from "src/app/modules/core/enums/select-values";
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: "app-opportunity-detail",
  templateUrl: "./opportunity-detail.component.html",
  styleUrls: ["./opportunity-detail.component.scss"],
})
export class OpportunityDetailComponent extends TcGenericFormComponent<
Opportunity
> {
  constructor(
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {
    super(
      EntitiesEnum.Opportunities,
      entityCollectionServiceFactory,
      translate,
      elem,
      dateAdapter,
    );

    this.fields = [
      {
        fieldGroupClassName: "form-display-grid",
        fieldGroup: [
          {
            key: "name",
            type: "input",
            templateOptions: {
              type: "text",
              required: true,
              maxLength: 255,
            },
          },
          {
            key: "contactName",
            type: "input",
            templateOptions: {
              type: "text",
              required: true,
              maxLength: 255,
            },
          },
          {
            key: "contactPhone",
            type: "input",
            templateOptions: {
              type: "text",
              maxLength: 20,
            },
          },
          {
            key: "contactEmail",
            type: "input",
            templateOptions: {
              type: "text",
              maxLength: 255,
            },
          },
          {
            key: "budget",
            type: "input",
            templateOptions: {
              type: "text",
            },
            validators: {
              isValidNumber: (ctrl) => {
                const pattern = /^[\d\s]*$/;
                return pattern.test(ctrl.value);
              },
            },
            validation: {
              messages: {
                isValidNumber: this.translate.instant('budgetShouldBeNumber'),
              },
            },
          },
          {
            key: "cDCExists",
            type: "checkbox",
            templateOptions: {
              type: "boolean",
            },
          },
          {
            key: "status",
            type: "select",
            templateOptions: {
              options: new SelectValues().opportunityStatus(this.translate),
            },
          },
          {
            key: "signatureProbability",
            type: "input",
            templateOptions: {
              type: "number",
              min: 0,
              max: 100,
            },
          },
          {
            key: "signatureTargetDate",
            type: "datepicker",
            templateOptions: {
              type: "text",
              datepickerPopup: "dd-MMMM-yyyy",
              datepickerOptions: {
                format: "dd-MM-yyyy hh:mm:ss",
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: "display-row",
        fieldGroup: [
          {
            key: "description",
            type: "textarea-editor",
            className: "textarea-form-content textarea-form-label",
            templateOptions: {},
          },
        ],
      }
    ];
  }
}
