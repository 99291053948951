import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcGridPaginationService, TcService } from '@tc/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { BASE_PATH, Configuration } from 'src/generated/api/krakenn-api';
import { Observable } from 'rxjs';
import { CollaboratorWorkload } from '../modules/collaborators/collaborators.interfaces';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorsService extends TcService {
  public configuration = new Configuration();
  protected basePath =
    "https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0";
  protected defaultHeaders = new HttpHeaders();


  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private readonly http: HttpClient,
    private readonly tcGridPaginationService: TcGridPaginationService,
    private store: Store<any>,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) {
    super();

    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  public getCollaboratorsWorkload(startDate: any, endDate: any): Observable<CollaboratorWorkload[]> {
    const url = `${this.configuration.basePath}/collaborator/get-collaborators-workload?startDate=${startDate}&endDate=${endDate}`;

    return this.http.get<CollaboratorWorkload[]>(url, { withCredentials: true });
  }
}
