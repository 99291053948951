import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { TcGenericListComponent, TcSmartComponent, TcListRowActionButtonsPosition, TcListSortType,
  TcListFilterType, TcListDisplayColumnType, TcListDefaultAction, TcFilterTypes, FilterTypesEnum, TcTranslateService } from '@tc/core';
import { Proposal } from 'src/generated/api/krakenn-api';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { SelectValues } from 'src/app/modules/core/enums/select-values';
import { Store } from '@ngrx/store';
import { EditListItem, AddListItem, DuplicateListItem } from 'src/app/modules/core/store/actions/list.actions';
import { ProposalDbStatus } from 'src/app/modules/core/enums/proposal-db-status.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-proposals-list',
  templateUrl: './proposals-list.component.html',
  styleUrls: ['./proposals-list.component.scss']
})
export class ProposalsListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  listId = 'proposals-list';

  activeProposals = [
    ProposalDbStatus.Initial,
    ProposalDbStatus.InEstimation,
    ProposalDbStatus.InValidation,
    ProposalDbStatus.Sent
  ];

  constructor(
    private store: Store<any>,
    private translate: TcTranslateService) {
    super();
  }

  @ViewChild('colStatusTemplate', { static: true }) colStatusTemplate: TemplateRef<any>;

  proposalsList: TcGenericListComponent<Proposal>;
  @ViewChild('proposalsList', { static: true }) set setProposalsList(values: TcGenericListComponent<Proposal>) {

    this.proposalsList = values;
    this.proposalsList.entityName = EntitiesEnum.Proposals;
  }

  ngAfterViewInit() {
  }


  ngOnInit() {

    this.proposalsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.BeforeData;
    this.proposalsList.showTotalInActionsHeader = true;

    this.proposalsList.sortType = TcListSortType.Server;
    this.proposalsList.filterType = TcListFilterType.Server;
    this.proposalsList.hasFixedHeader = true;

    this.proposalsList.hasAddButton = true;
    this.proposalsList.addItemWhenKeyPresed = true;

    this.proposalsList.hasActionsLabel = false;
    this.proposalsList.isPaged = false;

    this.proposalsList.onScrollDown = () => {
      this.proposalsList.service.getAll();
    };

    this.proposalsList.filters = [
      {
        key: TcFilterTypes.anyFieldContains,
        filterType: FilterTypesEnum.Equal,
        type: TcFilterTypes.anyFieldContains,
        label: `${this.listId}.filter-label`,
        options: {
          minLength: 1,
          debounceTime: 500,
        }
      },
      {
        key: 'status',
        filterType: FilterTypesEnum.In,
        type: TcFilterTypes.toggle,
        label: '',
        options: {
          offLabel: `${this.listId}.filter.toggles.inactive`,
          onLabel: `${this.listId}.filter.toggles.active`,
          default: true,
          onValue: this.activeProposals
        }
      },
      {
        key: 'sentDate',
        type: TcFilterTypes.daterange,
        label: `${this.listId}.filter.sentDate`,
        hidden: false,
        options: {
          format: 'DD/MM/YYYY',
          default: {
            start: moment(new Date(), 'DD/MM/YYYY').subtract(3, "month"),
            end: moment(new Date(), 'DD/MM/YYYY')
          },
          hasReset: true
        }
      },
    ];

    this.proposalsList.columns = [
      {
        propertyName: 'estimation.name',
        visible: true
      },
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'sentDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'status',
        visible: true,
        htmlTemplate: this.colStatusTemplate
      }
    ];

    this.proposalsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Delete,
        icon: 'delete',
        visible: true
      },
      {
        actionName: TcListDefaultAction.Duplicate,
        icon: 'content_copy',
        visible: true
      }
    ];

    this.proposalsList.onRowAction = (row: Proposal, actionName: string) => {
      if (actionName === 'edit') {
        this.editProposal(row);
      }
    };

    this.proposalsList.onRowClick = (row: Proposal) => {
      this.editProposal(row);
    };

    this.proposalsList.addItem = () => {
      this.store.dispatch(new AddListItem({ entityName: EntitiesEnum.Proposals }));
    };

    this.proposalsList.onDuplicateRow = (row: Proposal) => {
      this.store.dispatch(new DuplicateListItem({entityName: EntitiesEnum.Proposals, data: row}));
    }
  }

  private editProposal(row: Proposal) {
    this.store.dispatch(new EditListItem({ entityName: EntitiesEnum.Proposals, data: row }));
  }

  getStatusLabel(proposal: Proposal) {
    const result = new SelectValues().proposalStatus(this.translate).find(p => p.value === proposal.status);
    return result ? result.label : '';
  }

}
