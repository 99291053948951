import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from "@angular/core";
import { Estimation, Collaborator } from "src/generated/api/krakenn-api";
import {
  TcGenericListComponent,
  TcListRowActionButtonsPosition,
  TcSmartComponent,
  TcListSortType,
  TcListFilterType,
  TcListDisplayColumnType,
} from "@tc/core";
import { Store } from "@ngrx/store";
import {
  EditListItem,
  AddListItem,
} from "src/app/modules/core/store/actions/list.actions";
import { EntitiesEnum } from "src/app/modules/core/enums/entities-enum";
import { LogAsUser } from "../../../store/collaborators.actions";

@Component({
  selector: "app-collaborators-list",
  templateUrl: "./collaborators-list.component.html",
  styleUrls: ["./collaborators-list.component.scss"],
})
export class CollaboratorsListComponent
  extends TcSmartComponent
  implements OnInit, AfterViewInit {
  constructor(private store: Store<any>) {
    super();
  }

  collaboratorsList: TcGenericListComponent<Collaborator>;
  @ViewChild("collaboratorsList", { static: true }) set setCollaboratorsList(
    values: TcGenericListComponent<Collaborator>
  ) {
    this.collaboratorsList = values;
    this.collaboratorsList.entityName = "Collaborators";
  }

  ngAfterViewInit() {
    this.collaboratorsList.service.clearCache();
    this.collaboratorsList.service.getWithQuery({ initial: "true" });
  }

  ngOnInit() {
    this.collaboratorsList.hasTotalLine = true;
    this.collaboratorsList.totalLineColumns = ["costPerDay"];
    this.collaboratorsList.totalDisplayColumn = "companyName";

    this.collaboratorsList.rowActionButtonsPosition =
      TcListRowActionButtonsPosition.BeforeData;
    this.collaboratorsList.showTotalInActionsHeader = true;

    this.collaboratorsList.sortType = TcListSortType.Server;
    this.collaboratorsList.filterType = TcListFilterType.Server;
    this.collaboratorsList.hasFixedHeader = true;

    this.collaboratorsList.hasAddButton = true;
    this.collaboratorsList.addItemWhenKeyPresed = true;

    this.collaboratorsList.hasActionsLabel = false;
    this.collaboratorsList.isPaged = false;

    this.collaboratorsList.onScrollDown = () => {
      this.collaboratorsList.service.getAll();
    };

    this.collaboratorsList.columns = [
      {
        propertyName: "trigram",
        visible: true,
      },
      {
        propertyName: "jiraLogin",
        visible: true,
      },
      {
        propertyName: "firstName",
        visible: true,
      },
      {
        propertyName: "lastName",
        visible: true,
      },
      {
        propertyName: "companyName",
        visible: true,
      },
      {
        propertyName: "costPerDay",
        visible: true,
        displayColumnType: TcListDisplayColumnType.Currency,
      },
      {
        propertyName: "email",
        visible: true,
      },
      {
        propertyName: "phoneNumber",
        visible: true,
      },
      {
        propertyName: "expirationDate",
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date,
      }
    ];

    this.collaboratorsList.rowActions = [
      {
        actionName: "edit",
        icon: "edit",
        visible: true,
      },
      {
        actionName: "delete",
        icon: "delete",
        visible: true,
      },
      {
        actionName: "login as user",
        icon: "person",
        visible: true,
      },
    ];

    this.collaboratorsList.onRowAction = (
      row: Collaborator,
      actionName: string
    ) => {
      if (actionName === "edit") {
        this.editCollaborator(row);
      } else if (actionName === "login as user") {
        this.loginAsUser(row);
      }
    };

    this.collaboratorsList.onRowClick = (row: Collaborator) => {
      this.editCollaborator(row);
    };

    this.collaboratorsList.addItem = () => {
      this.store.dispatch(
        new AddListItem({ entityName: EntitiesEnum.Collaborators })
      );
    };
  }

  private editCollaborator(row: Collaborator) {
    this.store.dispatch(
      new EditListItem({ entityName: EntitiesEnum.Collaborators, data: row })
    );
  }

  private loginAsUser(row: Collaborator) {
    this.store.dispatch(new LogAsUser({ data: row }));
  }
}
