import { Activity } from 'src/generated/api/krakenn-api';

export interface ActivitysState {

    activitysList: Activity[];
}

export const initialActivitysState: ActivitysState = {
    activitysList: []
};


