import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskDetailComponent } from './components/smart/task-detail/task-detail.component';
import { TasksListComponent } from './components/smart/tasks-list/tasks-list.component';
import { TcCoreModule } from '@tc/core';
import { CoreModule } from '../core/core.module';
import { DownloadJiraSyncLogComponent } from './components/smart/download-jira-sync-log/download-jira-sync-log.component';
import { TerminateTaskPopupComponent } from './components/smart/terminate-task-popup/terminate-task-popup.component';
import { EffectsModule } from '@ngrx/effects';
import { TasksEffects } from './store/tasks.effects';
import { ForceJiraSyncComponent } from './components/smart/force-jira-sync/force-jira-sync.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CommentsModule } from '../comments/comments.module';
import { AddTasksMassComponent } from './components/smart/add-tasks-mass/add-tasks-mass.component';
import { AddTasksMassPopupComponent } from './components/smart/add-tasks-mass-popup/add-tasks-mass-popup.component';

const smartComponents = [TaskDetailComponent, TasksListComponent, DownloadJiraSyncLogComponent,
  TerminateTaskPopupComponent, ForceJiraSyncComponent,AddTasksMassComponent,AddTasksMassPopupComponent];
const dumbComponents = [

];

@NgModule({
  declarations: [...smartComponents, ...dumbComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    MatTabsModule,
    CoreModule,
    CommentsModule,
    EffectsModule.forFeature([TasksEffects]),
  ],
  entryComponents: [
    TaskDetailComponent,
    TerminateTaskPopupComponent
  ],
  exports: [...smartComponents, ...dumbComponents]
})
export class TasksModule { }
