import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommercialImputationDetailComponent } from './components/smart/commercial-imputation-detail/commercial-imputation-detail.component';
import { CommercialImputationsListComponent } from './components/smart/commercial-imputations-list/commercial-imputations-list.component';
import { TcCoreModule } from '@tc/core';
import { CoreModule } from '../core/core.module';
import { StoreModule } from '@ngrx/store';
import { invoiceReducer } from './store/invoice.reducer';
import { EffectsModule } from '@ngrx/effects';
import { InvoiceEffects } from './store/effects/invoice.effects';
import { InvoiceDateDialogComponent } from './components/dumb/invoice-date-dialog/invoice-date-dialog.component';
import { InvoicePreviewComponent } from './components/dumb/invoice-preview/invoice-preview.component';


const dumbComponents = [
  InvoiceDateDialogComponent,
  InvoicePreviewComponent
];

const smartComponents = [
  CommercialImputationDetailComponent,
  CommercialImputationsListComponent
];

@NgModule({
  declarations: [
    ...dumbComponents,
    ...smartComponents
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule,
    StoreModule.forFeature('invoice', invoiceReducer),
    EffectsModule.forFeature([InvoiceEffects])
  ],
  entryComponents: [
    CommercialImputationDetailComponent,
    InvoiceDateDialogComponent,
    InvoicePreviewComponent
  ],
  exports: [
    ...dumbComponents,
    ...smartComponents
  ]
})
export class CommercialImputationsModule { }
