import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListRefreshService {
  private _subject: Subject<ListRefreshType> = new Subject();

  get subject(): Subject<ListRefreshType> {
    return this._subject;
  }

  public emitRefresh(type: ListRefreshType) {
    this._subject.next(type);
  }
}

export enum ListRefreshType {
  Missions,
  Tasks,
  DashboardUnassignedTasks,
}
