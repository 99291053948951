import { Injectable, Inject, Optional } from "@angular/core";
import {
    Configuration,
    BASE_PATH,
    Contact
} from "src/generated/api/krakenn-api";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  TcService, TcGridPaginationService, TcGridDataSourceSort } from '@tc/core';

@Injectable({
  providedIn: 'root'
})
export class ContactsService  extends TcService {
  public configuration = new Configuration();
  protected basePath =
      "https://virtserver.swaggerhub.com/TanaCorp/Krakenn/1.0.0";
  protected defaultHeaders = new HttpHeaders();

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private readonly http: HttpClient,
    private readonly tcGridPaginationService: TcGridPaginationService,
  ) {
    super();

    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
          configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  getContactById(id: number) {
    const url = `${this.configuration.basePath}/contacts/${id}`;

    return this.http.get<Contact[]>(url, {withCredentials: true});
  }

  getAllContacts(): Observable<Contact[]> {
    const url = `${this.configuration.basePath}/contact`;

    return this.http.get<Contact[]>(url, {withCredentials: true});
  }

  save(data: any): Observable<any> {
    const url = `${this.configuration.basePath}/contact`;
    const headers = this.defaultHeaders;

    return this.http.post<Contact>(url, data,
        {
            withCredentials: true,
            headers,
            observe: 'body',
            reportProgress: false
        });
  }

  edit(data: any): Observable<any> {
    const url = `${this.configuration.basePath}/contact`;
      const headers = this.defaultHeaders;

      return this.http.put<Contact>(url, data,
          {
              withCredentials: true,
              headers,
              observe: 'body',
              reportProgress: false
          });
  }

  delete(id: number): Observable<any> {
      const url = `${this.configuration.basePath}/contact/${id}`;
      const headers = this.defaultHeaders;
      return this.http.delete<number>(url,
          {
              withCredentials: true,
              headers,
              observe: 'body',
              reportProgress: false
          });
  }
}
