import { CommercialImputation, Invoice } from 'src/generated/api/krakenn-api';
import { InvoiceResultModel } from '../models/invoice-result.model';

export interface InvoiceState {

    lines: CommercialImputation[];

    lastInvoice: Invoice;

    resultModel: InvoiceResultModel;

    result: any;

}

export const initialInvoiceState: InvoiceState = {
    lines: [],
    lastInvoice: null,
    resultModel: null,
    result: null
};


