import { Component, ElementRef } from '@angular/core';
import { TcTranslateService, TcGenericFormComponent } from '@tc/core';
import { TimeImputation, Collaborator, Task } from 'src/generated/api/krakenn-api';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-time-imputation-detail',
  templateUrl: './time-imputation-detail.component.html',
  styleUrls: ['./time-imputation-detail.component.scss']
})
export class TimeImputationDetailComponent extends TcGenericFormComponent<TimeImputation> {

  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {

    super(EntitiesEnum.TimeImputations, entityCollectionServiceFactory, translate, elem, dateAdapter);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'collaboratorId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Collaborator>(EntitiesEnum.Collaborators, term, 'id', 'firstName,lastName'),
              listValue: 'collaborator.name'
            },
            validators: {
              collaboratoId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            }
          },
          {
            key: 'taskId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Task>(EntitiesEnum.Tasks, term, 'id', 'name'),
              listValue: 'task.name'
            },
            validators: {
              taskId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            }
          },
          {
            key: 'date',
            type: 'datepicker',
            templateOptions: {
              required: true
            }
          },
          {
            key: 'daysConsumed',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: true
            }
          },
          {
            key: 'daysEstimatedToFinish',
            type: 'input',
            templateOptions: {
              type: 'number',
              required: false
            }
          }
        ]
      },
      {
        fieldGroupClassName: 'display-row',
        fieldGroup: [
          // {
          //   key: 'workDone',
          //   type: 'textarea-editor',
          //   className: 'textarea-form-content textarea-form-label',
          //   templateOptions: {
          //     required: true
          //   }
          // },
          {
            key: 'workDone',
            type: 'textarea',
            className: 'textarea-form-content textarea-form-label',
            templateOptions: {
              required: true,
              autosize: true,
              autosizeMinRows: 10,
              autosizeMaxRows: 10,
            }
          }
        ]
      }
    ];

  }

}
