// here you can specify the number of decimals for decimals number in the entire app
export const nbOfDecimals = 3;

export function formatToPrecision(value, precision: number = nbOfDecimals) {
  return parseFloat(parseFloat(value).toFixed(precision));
}

export function prettyNumber(value) {
  return typeof value !== 'undefined' ? value?.toString()?.replace('.', ',') : null;
}

// this function is used in the entire app as mask for MatInputEditor
export function decimalMask() {
  return `separator.${nbOfDecimals}`;
}

// this function is used in the entire app as decimalMarker for MatInputEditor
export function decimalMarker() {
  return ',';
}
