import { Component, OnInit, OnDestroy } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss']
})
export class ListPageComponent extends TcPage implements OnInit, OnDestroy {

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }
}
