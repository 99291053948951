import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OpportunitiesListComponent } from "./components/smart/opportunities-list/opportunities-list.component";
import { TcCoreModule } from "@tc/core";
import { OpportunityDetailComponent } from "./components/smart/opportunity-detail/opportunity-detail.component";
import { CoreModule } from "../core/core.module";
import { OpportunitiesEffects } from "./store/opportunities.effects";
import { EffectsModule } from "@ngrx/effects";

const smartComponents = [
  OpportunitiesListComponent,
  OpportunityDetailComponent,
];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    CoreModule,
    EffectsModule.forFeature([OpportunitiesEffects]),
  ],
  entryComponents: [OpportunityDetailComponent],
  exports: [...smartComponents],
})
export class OpportunitiesModule {}
