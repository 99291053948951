import { Component, ElementRef } from '@angular/core';
import { TcTranslateService, TcGenericFormComponent } from '@tc/core';
import { Project, Client, Proposal } from 'src/generated/api/krakenn-api';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { EntitiesEnum } from 'src/app/modules/core/enums/entities-enum';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QlAutcompleteService } from 'src/app/services/core/ql-autocomplete.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent extends TcGenericFormComponent<Project> {

  constructor(
    private qlAutocompleteService: QlAutcompleteService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    dateAdapter: DateAdapter<any>,
  ) {

    super(EntitiesEnum.Projects, entityCollectionServiceFactory, translate, elem, dateAdapter);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'clientId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Client>(EntitiesEnum.Clients, term, 'id', 'name'),
              listValue: 'client.name'
            },
            validators: {
              clientId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            }
          },
          {
            key: 'proposalId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Proposal>(EntitiesEnum.Proposals, term, 'id', 'name'),
              listValue: 'proposal.name'
            },
            validators: {
              proposalId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            }
          },
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 255
            }
          },
          {
            key: 'soldAmount',
            type: 'input',
            templateOptions: {
              type: 'number',
            }
          },
          {
            key: 'soldDays',
            type: 'input',
            templateOptions: {
              type: 'number',
            }
          },
          {
            key: 'jiraProject',
            type: 'input',
            templateOptions: {
              type: 'text',
              maxLength: 50
            }
          }
        ]
      }
    ];

  }

}
