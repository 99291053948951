import { Contact } from '../api/krakenn-api';

export class ContactType {
  static className = 'Contact';
  static gqlRootQueryName = 'contact';
  static gqlColumns: string[] = [
      '_id',
      'firstName',
      'lastName',
      'role',
      'phone',
      'email',
  ];

  static gqlNodeMapper = (node: any): Contact => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Contact;
    }

}
