import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/smart/login/login.component';
import { TcCoreModule } from '@tc/core';
import { EffectsModule } from '@ngrx/effects';
import { BypassLoginEffects } from './store/login.effects';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([BypassLoginEffects]),
  ]
})
export class LoginModule { }
